(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.r.aM === region.j.aM)
	{
		return 'on line ' + region.r.aM;
	}
	return 'on lines ' + region.r.aM + ' through ' + region.j.aM;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.eF,
		impl.fu,
		impl.fm,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		eM: func(record.eM),
		fl: record.fl,
		e2: record.e2
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.eM;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.fl;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.e2) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.eF,
		impl.fu,
		impl.fm,
		function(sendToApp, initialModel) {
			var view = impl.fz;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.eF,
		impl.fu,
		impl.fm,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.cf && impl.cf(sendToApp)
			var view = impl.fz;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.d2);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.fr) && (_VirtualDom_doc.title = title = doc.fr);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.eT;
	var onUrlRequest = impl.eU;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		cf: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.ds === next.ds
							&& curr.cV === next.cV
							&& curr.dp.a === next.dp.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		eF: function(flags)
		{
			return A3(impl.eF, flags, _Browser_getUrl(), key);
		},
		fz: impl.fz,
		fu: impl.fu,
		fm: impl.fm
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { eA: 'hidden', d6: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { eA: 'mozHidden', d6: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { eA: 'msHidden', d6: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { eA: 'webkitHidden', d6: 'webkitvisibilitychange' }
		: { eA: 'hidden', d6: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		dB: _Browser_getScene(),
		dO: {
			dP: _Browser_window.pageXOffset,
			dQ: _Browser_window.pageYOffset,
			fB: _Browser_doc.documentElement.clientWidth,
			ey: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		fB: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		ey: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			dB: {
				fB: node.scrollWidth,
				ey: node.scrollHeight
			},
			dO: {
				dP: node.scrollLeft,
				dQ: node.scrollTop,
				fB: node.clientWidth,
				ey: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			dB: _Browser_getScene(),
			dO: {
				dP: x,
				dQ: y,
				fB: _Browser_doc.documentElement.clientWidth,
				ey: _Browser_doc.documentElement.clientHeight
			},
			er: {
				dP: x + rect.left,
				dQ: y + rect.top,
				fB: rect.width,
				ey: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.m.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.m.b, xhr)); });
		$elm$core$Maybe$isJust(request.F) && _Http_track(router, xhr, request.F.a);

		try {
			xhr.open(request.A, request.t, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.t));
		}

		_Http_configureRequest(xhr, request);

		request.d2.a && xhr.setRequestHeader('Content-Type', request.d2.a);
		xhr.send(request.d2.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.y; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.E.a || 0;
	xhr.responseType = request.m.d;
	xhr.withCredentials = request.dV;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		t: xhr.responseURL,
		fj: xhr.status,
		fk: xhr.statusText,
		y: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			fd: event.loaded,
			dE: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			e6: event.loaded,
			dE: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}





// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.eQ) { flags += 'm'; }
	if (options.d5) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}

// BYTES

function _Bytes_width(bytes)
{
	return bytes.byteLength;
}

var _Bytes_getHostEndianness = F2(function(le, be)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(new Uint8Array(new Uint32Array([1]))[0] === 1 ? le : be));
	});
});


// ENCODERS

function _Bytes_encode(encoder)
{
	var mutableBytes = new DataView(new ArrayBuffer($elm$bytes$Bytes$Encode$getWidth(encoder)));
	$elm$bytes$Bytes$Encode$write(encoder)(mutableBytes)(0);
	return mutableBytes;
}


// SIGNED INTEGERS

var _Bytes_write_i8  = F3(function(mb, i, n) { mb.setInt8(i, n); return i + 1; });
var _Bytes_write_i16 = F4(function(mb, i, n, isLE) { mb.setInt16(i, n, isLE); return i + 2; });
var _Bytes_write_i32 = F4(function(mb, i, n, isLE) { mb.setInt32(i, n, isLE); return i + 4; });


// UNSIGNED INTEGERS

var _Bytes_write_u8  = F3(function(mb, i, n) { mb.setUint8(i, n); return i + 1 ;});
var _Bytes_write_u16 = F4(function(mb, i, n, isLE) { mb.setUint16(i, n, isLE); return i + 2; });
var _Bytes_write_u32 = F4(function(mb, i, n, isLE) { mb.setUint32(i, n, isLE); return i + 4; });


// FLOATS

var _Bytes_write_f32 = F4(function(mb, i, n, isLE) { mb.setFloat32(i, n, isLE); return i + 4; });
var _Bytes_write_f64 = F4(function(mb, i, n, isLE) { mb.setFloat64(i, n, isLE); return i + 8; });


// BYTES

var _Bytes_write_bytes = F3(function(mb, offset, bytes)
{
	for (var i = 0, len = bytes.byteLength, limit = len - 4; i <= limit; i += 4)
	{
		mb.setUint32(offset + i, bytes.getUint32(i));
	}
	for (; i < len; i++)
	{
		mb.setUint8(offset + i, bytes.getUint8(i));
	}
	return offset + len;
});


// STRINGS

function _Bytes_getStringWidth(string)
{
	for (var width = 0, i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		width +=
			(code < 0x80) ? 1 :
			(code < 0x800) ? 2 :
			(code < 0xD800 || 0xDBFF < code) ? 3 : (i++, 4);
	}
	return width;
}

var _Bytes_write_string = F3(function(mb, offset, string)
{
	for (var i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		offset +=
			(code < 0x80)
				? (mb.setUint8(offset, code)
				, 1
				)
				:
			(code < 0x800)
				? (mb.setUint16(offset, 0xC080 /* 0b1100000010000000 */
					| (code >>> 6 & 0x1F /* 0b00011111 */) << 8
					| code & 0x3F /* 0b00111111 */)
				, 2
				)
				:
			(code < 0xD800 || 0xDBFF < code)
				? (mb.setUint16(offset, 0xE080 /* 0b1110000010000000 */
					| (code >>> 12 & 0xF /* 0b00001111 */) << 8
					| code >>> 6 & 0x3F /* 0b00111111 */)
				, mb.setUint8(offset + 2, 0x80 /* 0b10000000 */
					| code & 0x3F /* 0b00111111 */)
				, 3
				)
				:
			(code = (code - 0xD800) * 0x400 + string.charCodeAt(++i) - 0xDC00 + 0x10000
			, mb.setUint32(offset, 0xF0808080 /* 0b11110000100000001000000010000000 */
				| (code >>> 18 & 0x7 /* 0b00000111 */) << 24
				| (code >>> 12 & 0x3F /* 0b00111111 */) << 16
				| (code >>> 6 & 0x3F /* 0b00111111 */) << 8
				| code & 0x3F /* 0b00111111 */)
			, 4
			);
	}
	return offset;
});


// DECODER

var _Bytes_decode = F2(function(decoder, bytes)
{
	try {
		return $elm$core$Maybe$Just(A2(decoder, bytes, 0).b);
	} catch(e) {
		return $elm$core$Maybe$Nothing;
	}
});

var _Bytes_read_i8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getInt8(offset)); });
var _Bytes_read_i16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getInt16(offset, isLE)); });
var _Bytes_read_i32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getInt32(offset, isLE)); });
var _Bytes_read_u8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getUint8(offset)); });
var _Bytes_read_u16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getUint16(offset, isLE)); });
var _Bytes_read_u32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getUint32(offset, isLE)); });
var _Bytes_read_f32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getFloat32(offset, isLE)); });
var _Bytes_read_f64 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 8, bytes.getFloat64(offset, isLE)); });

var _Bytes_read_bytes = F3(function(len, bytes, offset)
{
	return _Utils_Tuple2(offset + len, new DataView(bytes.buffer, bytes.byteOffset + offset, len));
});

var _Bytes_read_string = F3(function(len, bytes, offset)
{
	var string = '';
	var end = offset + len;
	for (; offset < end;)
	{
		var byte = bytes.getUint8(offset++);
		string +=
			(byte < 128)
				? String.fromCharCode(byte)
				:
			((byte & 0xE0 /* 0b11100000 */) === 0xC0 /* 0b11000000 */)
				? String.fromCharCode((byte & 0x1F /* 0b00011111 */) << 6 | bytes.getUint8(offset++) & 0x3F /* 0b00111111 */)
				:
			((byte & 0xF0 /* 0b11110000 */) === 0xE0 /* 0b11100000 */)
				? String.fromCharCode(
					(byte & 0xF /* 0b00001111 */) << 12
					| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
					| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
				)
				:
				(byte =
					((byte & 0x7 /* 0b00000111 */) << 18
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 12
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
						| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
					) - 0x10000
				, String.fromCharCode(Math.floor(byte / 0x400) + 0xD800, byte % 0x400 + 0xDC00)
				);
	}
	return _Utils_Tuple2(offset, string);
});

var _Bytes_decodeFailure = F2(function() { throw 0; });
var $author$project$Model$LinkClicked = function (a) {
	return {$: 0, a: a};
};
var $author$project$Model$UrlChanged = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.n) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.s),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.s);
		} else {
			var treeLen = builder.n * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.u) : builder.u;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.n);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.s) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.s);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{u: nodeList, n: (len / $elm$core$Array$branchFactor) | 0, s: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {aK: fragment, cV: host, q: path, dp: port_, ds: protocol, e5: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$Model$DataModels$DETERMINING = {$: 2};
var $author$project$Model$GotFeaturedBots = function (a) {
	return {$: 22, a: a};
};
var $author$project$Model$GotNewBots = function (a) {
	return {$: 24, a: a};
};
var $author$project$Model$GotTopRatedBots = function (a) {
	return {$: 23, a: a};
};
var $author$project$Model$DataModels$JAVASCRIPT = 0;
var $author$project$Model$DataModels$NOT_SEARCHING = {$: 0};
var $author$project$Model$DataModels$NO_EDITING = {$: 0};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Model$defaultNewBotCreatorForm = {ct: '', bJ: '', cw: '', bK: '', cy: 'Other', aI: '', aA: $elm$core$Maybe$Nothing, c_: $elm$core$Maybe$Nothing, eH: '', bx: false};
var $author$project$Model$defaultNewServerCreatorForm = {ay: '', aA: $elm$core$Maybe$Nothing, eH: '', bx: false, ce: '', fg: ''};
var $author$project$Model$defaultRatingDialog = {b_: 0, aT: $elm$core$Maybe$Nothing};
var $author$project$Model$defaultReportForm = {aA: $elm$core$Maybe$Nothing, c4: false, cd: ''};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.fj));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $author$project$Model$Endpoints$debugRoot = false;
var $author$project$Model$Endpoints$endpointRoot = $author$project$Model$Endpoints$debugRoot ? 'http://localhost:4000' : '';
var $author$project$Model$Endpoints$featuredBots = $author$project$Model$Endpoints$endpointRoot + '/api/featured-bots';
var $author$project$Model$DataModels$BotPreview = function (blurHash) {
	return function (id) {
		return function (title) {
			return function (shortDescription) {
				return function (inviteLink) {
					return function (description) {
						return function (backgroundImageUrl) {
							return function (averageRating) {
								return function (entryPoint) {
									return function (online) {
										return function (serverCount) {
											return function (author) {
												return function (profileImageUrl) {
													return function (isDbdBot) {
														return function (dbdCommands) {
															return {dY: author, dZ: averageRating, d_: backgroundImageUrl, d1: blurHash, cI: dbdCommands, ay: description, aI: entryPoint, b0: id, eH: inviteLink, c3: isDbdBot, eV: online, e4: profileImageUrl, fe: serverCount, fg: shortDescription, fr: title};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Model$DataModels$BotOwner = F4(
	function (id, username, tag, avatar) {
		return {bE: avatar, b0: id, fn: tag, fw: username};
	});
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Model$Decoders$botOwnerDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'avatar',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'tag',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'username',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Model$DataModels$BotOwner)))));
var $author$project$Model$DataModels$DbdCommand = F3(
	function (name, trigger, description) {
		return {ay: description, b5: name, ft: trigger};
	});
var $author$project$Model$Decoders$dbdCommandDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'description',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'trigger',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Model$DataModels$DbdCommand))));
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Model$Decoders$botPreviewDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'bdfd_commands',
	$elm$json$Json$Decode$nullable(
		$elm$json$Json$Decode$list($author$project$Model$Decoders$dbdCommandDecoder)),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'is_bdfd',
		$elm$json$Json$Decode$bool,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'avatar',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'owner',
				$author$project$Model$Decoders$botOwnerDecoder,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'servers',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'online',
						$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
						$elm$core$Maybe$Nothing,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'entry_point',
							$elm$json$Json$Decode$string,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'rating',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
								$elm$core$Maybe$Nothing,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'bg_image',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'description',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'invite_link',
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'short_description',
												$elm$json$Json$Decode$string,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'name',
													$elm$json$Json$Decode$string,
													A4(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
														'id',
														$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
														$elm$core$Maybe$Nothing,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'bg_blur_hash',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Model$DataModels$BotPreview))))))))))))))));
var $author$project$Model$Decoders$featuredBotsDecoder = $elm$json$Json$Decode$list($author$project$Model$Decoders$botPreviewDecoder);
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {dv: reqs, dG: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.F;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.dv));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.dG)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					dV: r.dV,
					d2: r.d2,
					m: A2(_Http_mapExpect, func, r.m),
					y: r.y,
					A: r.A,
					E: r.E,
					F: r.F,
					t: r.t
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{dV: false, d2: r.d2, m: r.m, y: r.y, A: r.A, E: r.E, F: r.F, t: r.t}));
};
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{d2: $elm$http$Http$emptyBody, m: r.m, y: _List_Nil, A: 'GET', E: $elm$core$Maybe$Nothing, F: $elm$core$Maybe$Nothing, t: r.t});
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Model$Ports$getAuthToken = _Platform_outgoingPort(
	'getAuthToken',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Model$Endpoints$newBots = $author$project$Model$Endpoints$endpointRoot + '/api/new-bots';
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $author$project$Model$run = function (m) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$always(m),
		$elm$core$Task$succeed(0));
};
var $author$project$Model$Endpoints$topRatedBots = $author$project$Model$Endpoints$endpointRoot + '/api/top-bots';
var $author$project$Model$init = F2(
	function (url, key) {
		return _Utils_Tuple2(
			{a1: $elm$core$Maybe$Nothing, l: '', ax: $author$project$Model$DataModels$NO_EDITING, S: $author$project$Model$defaultRatingDialog, a4: $elm$core$Maybe$Nothing, aH: $elm$core$Maybe$Nothing, a9: '', bR: 0, eE: $elm$core$Maybe$Nothing, x: key, e: $author$project$Model$defaultNewBotCreatorForm, k: $author$project$Model$defaultNewServerCreatorForm, ad: $author$project$Model$defaultReportForm, dC: $author$project$Model$DataModels$NOT_SEARCHING, fa: $elm$core$Maybe$Nothing, fc: $elm$core$Maybe$Nothing, aS: $elm$core$Maybe$Nothing, bw: $elm$core$Maybe$Nothing, dM: $elm$core$Maybe$Nothing, t: url, fv: $author$project$Model$DataModels$DETERMINING},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Model$Ports$getAuthToken(0),
						$elm$http$Http$get(
						{
							m: A2($elm$http$Http$expectJson, $author$project$Model$GotFeaturedBots, $author$project$Model$Decoders$featuredBotsDecoder),
							t: $author$project$Model$Endpoints$featuredBots
						}),
						$elm$http$Http$get(
						{
							m: A2($elm$http$Http$expectJson, $author$project$Model$GotTopRatedBots, $author$project$Model$Decoders$featuredBotsDecoder),
							t: $author$project$Model$Endpoints$topRatedBots
						}),
						$elm$http$Http$get(
						{
							m: A2($elm$http$Http$expectJson, $author$project$Model$GotNewBots, $author$project$Model$Decoders$featuredBotsDecoder),
							t: $author$project$Model$Endpoints$newBots
						}),
						$author$project$Model$run(
						$author$project$Model$UrlChanged(url))
					])));
	});
var $author$project$Model$SetToken = function (a) {
	return {$: 57, a: a};
};
var $author$project$Model$Ports$recAuthToken = _Platform_incomingPort('recAuthToken', $elm$json$Json$Decode$string);
var $author$project$Model$subscriptions = function (_v0) {
	return $author$project$Model$Ports$recAuthToken($author$project$Model$SetToken);
};
var $author$project$Model$BotPreviewRatingDialogOpened = {$: 38};
var $author$project$Model$BotPreviewRatingDone = function (a) {
	return {$: 39, a: a};
};
var $author$project$Model$DeleteBot = function (a) {
	return {$: 63, a: a};
};
var $author$project$Model$DataModels$DeleteBotResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Model$DesignerBotsFetched = function (a) {
	return {$: 20, a: a};
};
var $author$project$Model$DataModels$EDIT_LOADING_ERROR = function (a) {
	return {$: 3, a: a};
};
var $author$project$Model$DataModels$FETCHING = {$: 1};
var $author$project$Model$FetchSearchResults = function (a) {
	return {$: 34, a: a};
};
var $author$project$Model$DataModels$GET_SERVER = function (a) {
	return {$: 0, a: a};
};
var $author$project$Model$GotBotPreview = function (a) {
	return {$: 28, a: a};
};
var $author$project$Model$GotBotSettings = function (a) {
	return {$: 50, a: a};
};
var $author$project$Model$GotCurrentUser = function (a) {
	return {$: 25, a: a};
};
var $author$project$Model$GotDbdCommands = function (a) {
	return {$: 42, a: a};
};
var $author$project$Model$GotDevPreview = function (a) {
	return {$: 29, a: a};
};
var $author$project$Model$GotExchangeToken = function (a) {
	return {$: 26, a: a};
};
var $author$project$Model$GotSearchedBots = function (a) {
	return {$: 33, a: a};
};
var $author$project$Model$GotTokenInvalidated = function (a) {
	return {$: 47, a: a};
};
var $author$project$Model$HideToast = {$: 46};
var $author$project$Model$LoadBotSettingsWithId = function (a) {
	return {$: 48, a: a};
};
var $author$project$Model$LoadBotWithId = function (a) {
	return {$: 30, a: a};
};
var $author$project$Model$LoadDevWithId = function (a) {
	return {$: 31, a: a};
};
var $author$project$Model$LoadEditBotWithId = function (a) {
	return {$: 40, a: a};
};
var $author$project$Model$LoadServerPreviewWithId = function (a) {
	return {$: 49, a: a};
};
var $author$project$Model$LoadedEditBotWithId = function (a) {
	return {$: 41, a: a};
};
var $andrewMacmurray$elm_delay$Delay$Millisecond = 0;
var $author$project$Model$DataModels$NOT_SIGNED = {$: 0};
var $author$project$Model$NewBotCreatorImageDecoded = function (a) {
	return {$: 12, a: a};
};
var $author$project$Model$NewBotCreatorImageLoaded = function (a) {
	return {$: 11, a: a};
};
var $author$project$Model$NewBotCreatorReceivedForm = function (a) {
	return {$: 17, a: a};
};
var $author$project$Model$NewServerCreatorReceivedForm = function (a) {
	return {$: 56, a: a};
};
var $author$project$Model$DataModels$READY = {$: 2};
var $author$project$Model$DataModels$RESET_REASON_FORM = {$: 4};
var $author$project$Model$ReportMsg = function (a) {
	return {$: 62, a: a};
};
var $author$project$Model$DataModels$ReportReasonReceived = function (a) {
	return {$: 3, a: a};
};
var $author$project$Model$RetrieveUser = function (a) {
	return {$: 27, a: a};
};
var $author$project$Model$DataModels$SEARCHING = function (a) {
	return {$: 1, a: a};
};
var $author$project$Model$DataModels$SIGNED_IN = function (a) {
	return {$: 1, a: a};
};
var $author$project$Model$DataModels$ServerReceived = function (a) {
	return {$: 1, a: a};
};
var $author$project$Model$ShowToast = function (a) {
	return {$: 45, a: a};
};
var $author$project$Model$addUserOwnedBot = F2(
	function (newValue, user) {
		return _Utils_update(
			user,
			{
				br: A2($elm$core$List$cons, newValue, user.br)
			});
	});
var $author$project$Model$addUserOwnedServer = F2(
	function (newValue, user) {
		return _Utils_update(
			user,
			{
				dn: A2($elm$core$List$cons, newValue, user.dn)
			});
	});
var $andrewMacmurray$elm_delay$Delay$Duration = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Process$sleep = _Process_sleep;
var $andrewMacmurray$elm_delay$Delay$after_ = F2(
	function (time, msg) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$always(msg),
			$elm$core$Process$sleep(time));
	});
var $andrewMacmurray$elm_delay$Delay$Minute = 2;
var $andrewMacmurray$elm_delay$Delay$Second = 1;
var $andrewMacmurray$elm_delay$Delay$toMillis = function (_v0) {
	var t = _v0.a;
	var u = _v0.b;
	switch (u) {
		case 0:
			return t;
		case 1:
			return 1000 * t;
		case 2:
			return $andrewMacmurray$elm_delay$Delay$toMillis(
				A2($andrewMacmurray$elm_delay$Delay$Duration, 60 * t, 1));
		default:
			return $andrewMacmurray$elm_delay$Delay$toMillis(
				A2($andrewMacmurray$elm_delay$Delay$Duration, 60 * t, 2));
	}
};
var $andrewMacmurray$elm_delay$Delay$after = F3(
	function (time, unit, msg) {
		return A2(
			$andrewMacmurray$elm_delay$Delay$after_,
			$andrewMacmurray$elm_delay$Delay$toMillis(
				A2($andrewMacmurray$elm_delay$Delay$Duration, time, unit)),
			msg);
	});
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$browser$Browser$Navigation$back = F2(
	function (key, n) {
		return A2(_Browser_go, key, -n);
	});
var $author$project$Model$Endpoints$botCommands = function (botId) {
	return $author$project$Model$Endpoints$endpointRoot + ('/api/bot/' + (botId + '/commands'));
};
var $author$project$Model$Endpoints$botInfo = $author$project$Model$Endpoints$endpointRoot + '/api/bot';
var $author$project$Model$Endpoints$botSettings = function (botId) {
	return $author$project$Model$Endpoints$endpointRoot + ('/api/authorized/bot/' + (botId + '/settings'));
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Model$clientIdFromInviteLink = function (url) {
	var _v0 = A2($elm$core$String$contains, 'client_id=', url);
	if (_v0) {
		var _v1 = $elm$core$List$head(
			A2($elm$core$String$indexes, 'client_id=', url));
		if (!_v1.$) {
			var a = _v1.a;
			var id = A3($elm$core$String$slice, a + 10, (a + 18) + 10, url);
			var _v2 = $elm$core$String$toInt(id);
			if (!_v2.$) {
				return $elm$core$Maybe$Just(id);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $author$project$Model$Endpoints$createBot = $author$project$Model$Endpoints$endpointRoot + '/api/authorized/bot/create';
var $author$project$Model$Endpoints$createServer = $author$project$Model$Endpoints$endpointRoot + '/api/authorized/server/create';
var $author$project$Model$DataModels$User = F6(
	function (id, discordId, discordUser, ownedBots, ownedServers, ownedDbdBots) {
		return {eo: discordId, ep: discordUser, b0: id, br: ownedBots, dm: ownedDbdBots, dn: ownedServers};
	});
var $author$project$Model$DataModels$DiscordUser = F3(
	function (username, tag, avatar) {
		return {bE: avatar, fn: tag, fw: username};
	});
var $author$project$Model$Decoders$discordUserDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'avatar',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'tag',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'username',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Model$DataModels$DiscordUser))));
var $author$project$Model$DataModels$ExtraBotData = F3(
	function (basic, stats, verified) {
		return {bG: basic, fi: stats, fy: verified};
	});
var $author$project$Model$DataModels$Stats = function (views) {
	return {fA: views};
};
var $author$project$Model$Decoders$statsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'views',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Model$DataModels$Stats));
var $author$project$Model$DataModels$DECLINED = function (a) {
	return {$: 2, a: a};
};
var $author$project$Model$DataModels$PENDING_VERIFICATION = {$: 0};
var $author$project$Model$DataModels$VERIFIED = {$: 1};
var $webbhuset$elm_json_decode$Json$Decode$Field$require = F3(
	function (fieldName, valueDecoder, continuation) {
		return A2(
			$elm$json$Json$Decode$andThen,
			continuation,
			A2($elm$json$Json$Decode$field, fieldName, valueDecoder));
	});
var $author$project$Model$Decoders$verificationStatusDecoder = A3(
	$webbhuset$elm_json_decode$Json$Decode$Field$require,
	'status',
	$elm$json$Json$Decode$int,
	function (status) {
		return A3(
			$webbhuset$elm_json_decode$Json$Decode$Field$require,
			'decline_reason',
			$elm$json$Json$Decode$string,
			function (reason) {
				switch (status) {
					case 0:
						return $elm$json$Json$Decode$succeed($author$project$Model$DataModels$PENDING_VERIFICATION);
					case 1:
						return $elm$json$Json$Decode$succeed($author$project$Model$DataModels$VERIFIED);
					case 2:
						return $elm$json$Json$Decode$succeed(
							$author$project$Model$DataModels$DECLINED(reason));
					default:
						return $elm$json$Json$Decode$fail('Unknown status');
				}
			});
	});
var $author$project$Model$Decoders$extraBotDataDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'verification',
	$author$project$Model$Decoders$verificationStatusDecoder,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'stats',
		$elm$json$Json$Decode$nullable($author$project$Model$Decoders$statsDecoder),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'basic',
			$author$project$Model$Decoders$botPreviewDecoder,
			$elm$json$Json$Decode$succeed($author$project$Model$DataModels$ExtraBotData))));
var $author$project$Model$DataModels$ExtraServerData = F3(
	function (basic, stats, verified) {
		return {bG: basic, fi: stats, fy: verified};
	});
var $author$project$Model$DataModels$ServerPreview = F8(
	function (title, shortDescription, longDescription, averageRating, membersCount, owner, inviteLink, profileImageUrl) {
		return {dZ: averageRating, eH: inviteLink, eK: longDescription, eL: membersCount, eX: owner, e4: profileImageUrl, fg: shortDescription, fr: title};
	});
var $author$project$Model$Decoders$serverPreviewDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'profileImageUrl',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'inviteLink',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'owner',
			$author$project$Model$Decoders$botOwnerDecoder,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'membersCount',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
				$elm$core$Maybe$Nothing,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'averageRating',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'description',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'shortDescription',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'title',
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$succeed($author$project$Model$DataModels$ServerPreview)))))))));
var $author$project$Model$Decoders$extraServerDataDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'verification',
	$author$project$Model$Decoders$verificationStatusDecoder,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'stats',
		$elm$json$Json$Decode$nullable($author$project$Model$Decoders$statsDecoder),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'basic',
			$author$project$Model$Decoders$serverPreviewDecoder,
			$elm$json$Json$Decode$succeed($author$project$Model$DataModels$ExtraServerData))));
var $author$project$Model$DataModels$OwnedDbdBot = F6(
	function (name, commands, variables, isAvailableToImport, bdfdUUID, listUUID) {
		return {d$: bdfdUUID, ea: commands, c2: isAvailableToImport, da: listUUID, b5: name, fx: variables};
	});
var $author$project$Model$Decoders$ownedDbdBotDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'bdl_id',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'bdfd_id',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'available_import',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'variables',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'commands',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'name',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Model$DataModels$OwnedDbdBot)))))));
var $author$project$Model$Decoders$currentUserDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'owned_dbd_bots',
	$elm$json$Json$Decode$nullable(
		$elm$json$Json$Decode$list($author$project$Model$Decoders$ownedDbdBotDecoder)),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'owned_servers',
		$elm$json$Json$Decode$list($author$project$Model$Decoders$extraServerDataDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'owned_bots',
			$elm$json$Json$Decode$list($author$project$Model$Decoders$extraBotDataDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'discord_user',
				$author$project$Model$Decoders$discordUserDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'discord_id',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Model$DataModels$User)))))));
var $author$project$Model$Endpoints$currentUserInfo = $author$project$Model$Endpoints$endpointRoot + '/api/authorized/user/@me';
var $author$project$Model$Decoders$dbdCommandListDecoder = $elm$json$Json$Decode$list($author$project$Model$Decoders$dbdCommandDecoder);
var $author$project$Model$Endpoints$deleteBot = function (botId) {
	return $author$project$Model$Endpoints$endpointRoot + ('/api/authorized/bot/' + (botId + '/delete'));
};
var $author$project$Model$Endpoints$devInfo = $author$project$Model$Endpoints$endpointRoot + '/api/user';
var $author$project$Model$DataModels$DeveloperPreview = F5(
	function (discordUser, ownedBots, ownedServers, aboutMe, averageRating) {
		return {dS: aboutMe, dZ: averageRating, ep: discordUser, br: ownedBots, dn: ownedServers};
	});
var $author$project$Model$Decoders$developerPreviewDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'rating',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'about_me',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'owned_servers',
			$elm$json$Json$Decode$list($author$project$Model$Decoders$serverPreviewDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'owned_bots',
				$elm$json$Json$Decode$list($author$project$Model$Decoders$botPreviewDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'discord_user',
					$author$project$Model$Decoders$discordUserDecoder,
					$elm$json$Json$Decode$succeed($author$project$Model$DataModels$DeveloperPreview))))));
var $author$project$Model$Endpoints$editBot = function (botId) {
	return $author$project$Model$Endpoints$endpointRoot + ('/api/authorized/bot/' + (botId + '/edit'));
};
var $author$project$Model$Endpoints$exchangeCode = $author$project$Model$Endpoints$endpointRoot + '/api/exchange-code';
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$file$File$Select$file = F2(
	function (mimes, toMsg) {
		return A2(
			$elm$core$Task$perform,
			toMsg,
			_File_uploadOne(mimes));
	});
var $author$project$Model$Endpoints$getServer = function (id) {
	return $author$project$Model$Endpoints$endpointRoot + ('/api/authorized/server/' + id);
};
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $author$project$Model$isNewBotCreatorInEditMode = function (model) {
	return A2($elm$core$String$startsWith, '/edit-bot/', model.t.q);
};
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Model$Encoders$newBotReqEncoder = F2(
	function (bot, bdbBot) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string(bot.bK)),
					_Utils_Tuple2(
					'short_description',
					$elm$json$Json$Encode$string(bot.cw)),
					_Utils_Tuple2(
					'description',
					$elm$json$Json$Encode$string(bot.bJ)),
					_Utils_Tuple2(
					'entry_point',
					$elm$json$Json$Encode$string(bot.aI)),
					_Utils_Tuple2(
					'invite_link',
					$elm$json$Json$Encode$string(bot.eH)),
					_Utils_Tuple2(
					'avatar',
					$elm$json$Json$Encode$string(
						function () {
							var _v0 = bot.c_;
							if (!_v0.$) {
								var img = _v0.a;
								return img;
							} else {
								return '';
							}
						}())),
					_Utils_Tuple2(
					'additional_notes',
					$elm$json$Json$Encode$string(bot.ct)),
					_Utils_Tuple2(
					'bdfd_id',
					$elm$json$Json$Encode$string(
						function () {
							if (!bdbBot.$) {
								var dbdB = bdbBot.a;
								return dbdB.d$;
							} else {
								return '';
							}
						}())),
					_Utils_Tuple2(
					'category',
					$elm$json$Json$Encode$string(bot.cy))
				]));
	});
var $author$project$Model$Encoders$newServerReqEncoder = function (server) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(server.ce)),
				_Utils_Tuple2(
				'short_description',
				$elm$json$Json$Encode$string(server.fg)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(server.ay)),
				_Utils_Tuple2(
				'invite_link',
				$elm$json$Json$Encode$string(server.eH))
			]));
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$core$Basics$not = _Basics_not;
var $author$project$Model$profileImageUrl = function (imageUrl) {
	if (!imageUrl.$) {
		var a = imageUrl.a;
		return a;
	} else {
		return '/assets/defaults/profile.jpg';
	}
};
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Model$Endpoints$rateBot = function (botId) {
	return $author$project$Model$Endpoints$endpointRoot + ('/api/authorized/bot/' + (botId + '/rate'));
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Model$Encoders$rateReqEncoder = function (rating) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'rate',
				$elm$json$Json$Encode$int(rating))
			]));
};
var $author$project$Model$Endpoints$refreshBotToken = function (botId) {
	return $author$project$Model$Endpoints$endpointRoot + ('/api/authorized/bot/' + (botId + '/refresh-token'));
};
var $elm$browser$Browser$Navigation$reload = _Browser_reload(false);
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$Model$tmpBotId = function (preview) {
	var _v0 = preview.b0;
	if (!_v0.$) {
		var a = _v0.a;
		return a;
	} else {
		return 'testID';
	}
};
var $author$project$Model$replaceUserOwnedBot = F2(
	function (newValue, user) {
		return _Utils_update(
			user,
			{
				br: A2(
					$elm$core$List$map,
					function (bot) {
						return _Utils_eq(
							$author$project$Model$tmpBotId(bot.bG),
							$author$project$Model$tmpBotId(newValue.bG)) ? newValue : bot;
					},
					user.br)
			});
	});
var $author$project$Model$Endpoints$reportBot = function (botId) {
	return $author$project$Model$Endpoints$endpointRoot + ('/api/authorized/bot/' + (botId + '/report'));
};
var $author$project$Model$Encoders$reportEncoder = function (message) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'message',
				$elm$json$Json$Encode$string(message))
			]));
};
var $author$project$Model$resetBotEditMode = function (model) {
	return _Utils_update(
		model,
		{ax: $author$project$Model$DataModels$NO_EDITING});
};
var $author$project$Model$resetBotSearchResults = function (model) {
	return _Utils_update(
		model,
		{a4: $elm$core$Maybe$Nothing});
};
var $author$project$Model$resetCommandSearch = function (model) {
	return _Utils_update(
		model,
		{a9: ''});
};
var $author$project$Model$resetNewBotCreatorForm = function (model) {
	return _Utils_update(
		model,
		{e: $author$project$Model$defaultNewBotCreatorForm});
};
var $author$project$Model$resetReportForm = function (model) {
	return _Utils_update(
		model,
		{ad: $author$project$Model$defaultReportForm});
};
var $author$project$Model$setReportReason = F2(
	function (value, model) {
		var oldForm = model.ad;
		var newForm = _Utils_update(
			oldForm,
			{cd: value});
		return _Utils_update(
			model,
			{ad: newForm});
	});
var $author$project$Model$resetReportReason = function (model) {
	return A2($author$project$Model$setReportReason, '', model);
};
var $author$project$Model$resetSearchState = function (model) {
	return _Utils_update(
		model,
		{dC: $author$project$Model$DataModels$NOT_SEARCHING});
};
var $author$project$Model$resetUserDbdBots = function (model) {
	var _v0 = model.fv;
	if (_v0.$ === 1) {
		var user = _v0.a;
		var modifiedUser = _Utils_update(
			user,
			{dm: $elm$core$Maybe$Nothing});
		return _Utils_update(
			model,
			{
				fv: $author$project$Model$DataModels$SIGNED_IN(modifiedUser)
			});
	} else {
		return model;
	}
};
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{dV: true, d2: r.d2, m: r.m, y: r.y, A: r.A, E: r.E, F: r.F, t: r.t}));
};
var $author$project$Model$Endpoints$searchBots = $author$project$Model$Endpoints$endpointRoot + '/api/bot/search';
var $author$project$Model$Ports$setAuthToken = _Platform_outgoingPort('setAuthToken', $elm$json$Json$Encode$string);
var $author$project$Model$setBotPreviewDbdCommands = F2(
	function (dbdCommands, model) {
		var _v0 = model.fa;
		if (!_v0.$) {
			var result = _v0.a;
			if (!result.$) {
				var botPreview = result.a;
				var newBotPreview = _Utils_update(
					botPreview,
					{
						cI: $elm$core$Maybe$Just(dbdCommands)
					});
				return _Utils_update(
					model,
					{
						fa: $elm$core$Maybe$Just(
							$elm$core$Result$Ok(newBotPreview))
					});
			} else {
				var error = result.a;
				return model;
			}
		} else {
			return model;
		}
	});
var $author$project$Model$Ports$setClipboard = _Platform_outgoingPort('setClipboard', $elm$json$Json$Encode$string);
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $author$project$Model$sampleDbdCommands = $elm$core$Maybe$Just(
	A2(
		$elm$core$List$repeat,
		10,
		{ay: 'This is sample command description', b5: 'Test name', ft: '!test_command'}));
var $author$project$Model$dummyBotPreview = {
	dY: {bE: $elm$core$Maybe$Nothing, b0: 'TEST-ID', fn: '0000', fw: 'Loading'},
	dZ: $elm$core$Maybe$Just('0.0'),
	d_: 'https://drivetribe.imgix.net/Lpx_qpWnRLeL2UPXYGdvsw?w=1400&h=788&fm=webp&auto=compress&lossless=true&fit=crop&crop=faces',
	d1: 'TECi?iIq-3.TxZV@0%$zEToyNHxb',
	cI: $author$project$Model$sampleDbdCommands,
	ay: '',
	aI: '',
	b0: $elm$core$Maybe$Just('123'),
	eH: '',
	c3: true,
	eV: $elm$core$Maybe$Nothing,
	e4: $elm$core$Maybe$Nothing,
	fe: $elm$core$Maybe$Nothing,
	fg: '',
	fr: 'Not loaded'
};
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Model$setFeaturedBots = F2(
	function (bots, model) {
		var page = model.eE;
		var midBots = function () {
			var _v2 = $elm$core$List$tail(bots);
			if (!_v2.$) {
				var b = _v2.a;
				return b;
			} else {
				return _List_Nil;
			}
		}();
		var mainBot = function () {
			var _v1 = $elm$core$List$head(bots);
			if (!_v1.$) {
				var b = _v1.a;
				return b;
			} else {
				return $author$project$Model$dummyBotPreview;
			}
		}();
		var newPage = function () {
			if (!page.$) {
				var p = page.a;
				return $elm$core$Maybe$Just(
					_Utils_update(
						p,
						{aO: mainBot, eN: midBots}));
			} else {
				return $elm$core$Maybe$Just(
					{a3: _List_Nil, bH: _List_Nil, aO: mainBot, eN: midBots, bk: _List_Nil, bv: 1});
			}
		}();
		return _Utils_update(
			model,
			{eE: newPage});
	});
var $author$project$Model$setNewBotCreatorAdditionalNotes = F2(
	function (newValue, model) {
		var form = model.e;
		var newForm = _Utils_update(
			form,
			{ct: newValue});
		return _Utils_update(
			model,
			{e: newForm});
	});
var $author$project$Model$setNewBotCreatorBotDescription = F2(
	function (newValue, model) {
		var form = model.e;
		var newForm = _Utils_update(
			form,
			{bJ: newValue});
		return _Utils_update(
			model,
			{e: newForm});
	});
var $author$project$Model$setNewBotCreatorBotShortDescription = F2(
	function (newValue, model) {
		var form = model.e;
		var newForm = _Utils_update(
			form,
			{cw: newValue});
		return _Utils_update(
			model,
			{e: newForm});
	});
var $author$project$Model$setNewBotCreatorBotTitle = F2(
	function (newValue, model) {
		var form = model.e;
		var newForm = _Utils_update(
			form,
			{bK: newValue});
		return _Utils_update(
			model,
			{e: newForm});
	});
var $author$project$Model$setNewBotCreatorCategory = F2(
	function (newValue, model) {
		var form = model.e;
		var newForm = _Utils_update(
			form,
			{cy: newValue});
		return _Utils_update(
			model,
			{e: newForm});
	});
var $author$project$Model$setNewBotCreatorEntryPoint = F2(
	function (newValue, model) {
		var form = model.e;
		var newForm = _Utils_update(
			form,
			{aI: newValue});
		return _Utils_update(
			model,
			{e: newForm});
	});
var $author$project$Model$setNewBotCreatorErrorMessage = F2(
	function (newValue, model) {
		var form = model.e;
		var newForm = _Utils_update(
			form,
			{aA: newValue});
		return _Utils_update(
			model,
			{e: newForm});
	});
var $author$project$Model$setNewBotCreatorImage = F2(
	function (newValue, model) {
		var form = model.e;
		var newForm = _Utils_update(
			form,
			{
				c_: $elm$core$Maybe$Just(newValue)
			});
		return _Utils_update(
			model,
			{e: newForm});
	});
var $author$project$Model$setNewBotCreatorInviteLink = F2(
	function (newValue, model) {
		var form = model.e;
		var newForm = _Utils_update(
			form,
			{eH: newValue});
		return _Utils_update(
			model,
			{e: newForm});
	});
var $author$project$Model$setNewBotCreatorSendingInProgress = F2(
	function (newValue, model) {
		var form = model.e;
		var newForm = _Utils_update(
			form,
			{bx: newValue});
		return _Utils_update(
			model,
			{e: newForm});
	});
var $author$project$Model$setNewBots = F2(
	function (bots, model) {
		var page = function () {
			var _v0 = model.eE;
			if (!_v0.$) {
				var p = _v0.a;
				return $elm$core$Maybe$Just(
					_Utils_update(
						p,
						{bk: bots}));
			} else {
				return $elm$core$Maybe$Just(
					{a3: _List_Nil, bH: _List_Nil, aO: $author$project$Model$dummyBotPreview, eN: _List_Nil, bk: bots, bv: 1});
			}
		}();
		return _Utils_update(
			model,
			{eE: page});
	});
var $author$project$Model$setNewServerCreatorName = F2(
	function (newValue, model) {
		var form = model.k;
		var newForm = _Utils_update(
			form,
			{ce: newValue});
		return _Utils_update(
			model,
			{k: newForm});
	});
var $author$project$Model$setNewServerDescription = F2(
	function (newValue, model) {
		var form = model.k;
		var newForm = _Utils_update(
			form,
			{ay: newValue});
		return _Utils_update(
			model,
			{k: newForm});
	});
var $author$project$Model$setNewServerErrorMessage = F2(
	function (newValue, model) {
		var form = model.k;
		var newForm = _Utils_update(
			form,
			{aA: newValue});
		return _Utils_update(
			model,
			{k: newForm});
	});
var $author$project$Model$setNewServerInviteLink = F2(
	function (newValue, model) {
		var form = model.k;
		var newForm = _Utils_update(
			form,
			{eH: newValue});
		return _Utils_update(
			model,
			{k: newForm});
	});
var $author$project$Model$setNewServerSendingInProgress = F2(
	function (newValue, model) {
		var form = model.k;
		var newForm = _Utils_update(
			form,
			{bx: newValue});
		return _Utils_update(
			model,
			{k: newForm});
	});
var $author$project$Model$setNewServerShortDescription = F2(
	function (newValue, model) {
		var form = model.k;
		var newForm = _Utils_update(
			form,
			{fg: newValue});
		return _Utils_update(
			model,
			{k: newForm});
	});
var $author$project$Model$setRatingDialogHoverStars = F2(
	function (newStars, model) {
		var oldRating = model.S;
		var newRating = _Utils_update(
			oldRating,
			{b_: newStars});
		return _Utils_update(
			model,
			{S: newRating});
	});
var $author$project$Model$setRatingDialogStars = F2(
	function (newStars, model) {
		var oldRating = model.S;
		var newRating = _Utils_update(
			oldRating,
			{
				aT: $elm$core$Maybe$Just(newStars)
			});
		return _Utils_update(
			model,
			{S: newRating});
	});
var $author$project$Model$setReportSubmitInProgress = F2(
	function (value, model) {
		var oldForm = model.ad;
		var newForm = _Utils_update(
			oldForm,
			{c4: value});
		return _Utils_update(
			model,
			{ad: newForm});
	});
var $author$project$Model$setTopRatedBots = F2(
	function (bots, model) {
		var page = function () {
			var _v0 = model.eE;
			if (!_v0.$) {
				var p = _v0.a;
				return $elm$core$Maybe$Just(
					_Utils_update(
						p,
						{a3: bots}));
			} else {
				return $elm$core$Maybe$Just(
					{a3: bots, bH: _List_Nil, aO: $author$project$Model$dummyBotPreview, eN: _List_Nil, bk: _List_Nil, bv: 1});
			}
		}();
		return _Utils_update(
			model,
			{eE: page});
	});
var $author$project$Model$setUserOwnedDbdBots = F3(
	function (newValue, user, model) {
		var newUser = _Utils_update(
			user,
			{dm: newValue});
		return _Utils_update(
			model,
			{
				fv: $author$project$Model$DataModels$SIGNED_IN(newUser)
			});
	});
var $author$project$Model$DataModels$BotSettings = function (apiKey) {
	return {dW: apiKey};
};
var $author$project$Model$Decoders$settingsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'token',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Model$DataModels$BotSettings));
var $elm$file$File$size = _File_size;
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.ds;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.aK,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.e5,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.dp,
					_Utils_ap(http, url.cV)),
				url.q)));
};
var $elm$file$File$toUrl = _File_toUrl;
var $author$project$Model$DataModels$Token = function (token) {
	return {cp: token};
};
var $author$project$Model$Decoders$tokenDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'token',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Model$DataModels$Token));
var $author$project$Model$Endpoints$userBdfdBots = $author$project$Model$Endpoints$endpointRoot + '/api/authorized/user/bdfd-bots';
var $author$project$Model$withUser = F2(
	function (state, f) {
		if (state.$ === 1) {
			var user = state.a;
			return $author$project$Model$DataModels$SIGNED_IN(
				f(user));
		} else {
			return state;
		}
	});
var $author$project$Model$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 57:
				var token = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{l: token}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Model$run(
								$author$project$Model$RetrieveUser(token)),
								function () {
								var _v1 = model.t.e5;
								if (!_v1.$) {
									var query = _v1.a;
									return A2($elm$core$String$startsWith, 'code=', query) ? $elm$http$Http$riskyRequest(
										{
											d2: $elm$http$Http$emptyBody,
											m: A2($elm$http$Http$expectJson, $author$project$Model$GotExchangeToken, $author$project$Model$Decoders$tokenDecoder),
											y: _List_fromArray(
												[
													A2($elm$http$Http$header, 'authorization', 'bearer ' + model.l)
												]),
											A: 'GET',
											E: $elm$core$Maybe$Nothing,
											F: $elm$core$Maybe$Nothing,
											t: $author$project$Model$Endpoints$exchangeCode + ('/' + A2($elm$core$String$dropLeft, 5, query))
										}) : $elm$core$Platform$Cmd$none;
								} else {
									return $elm$core$Platform$Cmd$none;
								}
							}()
							])));
			case 0:
				var urlRequest = msg.a;
				if (!urlRequest.$) {
					var url = urlRequest.a;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.x,
							$elm$url$Url$toString(url)));
				} else {
					var href = urlRequest.a;
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(href));
				}
			case 1:
				var url = msg.a;
				return (_Utils_eq(model.t.q, url.q) && (!_Utils_eq(model.t.aK, url.aK))) ? ((A2($elm$core$String$startsWith, '/bot', url.q) && _Utils_eq(
					url.aK,
					$elm$core$Maybe$Just('rate-bot'))) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{t: url}),
					$author$project$Model$run($author$project$Model$BotPreviewRatingDialogOpened)) : _Utils_Tuple2(
					_Utils_update(
						model,
						{t: url}),
					$elm$core$Platform$Cmd$none)) : (A2($elm$core$String$startsWith, '/bot/', url.q) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{t: url}),
					$author$project$Model$run(
						$author$project$Model$LoadBotWithId(
							A3($elm$core$String$replace, '/bot/', '', url.q)))) : (A2($elm$core$String$startsWith, '/import-bot', url.q) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{t: url}),
					$elm$http$Http$riskyRequest(
						{
							d2: $elm$http$Http$emptyBody,
							m: A2(
								$elm$http$Http$expectJson,
								$author$project$Model$DesignerBotsFetched,
								$elm$json$Json$Decode$list($author$project$Model$Decoders$ownedDbdBotDecoder)),
							y: _List_fromArray(
								[
									A2($elm$http$Http$header, 'authorization', 'bearer ' + model.l)
								]),
							A: 'GET',
							E: $elm$core$Maybe$Just(120000),
							F: $elm$core$Maybe$Nothing,
							t: $author$project$Model$Endpoints$userBdfdBots
						})) : (A2($elm$core$String$startsWith, '/dev/', url.q) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{t: url}),
					$author$project$Model$run(
						$author$project$Model$LoadDevWithId(
							A3($elm$core$String$replace, '/dev/', '', url.q)))) : (A2($elm$core$String$startsWith, '/search/', url.q) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{t: url}),
					$author$project$Model$run(
						$author$project$Model$FetchSearchResults(
							A3($elm$core$String$replace, '/search/', '', url.q)))) : (A2($elm$core$String$startsWith, '/edit-bot/', url.q) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{t: url}),
					$author$project$Model$run(
						$author$project$Model$LoadEditBotWithId(
							A3($elm$core$String$replace, '/edit-bot/', '', url.q)))) : (A2($elm$core$String$startsWith, '/bot-settings/', url.q) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{t: url}),
					$author$project$Model$run(
						$author$project$Model$LoadBotSettingsWithId(
							A3($elm$core$String$replace, '/bot-settings/', '', url.q)))) : (A2($elm$core$String$startsWith, '/server/', url.q) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{t: url}),
					$author$project$Model$run(
						$author$project$Model$LoadServerPreviewWithId(
							$author$project$Model$DataModels$GET_SERVER(
								A3($elm$core$String$replace, '/server/', '', url.q))))) : (A2($elm$core$String$startsWith, '/report', url.q) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{t: url}),
					$author$project$Model$run(
						$author$project$Model$ReportMsg($author$project$Model$DataModels$RESET_REASON_FORM))) : _Utils_Tuple2(
					_Utils_update(
						model,
						{t: url}),
					$elm$core$Platform$Cmd$none)))))))));
			case 2:
				var phase = msg.a;
				return (!$elm$core$String$length(phase)) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{dC: $author$project$Model$DataModels$NOT_SEARCHING}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dC: $author$project$Model$DataModels$SEARCHING(phase)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dC: $author$project$Model$DataModels$NOT_SEARCHING}),
					$elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					model,
					A2($elm$browser$Browser$Navigation$back, model.x, 1));
			case 6:
				var createBotType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a1: $elm$core$Maybe$Just(createBotType)
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var sampleBot = {ea: 230, c2: true, da: 'uuid-zaq', b5: 'Testing bot', fx: 230};
				var sampleUnavailableBotTmp = _Utils_update(
					sampleBot,
					{c2: false});
				var sampleUnavailableBot = _Utils_update(
					sampleUnavailableBotTmp,
					{da: 'uuid-wsx'});
				var _v3 = model.a1;
				if (!_v3.$) {
					var botType = _v3.a;
					if (!botType) {
						return _Utils_Tuple2(
							$author$project$Model$resetBotEditMode(
								$author$project$Model$resetNewBotCreatorForm(
									$author$project$Model$resetUserDbdBots(model))),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$browser$Browser$Navigation$pushUrl, model.x, '/import-bot')
									])));
					} else {
						return _Utils_Tuple2(
							$author$project$Model$resetUserDbdBots(
								$author$project$Model$resetNewBotCreatorForm(model)),
							A2($elm$browser$Browser$Navigation$pushUrl, model.x, '/newBotCreator'));
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				var value = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Model$setNewBotCreatorBotTitle, value, model),
					$elm$core$Platform$Cmd$none);
			case 9:
				var value = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Model$setNewBotCreatorBotDescription, value, model),
					$elm$core$Platform$Cmd$none);
			case 13:
				var value = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Model$setNewBotCreatorEntryPoint, value, model),
					$elm$core$Platform$Cmd$none);
			case 14:
				var value = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Model$setNewBotCreatorInviteLink, value, model),
					$elm$core$Platform$Cmd$none);
			case 15:
				var value = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Model$setNewBotCreatorAdditionalNotes, value, model),
					$elm$core$Platform$Cmd$none);
			case 16:
				return ($elm$core$String$length(model.e.bK) < 2) ? _Utils_Tuple2(
					A2(
						$author$project$Model$setNewBotCreatorErrorMessage,
						$elm$core$Maybe$Just('Bot title should be at least 2 characters long'),
						model),
					$elm$core$Platform$Cmd$none) : (($elm$core$String$length(model.e.bJ) < 100) ? _Utils_Tuple2(
					A2(
						$author$project$Model$setNewBotCreatorErrorMessage,
						$elm$core$Maybe$Just('Your bot description should be at least 100 characters long'),
						model),
					$elm$core$Platform$Cmd$none) : (($elm$core$String$length(model.e.eH) < 1) ? _Utils_Tuple2(
					A2(
						$author$project$Model$setNewBotCreatorErrorMessage,
						$elm$core$Maybe$Just('Your have to provide invite link'),
						model),
					$elm$core$Platform$Cmd$none) : (($elm$core$String$length(model.e.aI) < 1) ? _Utils_Tuple2(
					A2(
						$author$project$Model$setNewBotCreatorErrorMessage,
						$elm$core$Maybe$Just('Your have to provide entry point'),
						model),
					$elm$core$Platform$Cmd$none) : ((!(A2($elm$core$String$startsWith, 'https://discord.com/', model.e.eH) || A2($elm$core$String$startsWith, 'https://discordapp.com/', model.e.eH))) ? _Utils_Tuple2(
					A2(
						$author$project$Model$setNewBotCreatorErrorMessage,
						$elm$core$Maybe$Just('Your invite link has to start with \"https://discord.com/\"'),
						model),
					$elm$core$Platform$Cmd$none) : (_Utils_eq(
					$author$project$Model$clientIdFromInviteLink(model.e.eH),
					$elm$core$Maybe$Nothing) ? _Utils_Tuple2(
					A2(
						$author$project$Model$setNewBotCreatorErrorMessage,
						$elm$core$Maybe$Just('Your invite link is missing client id'),
						model),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					A2(
						$author$project$Model$setNewBotCreatorErrorMessage,
						$elm$core$Maybe$Nothing,
						A2($author$project$Model$setNewBotCreatorSendingInProgress, true, model)),
					$elm$http$Http$riskyRequest(
						{
							d2: $elm$http$Http$jsonBody(
								A2($author$project$Model$Encoders$newBotReqEncoder, model.e, model.aS)),
							m: A2($elm$http$Http$expectJson, $author$project$Model$NewBotCreatorReceivedForm, $author$project$Model$Decoders$extraBotDataDecoder),
							y: _List_fromArray(
								[
									A2($elm$http$Http$header, 'authorization', 'bearer ' + model.l)
								]),
							A: 'POST',
							E: $elm$core$Maybe$Just(60000),
							F: $elm$core$Maybe$Nothing,
							t: $author$project$Model$isNewBotCreatorInEditMode(model) ? $author$project$Model$Endpoints$editBot(
								A3($elm$core$String$replace, '/edit-bot/', '', model.t.q)) : $author$project$Model$Endpoints$createBot
						})))))));
			case 17:
				var result = msg.a;
				if (!result.$) {
					var bot = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								e: $author$project$Model$defaultNewBotCreatorForm,
								fv: A2(
									$author$project$Model$withUser,
									model.fv,
									$author$project$Model$isNewBotCreatorInEditMode(model) ? $author$project$Model$replaceUserOwnedBot(bot) : $author$project$Model$addUserOwnedBot(bot))
							}),
						A2($elm$browser$Browser$Navigation$pushUrl, model.x, '/account'));
				} else {
					return _Utils_Tuple2(
						A2(
							$author$project$Model$setNewBotCreatorSendingInProgress,
							false,
							A2(
								$author$project$Model$setNewBotCreatorErrorMessage,
								$elm$core$Maybe$Just('Could not create this bot.'),
								model)),
						$elm$core$Platform$Cmd$none);
				}
			case 20:
				var result = msg.a;
				if (!result.$) {
					var bots = result.a;
					var _v7 = model.fv;
					if (_v7.$ === 1) {
						var user = _v7.a;
						return _Utils_Tuple2(
							A3(
								$author$project$Model$setUserOwnedDbdBots,
								$elm$core$Maybe$Just(bots),
								user,
								model),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 21:
				var ownedDbdBot = msg.a;
				var modelWithDefaultForm = $author$project$Model$resetNewBotCreatorForm(model);
				var modelWithSelectedImportedBot = _Utils_update(
					modelWithDefaultForm,
					{
						aS: $elm$core$Maybe$Just(ownedDbdBot)
					});
				var modelWithFilledFields = A2($author$project$Model$setNewBotCreatorBotTitle, ownedDbdBot.b5, modelWithSelectedImportedBot);
				return _Utils_Tuple2(
					modelWithFilledFields,
					A2($elm$browser$Browser$Navigation$pushUrl, model.x, '/newBotCreator'));
			case 10:
				return _Utils_Tuple2(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['image/png']),
						$author$project$Model$NewBotCreatorImageLoaded));
			case 11:
				var image = msg.a;
				return (_Utils_cmp(
					$elm$file$File$size(image),
					(1024 * 1024) * 5) > 0) ? _Utils_Tuple2(
					A2(
						$author$project$Model$setNewBotCreatorErrorMessage,
						$elm$core$Maybe$Just('Selected file is too big'),
						model),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						$author$project$Model$NewBotCreatorImageDecoded,
						$elm$file$File$toUrl(image)));
			case 12:
				var v = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Model$setNewBotCreatorImage, v, model),
					$elm$core$Platform$Cmd$none);
			case 22:
				var result = msg.a;
				if (!result.$) {
					var bots = result.a;
					return _Utils_Tuple2(
						A2($author$project$Model$setFeaturedBots, bots, model),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 23:
				var result = msg.a;
				if (!result.$) {
					var bots = result.a;
					return _Utils_Tuple2(
						A2($author$project$Model$setTopRatedBots, bots, model),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 24:
				var result = msg.a;
				if (!result.$) {
					var bots = result.a;
					return _Utils_Tuple2(
						A2($author$project$Model$setNewBots, bots, model),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 25:
				var result = msg.a;
				if (!result.$) {
					var user = result.a;
					var newModel = _Utils_update(
						model,
						{
							fv: $author$project$Model$DataModels$SIGNED_IN(user)
						});
					var defaultResult = _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
					var _v12 = model.fa;
					if (!_v12.$) {
						var res = _v12.a;
						if (!res.$) {
							return defaultResult;
						} else {
							var error = res.a;
							if (error.$ === 3) {
								var code = error.a;
								return (code === 404) ? _Utils_Tuple2(
									newModel,
									$author$project$Model$run(
										$author$project$Model$LoadBotWithId(
											A3($elm$core$String$replace, '/bot/', '', model.t.q)))) : defaultResult;
							} else {
								return defaultResult;
							}
						}
					} else {
						return defaultResult;
					}
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{fv: $author$project$Model$DataModels$NOT_SIGNED}),
						$elm$core$Platform$Cmd$none);
				}
			case 26:
				var result = msg.a;
				if (!result.$) {
					var tok = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{l: tok.cp}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Model$Ports$setAuthToken(tok.cp),
									$author$project$Model$run(
									$author$project$Model$RetrieveUser(tok.cp)),
									A2($elm$browser$Browser$Navigation$pushUrl, model.x, '/')
								])));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 27:
				var token = msg.a;
				return _Utils_Tuple2(
					model,
					$elm$http$Http$riskyRequest(
						{
							d2: $elm$http$Http$emptyBody,
							m: A2($elm$http$Http$expectJson, $author$project$Model$GotCurrentUser, $author$project$Model$Decoders$currentUserDecoder),
							y: (token === '') ? _List_Nil : _List_fromArray(
								[
									A2($elm$http$Http$header, 'authorization', 'bearer ' + token)
								]),
							A: 'GET',
							E: $elm$core$Maybe$Nothing,
							F: $elm$core$Maybe$Nothing,
							t: $author$project$Model$Endpoints$currentUserInfo
						}));
			case 30:
				var botId = msg.a;
				var newModel = $author$project$Model$resetCommandSearch(model);
				return _Utils_Tuple2(
					_Utils_update(
						newModel,
						{fa: $elm$core$Maybe$Nothing}),
					$elm$http$Http$riskyRequest(
						{
							d2: $elm$http$Http$emptyBody,
							m: A2($elm$http$Http$expectJson, $author$project$Model$GotBotPreview, $author$project$Model$Decoders$botPreviewDecoder),
							y: _List_fromArray(
								[
									A2($elm$http$Http$header, 'authorization', 'bearer ' + model.l)
								]),
							A: 'GET',
							E: $elm$core$Maybe$Nothing,
							F: $elm$core$Maybe$Nothing,
							t: $author$project$Model$Endpoints$botInfo + ('/' + botId)
						}));
			case 28:
				var result = msg.a;
				if (!result.$) {
					var value = result.a;
					return value.c3 ? _Utils_Tuple2(
						_Utils_update(
							model,
							{
								fa: $elm$core$Maybe$Just(result)
							}),
						$elm$http$Http$riskyRequest(
							{
								d2: $elm$http$Http$emptyBody,
								m: A2($elm$http$Http$expectJson, $author$project$Model$GotDbdCommands, $author$project$Model$Decoders$dbdCommandListDecoder),
								y: _List_fromArray(
									[
										A2($elm$http$Http$header, 'authorization', 'bearer ' + model.l)
									]),
								A: 'GET',
								E: $elm$core$Maybe$Just(60000),
								F: $elm$core$Maybe$Nothing,
								t: $author$project$Model$Endpoints$botCommands(
									$author$project$Model$tmpBotId(value))
							})) : _Utils_Tuple2(
						_Utils_update(
							model,
							{
								fa: $elm$core$Maybe$Just(result)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								fa: $elm$core$Maybe$Just(result)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 29:
				var result = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fc: $elm$core$Maybe$Just(result)
						}),
					$elm$core$Platform$Cmd$none);
			case 18:
				var description = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Model$setNewBotCreatorBotShortDescription, description, model),
					$elm$core$Platform$Cmd$none);
			case 31:
				var devID = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fc: $elm$core$Maybe$Nothing}),
					$elm$http$Http$riskyRequest(
						{
							d2: $elm$http$Http$emptyBody,
							m: A2($elm$http$Http$expectJson, $author$project$Model$GotDevPreview, $author$project$Model$Decoders$developerPreviewDecoder),
							y: _List_fromArray(
								[
									A2($elm$http$Http$header, 'authorization', 'bearer ' + model.l)
								]),
							A: 'GET',
							E: $elm$core$Maybe$Nothing,
							F: $elm$core$Maybe$Nothing,
							t: $author$project$Model$Endpoints$devInfo + ('/' + devID)
						}));
			case 32:
				var _v17 = model.dC;
				if (!_v17.$) {
					return _Utils_Tuple2(
						$author$project$Model$resetSearchState(model),
						A2($elm$browser$Browser$Navigation$pushUrl, model.x, '/search/'));
				} else {
					var phrase = _v17.a;
					return _Utils_Tuple2(
						$author$project$Model$resetBotSearchResults(
							$author$project$Model$resetSearchState(model)),
						A2($elm$browser$Browser$Navigation$pushUrl, model.x, '/search/' + phrase));
				}
			case 33:
				var result = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a4: $elm$core$Maybe$Just(result)
						}),
					$elm$core$Platform$Cmd$none);
			case 34:
				var phrase = msg.a;
				return _Utils_Tuple2(
					model,
					$elm$http$Http$get(
						{
							m: A2($elm$http$Http$expectJson, $author$project$Model$GotSearchedBots, $author$project$Model$Decoders$featuredBotsDecoder),
							t: $author$project$Model$Endpoints$searchBots + ('/' + phrase)
						}));
			case 35:
				var stars = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Model$setRatingDialogStars, stars, model),
					$elm$core$Platform$Cmd$none);
			case 36:
				var stars = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Model$setRatingDialogHoverStars, stars, model),
					$elm$core$Platform$Cmd$none);
			case 37:
				var botId = msg.a;
				var _v18 = model.S.aT;
				if (!_v18.$) {
					var rating = _v18.a;
					return _Utils_Tuple2(
						model,
						$elm$http$Http$riskyRequest(
							{
								d2: $elm$http$Http$jsonBody(
									$author$project$Model$Encoders$rateReqEncoder(rating)),
								m: $elm$http$Http$expectWhatever($author$project$Model$BotPreviewRatingDone),
								y: _List_fromArray(
									[
										A2($elm$http$Http$header, 'authorization', 'bearer ' + model.l)
									]),
								A: 'POST',
								E: $elm$core$Maybe$Nothing,
								F: $elm$core$Maybe$Nothing,
								t: $author$project$Model$Endpoints$rateBot(botId)
							}));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 38:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{S: $author$project$Model$defaultRatingDialog}),
					$elm$core$Platform$Cmd$none);
			case 39:
				var _v19 = model.fa;
				if (!_v19.$) {
					var result = _v19.a;
					if (!result.$) {
						var bot = result.a;
						return _Utils_Tuple2(
							model,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$elm$browser$Browser$Navigation$pushUrl,
										model.x,
										'/bot/' + $author$project$Model$tmpBotId(bot)),
										$author$project$Model$run(
										$author$project$Model$LoadBotWithId(
											A3($elm$core$String$replace, '/bot/', '', model.t.q)))
									])));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 40:
				var id = msg.a;
				var newModel = $author$project$Model$resetNewBotCreatorForm(model);
				return _Utils_Tuple2(
					_Utils_update(
						newModel,
						{ax: $author$project$Model$DataModels$FETCHING}),
					$elm$http$Http$riskyRequest(
						{
							d2: $elm$http$Http$emptyBody,
							m: A2($elm$http$Http$expectJson, $author$project$Model$LoadedEditBotWithId, $author$project$Model$Decoders$botPreviewDecoder),
							y: _List_fromArray(
								[
									A2($elm$http$Http$header, 'authorization', 'bearer ' + model.l)
								]),
							A: 'GET',
							E: $elm$core$Maybe$Nothing,
							F: $elm$core$Maybe$Nothing,
							t: $author$project$Model$Endpoints$botInfo + ('/' + id)
						}));
			case 19:
				var newCategory = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Model$setNewBotCreatorCategory, newCategory, model),
					$elm$core$Platform$Cmd$none);
			case 41:
				var result = msg.a;
				if (!result.$) {
					var bot = result.a;
					var newModel = A2(
						$author$project$Model$setNewBotCreatorImage,
						$author$project$Model$profileImageUrl(bot.e4),
						A2(
							$author$project$Model$setNewBotCreatorInviteLink,
							bot.eH,
							A2(
								$author$project$Model$setNewBotCreatorEntryPoint,
								bot.aI,
								A2(
									$author$project$Model$setNewBotCreatorBotDescription,
									bot.ay,
									A2(
										$author$project$Model$setNewBotCreatorBotShortDescription,
										bot.fg,
										A2($author$project$Model$setNewBotCreatorBotTitle, bot.fr, model))))));
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{ax: $author$project$Model$DataModels$READY}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ax: $author$project$Model$DataModels$EDIT_LOADING_ERROR(error)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 42:
				var result = msg.a;
				if (!result.$) {
					var commandList = result.a;
					return _Utils_Tuple2(
						A2($author$project$Model$setBotPreviewDbdCommands, commandList, model),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 43:
				var string = msg.a;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Model$Ports$setClipboard(string),
								$author$project$Model$run(
								$author$project$Model$ShowToast('Copied to clipboard'))
							])));
			case 44:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aH: $elm$core$Maybe$Nothing}),
					$elm$http$Http$riskyRequest(
						{
							d2: $elm$http$Http$emptyBody,
							m: $elm$http$Http$expectWhatever($author$project$Model$GotTokenInvalidated),
							y: _List_fromArray(
								[
									A2($elm$http$Http$header, 'authorization', 'bearer ' + model.l)
								]),
							A: 'POST',
							E: $elm$core$Maybe$Nothing,
							F: $elm$core$Maybe$Nothing,
							t: $author$project$Model$Endpoints$refreshBotToken(
								A3($elm$core$String$replace, '/bot-settings/', '', model.t.q))
						}));
			case 45:
				var content = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dM: $elm$core$Maybe$Just(content)
						}),
					A3($andrewMacmurray$elm_delay$Delay$after, 2850, 0, $author$project$Model$HideToast));
			case 46:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dM: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 47:
				return _Utils_Tuple2(model, $elm$browser$Browser$Navigation$reload);
			case 50:
				var result = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aH: $elm$core$Maybe$Just(result)
						}),
					$elm$core$Platform$Cmd$none);
			case 48:
				var id = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aH: $elm$core$Maybe$Nothing}),
					$elm$http$Http$riskyRequest(
						{
							d2: $elm$http$Http$emptyBody,
							m: A2($elm$http$Http$expectJson, $author$project$Model$GotBotSettings, $author$project$Model$Decoders$settingsDecoder),
							y: _List_fromArray(
								[
									A2($elm$http$Http$header, 'authorization', 'bearer ' + model.l)
								]),
							A: 'GET',
							E: $elm$core$Maybe$Nothing,
							F: $elm$core$Maybe$Nothing,
							t: $author$project$Model$Endpoints$botSettings(id)
						}));
			case 49:
				var message = msg.a;
				if (!message.$) {
					var id = message.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bw: $elm$core$Maybe$Nothing}),
						$elm$http$Http$riskyRequest(
							{
								d2: $elm$http$Http$emptyBody,
								m: A2(
									$elm$http$Http$expectJson,
									A2($elm$core$Basics$composeL, $author$project$Model$LoadServerPreviewWithId, $author$project$Model$DataModels$ServerReceived),
									$author$project$Model$Decoders$serverPreviewDecoder),
								y: _List_fromArray(
									[
										A2($elm$http$Http$header, 'authorization', 'bearer ' + model.l)
									]),
								A: 'GET',
								E: $elm$core$Maybe$Nothing,
								F: $elm$core$Maybe$Nothing,
								t: $author$project$Model$Endpoints$getServer(id)
							}));
				} else {
					var result = message.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bw: $elm$core$Maybe$Just(result)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 51:
				var name = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Model$setNewServerCreatorName, name, model),
					$elm$core$Platform$Cmd$none);
			case 52:
				var shortDescription = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Model$setNewServerShortDescription, shortDescription, model),
					$elm$core$Platform$Cmd$none);
			case 53:
				var description = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Model$setNewServerDescription, description, model),
					$elm$core$Platform$Cmd$none);
			case 54:
				var inviteLink = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Model$setNewServerInviteLink, inviteLink, model),
					$elm$core$Platform$Cmd$none);
			case 55:
				var errorMessage = $author$project$Model$run(
					$author$project$Model$ShowToast('You have errors in form'));
				return ($elm$core$String$length(model.k.ce) < 3) ? _Utils_Tuple2(
					A2(
						$author$project$Model$setNewServerErrorMessage,
						$elm$core$Maybe$Just('Server name should be at least 3 charactes long'),
						model),
					errorMessage) : (($elm$core$String$length(model.k.fg) < 30) ? _Utils_Tuple2(
					A2(
						$author$project$Model$setNewServerErrorMessage,
						$elm$core$Maybe$Just('Server short description should be at least 30 charactes long'),
						model),
					errorMessage) : (($elm$core$String$length(model.k.ay) < 50) ? _Utils_Tuple2(
					A2(
						$author$project$Model$setNewServerErrorMessage,
						$elm$core$Maybe$Just('Your server description should be at least 50 characters long'),
						model),
					errorMessage) : _Utils_Tuple2(
					A2($author$project$Model$setNewServerSendingInProgress, true, model),
					$elm$http$Http$riskyRequest(
						{
							d2: $elm$http$Http$jsonBody(
								$author$project$Model$Encoders$newServerReqEncoder(model.k)),
							m: A2($elm$http$Http$expectJson, $author$project$Model$NewServerCreatorReceivedForm, $author$project$Model$Decoders$extraServerDataDecoder),
							y: _List_fromArray(
								[
									A2($elm$http$Http$header, 'authorization', 'bearer ' + model.l)
								]),
							A: 'POST',
							E: $elm$core$Maybe$Nothing,
							F: $elm$core$Maybe$Nothing,
							t: $author$project$Model$Endpoints$createServer
						}))));
			case 56:
				var result = msg.a;
				if (!result.$) {
					var server = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								k: $author$project$Model$defaultNewServerCreatorForm,
								fv: A2(
									$author$project$Model$withUser,
									model.fv,
									$author$project$Model$addUserOwnedServer(server))
							}),
						A2($elm$browser$Browser$Navigation$pushUrl, model.x, '/account'));
				} else {
					return _Utils_Tuple2(
						A2(
							$author$project$Model$setNewServerSendingInProgress,
							false,
							A2(
								$author$project$Model$setNewServerErrorMessage,
								$elm$core$Maybe$Just('Could not create this bot.'),
								model)),
						$elm$core$Platform$Cmd$none);
				}
			case 58:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fv: $author$project$Model$DataModels$NOT_SIGNED}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Model$Ports$setAuthToken(''),
								A2($elm$browser$Browser$Navigation$pushUrl, model.x, '/')
							])));
			case 59:
				var lang = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bR: lang}),
					$elm$core$Platform$Cmd$none);
			case 60:
				var value = msg.a;
				var _v25 = model.eE;
				if (!_v25.$) {
					var a = _v25.a;
					var index = a;
					var newIndex = _Utils_update(
						index,
						{bv: value});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								eE: $elm$core$Maybe$Just(newIndex)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 61:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a9: search}),
					$elm$core$Platform$Cmd$none);
			case 62:
				var reportMessage = msg.a;
				switch (reportMessage.$) {
					case 0:
						var reason = reportMessage.a;
						return _Utils_Tuple2(
							A2($author$project$Model$setReportReason, reason, model),
							$elm$core$Platform$Cmd$none);
					case 2:
						var newModel = A2(
							$author$project$Model$setReportSubmitInProgress,
							true,
							$author$project$Model$resetReportReason(model));
						var botId = A3($elm$core$String$replace, '/report/bot/', '', model.t.q);
						return _Utils_Tuple2(
							newModel,
							$elm$http$Http$riskyRequest(
								{
									d2: $elm$http$Http$jsonBody(
										$author$project$Model$Encoders$reportEncoder(model.ad.cd)),
									m: $elm$http$Http$expectWhatever(
										A2($elm$core$Basics$composeL, $author$project$Model$ReportMsg, $author$project$Model$DataModels$ReportReasonReceived)),
									y: _List_fromArray(
										[
											A2($elm$http$Http$header, 'authorization', 'bearer ' + model.l)
										]),
									A: 'POST',
									E: $elm$core$Maybe$Nothing,
									F: $elm$core$Maybe$Nothing,
									t: $author$project$Model$Endpoints$reportBot(botId)
								}));
					case 1:
						var bool = reportMessage.a;
						return _Utils_Tuple2(
							A2($author$project$Model$setReportSubmitInProgress, bool, model),
							$elm$core$Platform$Cmd$none);
					case 3:
						return _Utils_Tuple2(
							A2($author$project$Model$setReportSubmitInProgress, false, model),
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								model.x,
								A3($elm$core$String$replace, '/report/bot/', '/bot/', model.t.q)));
					default:
						return _Utils_Tuple2(
							$author$project$Model$resetReportForm(model),
							$elm$core$Platform$Cmd$none);
				}
			default:
				var deleteMessage = msg.a;
				if (!deleteMessage.$) {
					return _Utils_Tuple2(
						model,
						$elm$http$Http$riskyRequest(
							{
								d2: $elm$http$Http$emptyBody,
								m: $elm$http$Http$expectWhatever(
									A2($elm$core$Basics$composeL, $author$project$Model$DeleteBot, $author$project$Model$DataModels$DeleteBotResponse)),
								y: _List_fromArray(
									[
										A2($elm$http$Http$header, 'authorization', 'bearer ' + model.l)
									]),
								A: 'POST',
								E: $elm$core$Maybe$Nothing,
								F: $elm$core$Maybe$Nothing,
								t: $author$project$Model$Endpoints$deleteBot(
									A3($elm$core$String$replace, '/bot-settings/', '', model.t.q))
							}));
				} else {
					return _Utils_Tuple2(
						model,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Model$run(
									$author$project$Model$RetrieveUser(model.l)),
									A2($elm$browser$Browser$Navigation$pushUrl, model.x, '/account')
								])));
				}
		}
	});
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$footer = _VirtualDom_node('footer');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Components$Footer$footer = A2(
	$elm$html$Html$footer,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('footer')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('footerCompanyLink'),
					$elm$html$Html$Attributes$href('https://nilpointer.software')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Copyright 2020 NilPointer Software')
						]))
				])),
			A2(
			$elm$html$Html$span,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('All 3rd party trademarks and copyrights are property of their respective owners.')
				]))
		]));
var $author$project$Main$generateTitle = function (model) {
	if (A2($elm$core$String$startsWith, '/bot/', model.t.q)) {
		var _v0 = model.fa;
		if (!_v0.$) {
			var result = _v0.a;
			if (!result.$) {
				var bot = result.a;
				return bot.fr + ' - Bot Designer List';
			} else {
				return 'Bot Designer List';
			}
		} else {
			return 'Loading... - Bot Designer List';
		}
	} else {
		if (A2($elm$core$String$startsWith, '/dev/', model.t.q)) {
			var _v2 = model.fc;
			if (!_v2.$) {
				var result = _v2.a;
				if (!result.$) {
					var dev = result.a;
					var discordUser = dev.ep;
					return discordUser.fw + ('#' + (discordUser.fn + ('' + ' - Bot Designer List')));
				} else {
					return 'Bot Designer List';
				}
			} else {
				return 'Loading... - Bot Designer List';
			}
		} else {
			if (A2($elm$core$String$startsWith, '/account', model.t.q)) {
				var _v4 = model.fv;
				switch (_v4.$) {
					case 1:
						return 'Your account - Bot Designer List';
					case 0:
						return 'Bot Designer List';
					default:
						return 'Loading... - Bot Designer List';
				}
			} else {
				if (A2($elm$core$String$startsWith, '/search', model.t.q)) {
					return 'Search - Bot Designer List';
				} else {
					return 'Bot Designer List';
				}
			}
		}
	}
};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $elm$html$Html$img = _VirtualDom_node('img');
var $author$project$Model$Endpoints$login = $author$project$Model$Endpoints$debugRoot ? 'https://discordapp.com/api/oauth2/authorize?client_id=535183435539677195&redirect_uri=http%3A%2F%2Flocalhost%3A3000&response_type=code&scope=identify%20email' : 'https://discordapp.com/api/oauth2/authorize?client_id=705890174156144710&redirect_uri=https%3A%2F%2Fbotdesignerlist.com&response_type=code&scope=identify%20email';
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Components$NavBar$navBarRightSide = function (model) {
	var _v0 = model.fv;
	switch (_v0.$) {
		case 0:
			return A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href($author$project$Model$Endpoints$login),
						$elm$html$Html$Attributes$class('navBarCenter navBarDiscordLogin')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('navBarDiscordLoginFlow')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('navBarDiscordLoginIcon'),
										$elm$html$Html$Attributes$src('/assets/third-party/discord/logo-white.svg')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('navBarCenter')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Sign in')
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('navBarCenter navBarDiscordSignText ')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('using Discord')
									]))
							]))
					]));
		case 1:
			return A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('/account'),
						$elm$html$Html$Attributes$class('navBarCenter navBarHighlight')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('\uD83E\uDD16 Your account')
					]));
		default:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('navBarCenter navBarHighlight')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Please wait...')
					]));
	}
};
var $author$project$Model$Search = function (a) {
	return {$: 2, a: a};
};
var $author$project$Model$SubmitSearch = {$: 32};
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $author$project$Model$CancelSearching = {$: 3};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Components$NavBar$cancelSearchButton = function (model) {
	var _v0 = model.dC;
	if (!_v0.$) {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	} else {
		var string = _v0.a;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$Model$CancelSearching),
					$elm$html$Html$Attributes$class('navBarSearchCancelButton')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('❌')
				]));
	}
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $elm_community$html_extra$Html$Events$Extra$onEnter = function (onEnterAction) {
	return A2(
		$elm$html$Html$Events$on,
		'keyup',
		A2(
			$elm$json$Json$Decode$andThen,
			function (keyCode) {
				return (keyCode === 13) ? $elm$json$Json$Decode$succeed(onEnterAction) : $elm$json$Json$Decode$fail(
					$elm$core$String$fromInt(keyCode));
			},
			$elm$html$Html$Events$keyCode));
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Components$NavBar$searchAttribute = function (model) {
	var _v0 = model.dC;
	if (!_v0.$) {
		return _List_Nil;
	} else {
		var string = _v0.a;
		return _List_fromArray(
			[
				$elm$html$Html$Attributes$value(string)
			]);
	}
};
var $author$project$Components$NavBar$navBarSearch = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('navBarSearch')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('navBarSearchContent')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('navBarSearchContainer')
							]),
						_List_fromArray(
							[
								$author$project$Components$NavBar$cancelSearchButton(model),
								A2(
								$elm$html$Html$input,
								A2(
									$elm$core$List$append,
									_List_fromArray(
										[
											$elm$html$Html$Events$onInput($author$project$Model$Search),
											$elm$html$Html$Events$onSubmit($author$project$Model$SubmitSearch),
											$elm_community$html_extra$Html$Events$Extra$onEnter($author$project$Model$SubmitSearch),
											$elm$html$Html$Attributes$class('navBarInputInherit navBarSearchTitle'),
											$elm$html$Html$Attributes$placeholder('🔎 Type here to search')
										]),
									$author$project$Components$NavBar$searchAttribute(model)),
								_List_Nil),
								A2(
								$elm$html$Html$input,
								A2(
									$elm$core$List$append,
									_List_fromArray(
										[
											$elm$html$Html$Events$onInput($author$project$Model$Search),
											$elm$html$Html$Events$onSubmit($author$project$Model$SubmitSearch),
											$elm_community$html_extra$Html$Events$Extra$onEnter($author$project$Model$SubmitSearch),
											$elm$html$Html$Attributes$class('navBarInputInherit navBarSearchTitleEmojiOnly'),
											$elm$html$Html$Attributes$placeholder('🔎 Search')
										]),
									$author$project$Components$NavBar$searchAttribute(model)),
								_List_Nil)
							]))
					]))
			]));
};
var $author$project$Components$NavBar$navBar = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('navBarZIndex')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$nav,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('navBar')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('navBarContent')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('/'),
										$elm$html$Html$Attributes$class('navBarTitle navBarCenter')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('navBarTitleDesktop navBarCenter')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Bot Designer List')
											])),
										A2(
										$elm$html$Html$h1,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('navBarTitleMobile navBarCenter')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('BDL')
											]))
									])),
								$author$project$Components$NavBar$navBarSearch(model),
								$author$project$Components$NavBar$navBarRightSide(model)
							]))
					])),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('/')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('navBarTitleOverNavBarMobile')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Bot Designer List')
							]))
					]))
			]));
};
var $elm$html$Html$br = _VirtualDom_node('br');
var $author$project$Pages$Account$createNewBotButton = function (model) {
	return A2(
		$elm$html$Html$a,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$href('/account/create-bot-dialog')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('accountNewEntity')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('+ Add new bot')
					]))
			]));
};
var $author$project$Model$ConfirmAccountNewBotTypeSelection = {$: 7};
var $author$project$Model$DataModels$DBD_IMPORT = 0;
var $author$project$Model$NoOp = {$: 5};
var $author$project$Model$DataModels$OTHER = 1;
var $author$project$Model$SelectAccountNewBotType = function (a) {
	return {$: 6, a: a};
};
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$button = _VirtualDom_node('button');
var $author$project$Pages$Account$continueSelectionAttributes = function (model) {
	var _v0 = model.a1;
	if (!_v0.$) {
		return _List_fromArray(
			[
				$elm$html$Html$Attributes$class('accountDialogActionButton')
			]);
	} else {
		return _List_fromArray(
			[
				$elm$html$Html$Attributes$class('accountDialogActionButton accountDialogActionButtonDisabled')
			]);
	}
};
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Pages$Account$itemSelectionAttribute = F2(
	function (model, createBotType) {
		var _v0 = model.a1;
		if (!_v0.$) {
			var selectedBotType = _v0.a;
			return _Utils_eq(createBotType, selectedBotType) ? 'accountDialogChooseItemSelected' : '';
		} else {
			return '';
		}
	});
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $author$project$Model$onClickNoBubble = function (message) {
	return A2(
		$elm$html$Html$Events$custom,
		'click',
		$elm$json$Json$Decode$succeed(
			{eM: message, e2: true, fl: true}));
};
var $author$project$Pages$Account$createOrImportDbdBotDialog = function (model) {
	return A2($elm$core$String$startsWith, '/account/create-bot-dialog', model.t.q) ? A2(
		$elm$html$Html$a,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$href('/account')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('accountDialogDiv')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('accountDialogWindow'),
								$author$project$Model$onClickNoBubble($author$project$Model$NoOp)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h2,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('accountDialogTitle')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Choose bot type')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('accountDialogContent')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class(
												'accountDialogChooseItem ' + A2($author$project$Pages$Account$itemSelectionAttribute, model, 0)),
												$elm$html$Html$Events$onClick(
												$author$project$Model$SelectAccountNewBotType(0))
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$img,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('accountDialogItemImage '),
														$elm$html$Html$Attributes$src('/assets/material/bdfd.svg'),
														$elm$html$Html$Attributes$alt('Bot Designer For Discord Logo')
													]),
												_List_Nil),
												$elm$html$Html$text('Bot Designer')
											])),
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class(
												'accountDialogChooseItem ' + A2($author$project$Pages$Account$itemSelectionAttribute, model, 1)),
												$elm$html$Html$Events$onClick(
												$author$project$Model$SelectAccountNewBotType(1))
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$img,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('accountDialogItemImage'),
														$elm$html$Html$Attributes$src('/assets/material/code.svg'),
														$elm$html$Html$Attributes$alt('Bot Designer For Discord Logo')
													]),
												_List_Nil),
												$elm$html$Html$text('Other language')
											]))
									])),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('accountDialogActionContainer'),
										$elm$html$Html$Events$onClick($author$project$Model$ConfirmAccountNewBotTypeSelection)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h3,
										$author$project$Pages$Account$continueSelectionAttributes(model),
										_List_fromArray(
											[
												$elm$html$Html$text('Continue')
											]))
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('accountDialogTosAccept')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('By clicking continue, you are accepting to our '),
										A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$href('/tos'),
												$elm$html$Html$Attributes$class('accountDialogTosAcceptUnderline')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('terms of service')
											]))
									]))
							]))
					]))
			])) : A2($elm$html$Html$div, _List_Nil, _List_Nil);
};
var $author$project$Model$Logout = {$: 58};
var $author$project$Components$Dialog$dialog = function (child) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal is-active')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('modal-background')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('modal-card')
					]),
				child)
			]));
};
var $elm$html$Html$section = _VirtualDom_node('section');
var $author$project$Components$Dialog$dialogContent = function (body) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal-card-body')
			]),
		body);
};
var $author$project$Components$Dialog$dialogFooter = function (body) {
	return A2(
		$elm$html$Html$footer,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal-card-foot')
			]),
		body);
};
var $elm$html$Html$header = _VirtualDom_node('header');
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$Components$Dialog$dialogTitle = function (title) {
	return A2(
		$elm$html$Html$header,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal-card-head')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('modal-card-title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(title)
					]))
			]));
};
var $elm$core$String$endsWith = _String_endsWith;
var $author$project$Model$hasLogoutDialogSuffix = function (fragment) {
	return A2($elm$core$String$endsWith, 'logout-dialog', fragment);
};
var $author$project$Pages$BotSettings$spacer = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('botSettingsSpacer')
		]),
	_List_Nil);
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Pages$Account$logoutDialog = function (model) {
	var _v0 = $author$project$Model$hasLogoutDialogSuffix(
		A2($elm$core$Maybe$withDefault, '', model.t.aK));
	if (_v0) {
		return $author$project$Components$Dialog$dialog(
			_List_fromArray(
				[
					$author$project$Components$Dialog$dialogTitle('Confirm logout'),
					$author$project$Components$Dialog$dialogContent(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Are you sure that you want to log out?')
								]))
						])),
					$author$project$Components$Dialog$dialogFooter(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('accountNewEntity'),
									$elm$html$Html$Attributes$href('')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Cancel')
								])),
							$author$project$Pages$BotSettings$spacer,
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Model$Logout),
									$elm$html$Html$Attributes$class('accountNewEntity botSettingsBurningEyesRed')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Yes, log me out')
								]))
						]))
				]));
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $author$project$Pages$BotPreview$botRatingText = function (rating) {
	if (!rating.$) {
		var a = rating.a;
		return a;
	} else {
		return '???';
	}
};
var $author$project$Model$discordUserFromBotOwner = function (botOwner) {
	return {bE: botOwner.bE, fn: botOwner.fn, fw: botOwner.fw};
};
var $author$project$Model$discordUserString = function (author) {
	return author.fw + ('#' + author.fn);
};
var $author$project$Model$discordUserStringBotOwner = function (botOwner) {
	return $author$project$Model$discordUserString(
		$author$project$Model$discordUserFromBotOwner(botOwner));
};
var $author$project$Components$BotComponent$onlineStatus = function (status) {
	if (!status.$) {
		var knownStatus = status.a;
		if (knownStatus) {
			return '\uD83D\uDFE2 Online';
		} else {
			return '🔴 Offline';
		}
	} else {
		return '❔ Unknown';
	}
};
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $author$project$Components$BotComponent$countTrim = function (a) {
	var countStr = $elm$core$String$fromInt(a);
	var countLen = $elm$core$String$length(countStr);
	var countDiff = countLen - 7;
	return (countDiff > 0) ? A2($elm$core$String$dropRight, countDiff, countStr) : countStr;
};
var $author$project$Components$BotComponent$serverCounter = function (count) {
	if (!count.$) {
		var a = count.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('botFooterText')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					'⚡ ' + ($author$project$Components$BotComponent$countTrim(a) + ' servers'))
				]));
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Components$BotComponent$botComponent = function (botData) {
	return A2(
		$elm$html$Html$a,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('botComponent'),
				$elm$html$Html$Attributes$href(
				'/bot/' + $author$project$Model$tmpBotId(botData))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('botComponentHeader')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('botComponentProfileImg tileLoading')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('botComponentProfileImg'),
										$elm$html$Html$Attributes$src(
										$author$project$Model$profileImageUrl(botData.e4)),
										A2($elm$html$Html$Attributes$attribute, 'loading', 'lazy')
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('botHeaderNames')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('botName')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(botData.fr)
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('botAuthor')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Model$discordUserStringBotOwner(botData.dY))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('botHeaderRating')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('botRating')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										'⭐ ' + $author$project$Pages$BotPreview$botRatingText(botData.dZ))
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('botComponentContent')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('botDescription')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(botData.fg)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('botFooter')
					]),
				_List_fromArray(
					[
						$author$project$Components$BotComponent$serverCounter(botData.fe),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('botFooterText')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Components$BotComponent$onlineStatus(botData.eV))
									]))
							]))
					]))
			]));
};
var $author$project$Components$ExtendedBotComponent$botVerifiedBar = function (extraBotData) {
	var _v0 = extraBotData.fy;
	switch (_v0.$) {
		case 0:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(' extendedBotComponentBaseVerifiedText extendedBotComponentYellowVerifiedText')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Your bot is awaiting verification')
					]));
		case 1:
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		default:
			var reason = _v0.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(' extendedBotComponentBaseVerifiedText extendedBotComponentDeclinedText')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Your bot has been declined. Reason: ' + reason)
					]));
	}
};
var $author$project$Components$ExtendedBotComponent$botViewsText = function (extraBotData) {
	var _v0 = extraBotData.fi;
	if (!_v0.$) {
		var stats = _v0.a;
		return $elm$core$String$fromInt(stats.fA) + ' views';
	} else {
		return '??? views';
	}
};
var $author$project$Components$ExtendedBotComponent$extendedBotComponent = function (bot) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('extendedBotComponentBotComponent')
			]),
		_List_fromArray(
			[
				$author$project$Components$BotComponent$botComponent(bot.bG),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('extendedBotComponentActionBar')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Components$ExtendedBotComponent$botViewsText(bot))
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href(
										'/edit-bot/' + $author$project$Model$tmpBotId(bot.bG))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Edit bot')
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href(
										'/bot-settings/' + $author$project$Model$tmpBotId(bot.bG))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Settings')
									]))
							]))
					])),
				$author$project$Components$ExtendedBotComponent$botVerifiedBar(bot)
			]));
};
var $author$project$Pages$Account$ownedBotList = function (user) {
	return ($elm$core$List$length(user.br) > 0) ? A2(
		$elm$html$Html$div,
		_List_Nil,
		A2($elm$core$List$map, $author$project$Components$ExtendedBotComponent$extendedBotComponent, user.br)) : A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('It looks like it\'s empty here... let\'s change this!')
			]));
};
var $author$project$Pages$Account$account = function (_v0) {
	var model = _v0.a;
	var user = _v0.b;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('content pushContentToLeft')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('accountTitleHeader')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Your account: '),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('accountTitleHeaderUsername')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(user.ep.fw)
							])),
						$elm$html$Html$text('#'),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('accountTitleHeaderTag')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(user.ep.fn)
							]))
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('accountIdSmallSubtitle')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Discord ID: ' + user.eo),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$elm$html$Html$text('BDL ID: ' + user.b0)
					])),
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Your bots:')
					])),
				$author$project$Pages$Account$ownedBotList(user),
				$author$project$Pages$Account$createNewBotButton(model),
				A2(
				$elm$html$Html$h2,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('marginBlockEndLess')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Other:')
					])),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('accountNewEntity'),
						$elm$html$Html$Attributes$href('#logout-dialog')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Logout')
					])),
				$author$project$Pages$Account$createOrImportDbdBotDialog(model),
				$author$project$Pages$Account$logoutDialog(model)
			]));
};
var $author$project$Components$LoadingIndicator$loadingIndicator = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('loaderContainer')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('loader')
				]),
			_List_Nil)
		]));
var $author$project$Pages$SignInNeeded$checkingSignIn = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Please wait: Checking if you are signed in')
					])),
				$author$project$Components$LoadingIndicator$loadingIndicator
			]));
};
var $author$project$Pages$SignInNeeded$signInNeeded = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('HTTP 403: You don\'t have access to this page')
					])),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('accountSingInLink'),
						$elm$html$Html$Attributes$href('/')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Please sign-in to continue')
					]))
			]));
};
var $author$project$Pages$SignInNeeded$accountGuard = F2(
	function (model, content) {
		var _v0 = model.fv;
		switch (_v0.$) {
			case 0:
				return $author$project$Pages$SignInNeeded$signInNeeded(model);
			case 1:
				var user = _v0.a;
				return content(
					_Utils_Tuple2(model, user));
			default:
				return $author$project$Pages$SignInNeeded$checkingSignIn(model);
		}
	});
var $author$project$Model$DataModels$DOT_NET_CORE = 1;
var $author$project$Model$DataModels$GO = 3;
var $author$project$Model$DataModels$PYTHON = 2;
var $elm$html$Html$code = _VirtualDom_node('code');
var $author$project$Components$ContentLeft$contentLeft = F2(
	function (attr, child) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('content pushContentToLeft')
				]),
			child);
	});
var $author$project$Pages$BotApiDocumentation$documentationText = function (apiDocumentationLanguage) {
	if (!apiDocumentationLanguage) {
		return 'await fetch("https://botdesignerlist.com/api/exposed/bot/update", {\n                   method: \'POST\',\n                   headers: {\n                     \'authorization\': \'bearer YOUR_BOT_API_TOKEN\',\n                     \'content-type\': \'application/json\',\n                   },\n                   body: JSON.stringify({"servers":123}),\n                 });';
	} else {
		return 'Unimplemented yet';
	}
};
var $author$project$Model$SetApiDocLanguage = function (a) {
	return {$: 59, a: a};
};
var $author$project$Model$DataModels$apiDocumentationLanguageName = function (apiDocumentationLanguage) {
	switch (apiDocumentationLanguage) {
		case 0:
			return 'Javascript';
		case 1:
			return 'C# .NET Core';
		case 2:
			return 'Python';
		default:
			return 'Go';
	}
};
var $author$project$Pages$BotApiDocumentation$languageButtonSelectedClass = F2(
	function (apiDocumentationLanguage, model) {
		var _v0 = _Utils_eq(apiDocumentationLanguage, model.bR);
		if (_v0) {
			return ' stdButtonForcePressed';
		} else {
			return '';
		}
	});
var $author$project$Pages$BotApiDocumentation$languageButton = F2(
	function (language, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('botSettingsTokenBox')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							'stdButton' + A2($author$project$Pages$BotApiDocumentation$languageButtonSelectedClass, language, model)),
							$elm$html$Html$Events$onClick(
							$author$project$Model$SetApiDocLanguage(language))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Model$DataModels$apiDocumentationLanguageName(language))
						])),
					$author$project$Pages$BotSettings$spacer
				]));
	});
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$problemToString = function (problem) {
	switch (problem.$) {
		case 0:
			var string = problem.a;
			return 'Expecting ' + string;
		case 1:
			return 'Expecting int';
		case 2:
			return 'Expecting hex';
		case 3:
			return 'Expecting octal';
		case 4:
			return 'Expecting binary';
		case 5:
			return 'Expecting float';
		case 6:
			return 'Expecting number';
		case 7:
			return 'Expecting variable';
		case 8:
			var string = problem.a;
			return 'Expecting symbol ' + string;
		case 9:
			var string = problem.a;
			return 'Expecting keyword ' + string;
		case 10:
			return 'Expecting keyword end';
		case 11:
			return 'Unexpected char';
		case 12:
			var problemDescription = problem.a;
			return problemDescription;
		default:
			return 'Bad repeat';
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString = function (deadEnd) {
	return 'Problem at row ' + ($elm$core$String$fromInt(deadEnd.at) + ('\n' + $dillonkearns$elm_markdown$Markdown$Parser$problemToString(deadEnd.e3)));
};
var $elm$html$Html$Attributes$align = $elm$html$Html$Attributes$stringProperty('align');
var $elm$html$Html$blockquote = _VirtualDom_node('blockquote');
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$em = _VirtualDom_node('em');
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $elm$html$Html$h6 = _VirtualDom_node('h6');
var $elm$html$Html$hr = _VirtualDom_node('hr');
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$html$Html$ol = _VirtualDom_node('ol');
var $dillonkearns$elm_markdown$Markdown$HtmlRenderer$HtmlRenderer = $elm$core$Basics$identity;
var $dillonkearns$elm_markdown$Markdown$Html$resultOr = F2(
	function (ra, rb) {
		if (ra.$ === 1) {
			var singleError = ra.a;
			if (!rb.$) {
				var okValue = rb.a;
				return $elm$core$Result$Ok(okValue);
			} else {
				var errorsSoFar = rb.a;
				return $elm$core$Result$Err(
					A2($elm$core$List$cons, singleError, errorsSoFar));
			}
		} else {
			var okValue = ra.a;
			return $elm$core$Result$Ok(okValue);
		}
	});
var $dillonkearns$elm_markdown$Markdown$Html$attributesToString = function (attributes) {
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$map,
			function (_v0) {
				var name = _v0.b5;
				var value = _v0.bA;
				return name + ('=\"' + (value + '\"'));
			},
			attributes));
};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $dillonkearns$elm_markdown$Markdown$Html$tagToString = F2(
	function (tagName, attributes) {
		return $elm$core$List$isEmpty(attributes) ? ('<' + (tagName + '>')) : ('<' + (tagName + (' ' + ($dillonkearns$elm_markdown$Markdown$Html$attributesToString(attributes) + '>'))));
	});
var $dillonkearns$elm_markdown$Markdown$Html$oneOf = function (decoders) {
	var unwrappedDecoders = A2(
		$elm$core$List$map,
		function (_v1) {
			var rawDecoder = _v1;
			return rawDecoder;
		},
		decoders);
	return function (rawDecoder) {
		return F3(
			function (tagName, attributes, innerBlocks) {
				return A2(
					$elm$core$Result$mapError,
					function (errors) {
						if (!errors.b) {
							return 'Ran into a oneOf with no possibilities!';
						} else {
							if (!errors.b.b) {
								var singleError = errors.a;
								return 'Problem with the given value:\n\n' + (A2($dillonkearns$elm_markdown$Markdown$Html$tagToString, tagName, attributes) + ('\n\n' + (singleError + '\n')));
							} else {
								return 'oneOf failed parsing this value:\n    ' + (A2($dillonkearns$elm_markdown$Markdown$Html$tagToString, tagName, attributes) + ('\n\nParsing failed in the following 2 ways:\n\n\n' + (A2(
									$elm$core$String$join,
									'\n\n',
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (index, error) {
												return '(' + ($elm$core$String$fromInt(index + 1) + (') ' + error));
											}),
										errors)) + '\n')));
							}
						}
					},
					A3(rawDecoder, tagName, attributes, innerBlocks));
			});
	}(
		A3(
			$elm$core$List$foldl,
			F2(
				function (decoder, soFar) {
					return F3(
						function (tagName, attributes, children) {
							return A2(
								$dillonkearns$elm_markdown$Markdown$Html$resultOr,
								A3(decoder, tagName, attributes, children),
								A3(soFar, tagName, attributes, children));
						});
				}),
			F3(
				function (tagName, attributes, children) {
					return $elm$core$Result$Err(_List_Nil);
				}),
			unwrappedDecoders));
};
var $elm$html$Html$pre = _VirtualDom_node('pre');
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $elm$html$Html$Attributes$start = function (n) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'start',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $dillonkearns$elm_markdown$Markdown$Renderer$defaultHtmlRenderer = {
	bI: $elm$html$Html$blockquote(_List_Nil),
	bN: function (_v0) {
		var body = _v0.d2;
		var language = _v0.c7;
		return A2(
			$elm$html$Html$pre,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$code,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(body)
						]))
				]));
	},
	bO: function (content) {
		return A2(
			$elm$html$Html$code,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(content)
				]));
	},
	bT: function (children) {
		return A2($elm$html$Html$em, _List_Nil, children);
	},
	bX: A2($elm$html$Html$br, _List_Nil, _List_Nil),
	bZ: function (_v1) {
		var level = _v1.c8;
		var children = _v1.cB;
		switch (level) {
			case 0:
				return A2($elm$html$Html$h1, _List_Nil, children);
			case 1:
				return A2($elm$html$Html$h2, _List_Nil, children);
			case 2:
				return A2($elm$html$Html$h3, _List_Nil, children);
			case 3:
				return A2($elm$html$Html$h4, _List_Nil, children);
			case 4:
				return A2($elm$html$Html$h5, _List_Nil, children);
			default:
				return A2($elm$html$Html$h6, _List_Nil, children);
		}
	},
	b$: $dillonkearns$elm_markdown$Markdown$Html$oneOf(_List_Nil),
	c_: function (imageInfo) {
		var _v3 = imageInfo.fr;
		if (!_v3.$) {
			var title = _v3.a;
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(imageInfo.cg),
						$elm$html$Html$Attributes$alt(imageInfo.bD),
						$elm$html$Html$Attributes$title(title)
					]),
				_List_Nil);
		} else {
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(imageInfo.cg),
						$elm$html$Html$Attributes$alt(imageInfo.bD)
					]),
				_List_Nil);
		}
	},
	b1: F2(
		function (link, content) {
			var _v4 = link.fr;
			if (!_v4.$) {
				var title = _v4.a;
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href(link.em),
							$elm$html$Html$Attributes$title(title)
						]),
					content);
			} else {
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href(link.em)
						]),
					content);
			}
		}),
	b8: F2(
		function (startingIndex, items) {
			return A2(
				$elm$html$Html$ol,
				function () {
					if (startingIndex === 1) {
						return _List_fromArray(
							[
								$elm$html$Html$Attributes$start(startingIndex)
							]);
					} else {
						return _List_Nil;
					}
				}(),
				A2(
					$elm$core$List$map,
					function (itemBlocks) {
						return A2($elm$html$Html$li, _List_Nil, itemBlocks);
					},
					items));
		}),
	b9: $elm$html$Html$p(_List_Nil),
	ch: function (children) {
		return A2($elm$html$Html$strong, _List_Nil, children);
	},
	ck: $elm$html$Html$table(_List_Nil),
	dH: $elm$html$Html$tbody(_List_Nil),
	dI: $elm$html$Html$td(_List_Nil),
	cl: $elm$html$Html$thead(_List_Nil),
	cm: function (maybeAlignment) {
		var attrs = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$singleton,
				A2(
					$elm$core$Maybe$map,
					$elm$html$Html$Attributes$align,
					A2(
						$elm$core$Maybe$map,
						function (alignment) {
							switch (alignment) {
								case 0:
									return 'left';
								case 2:
									return 'center';
								default:
									return 'right';
							}
						},
						maybeAlignment))));
		return $elm$html$Html$th(attrs);
	},
	dJ: $elm$html$Html$tr(_List_Nil),
	D: $elm$html$Html$text,
	co: A2($elm$html$Html$hr, _List_Nil, _List_Nil),
	cq: function (items) {
		return A2(
			$elm$html$Html$ul,
			_List_Nil,
			A2(
				$elm$core$List$map,
				function (item) {
					var task = item.a;
					var children = item.b;
					var checkbox = function () {
						switch (task) {
							case 0:
								return $elm$html$Html$text('');
							case 1:
								return A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$disabled(true),
											$elm$html$Html$Attributes$checked(false),
											$elm$html$Html$Attributes$type_('checkbox')
										]),
									_List_Nil);
							default:
								return A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$disabled(true),
											$elm$html$Html$Attributes$checked(true),
											$elm$html$Html$Attributes$type_('checkbox')
										]),
									_List_Nil);
						}
					}();
					return A2(
						$elm$html$Html$li,
						_List_Nil,
						A2($elm$core$List$cons, checkbox, children));
				},
				items));
	}
};
var $dillonkearns$elm_markdown$Markdown$Block$BlockQuote = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_markdown$Markdown$RawBlock$BlockQuote = function (a) {
	return {$: 10, a: a};
};
var $dillonkearns$elm_markdown$Markdown$RawBlock$Body = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Cdata = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$CodeBlock = function (a) {
	return {$: 7, a: a};
};
var $dillonkearns$elm_markdown$Markdown$RawBlock$CodeBlock = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$CodeSpan = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$CompletedTask = 2;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Emphasis = function (a) {
	return {$: 3, a: a};
};
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$HardLineBreak = {$: 7};
var $dillonkearns$elm_markdown$Markdown$Block$Heading = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$RawBlock$Html = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$HtmlBlock = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$HtmlComment = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$HtmlDeclaration = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Block$HtmlElement = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Block$HtmlInline = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Image = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Block$IncompleteTask = 1;
var $dillonkearns$elm_markdown$Markdown$RawBlock$IndentedCodeBlock = function (a) {
	return {$: 6, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Link = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Block$ListItem = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$NoTask = 0;
var $dillonkearns$elm_markdown$Markdown$Block$OrderedList = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Block$Paragraph = function (a) {
	return {$: 5, a: a};
};
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$ProcessingInstruction = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Strong = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Table = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Block$Text = function (a) {
	return {$: 6, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$ThematicBreak = {$: 8};
var $dillonkearns$elm_markdown$Markdown$RawBlock$ThematicBreak = {$: 7};
var $dillonkearns$elm_markdown$Markdown$Block$UnorderedList = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$RawBlock$UnparsedInlines = $elm$core$Basics$identity;
var $dillonkearns$elm_markdown$Markdown$Parser$addReference = F2(
	function (state, linkRef) {
		return _Utils_update(
			state,
			{
				aN: A2($elm$core$List$cons, linkRef, state.aN)
			});
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine = {$: 9};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.cg);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{cC: col, f: s0.f, h: s0.h, b: offset, at: row, cg: s0.cg});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.at, s.cC, s);
	};
};
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $dillonkearns$elm_markdown$Helpers$isSpaceOrTab = function (c) {
	switch (c) {
		case ' ':
			return true;
		case '\t':
			return true;
		default:
			return false;
	}
};
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {cC: col, ed: contextStack, e3: problem, at: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.at, s.cC, x, s.f));
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.at, s.cC, s.cg);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{cC: newCol, f: s.f, h: s.h, b: newOffset, at: newRow, cg: s.cg});
	};
};
var $dillonkearns$elm_markdown$Markdown$Parser$blankLine = A2(
	$elm$parser$Parser$Advanced$map,
	function (_v0) {
		return $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine;
	},
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$backtrackable(
			$elm$parser$Parser$Advanced$chompWhile(
				function (c) {
					return $dillonkearns$elm_markdown$Helpers$isSpaceOrTab(c);
				})),
		$elm$parser$Parser$Advanced$token(
			A2(
				$elm$parser$Parser$Advanced$Token,
				'\n',
				$elm$parser$Parser$Expecting('\\n')))));
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.cg);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{cC: 1, f: s.f, h: s.h, b: s.b + 1, at: s.at + 1, cg: s.cg}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{cC: s.cC + 1, f: s.f, h: s.h, b: newOffset, at: s.at, cg: s.cg}));
		};
	});
var $elm$parser$Parser$Advanced$chompUntilEndOr = function (str) {
	return function (s) {
		var _v0 = A5(_Parser_findSubString, str, s.b, s.at, s.cC, s.cg);
		var newOffset = _v0.a;
		var newRow = _v0.b;
		var newCol = _v0.c;
		var adjustedOffset = (newOffset < 0) ? $elm$core$String$length(s.cg) : newOffset;
		return A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.b, adjustedOffset) < 0,
			0,
			{cC: newCol, f: s.f, h: s.h, b: adjustedOffset, at: newRow, cg: s.cg});
	};
};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.cg),
			s.b) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b, s1.b, s0.cg),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $dillonkearns$elm_markdown$Helpers$isNewline = function (character) {
	if ('\n' === character) {
		return true;
	} else {
		return false;
	}
};
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $dillonkearns$elm_markdown$Markdown$Parser$blockQuote = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$RawBlock$BlockQuote),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'   > ',
						$elm$parser$Parser$Expecting('   > '))),
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'  > ',
						$elm$parser$Parser$Expecting('  > '))),
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						' > ',
						$elm$parser$Parser$Expecting(' > '))),
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'> ',
						$elm$parser$Parser$Expecting('> '))),
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'   >',
						$elm$parser$Parser$Expecting('   >'))),
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'  >',
						$elm$parser$Parser$Expecting('  >'))),
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						' >',
						$elm$parser$Parser$Expecting(' >'))),
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'>',
						$elm$parser$Parser$Expecting('>')))
				]))),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$Advanced$end(
					$elm$parser$Parser$Problem('Expecting end')),
					A2(
					$elm$parser$Parser$Advanced$chompIf,
					$dillonkearns$elm_markdown$Helpers$isNewline,
					$elm$parser$Parser$Problem('Expecting newline'))
				]))));
var $dillonkearns$elm_markdown$Markdown$Parser$combine = function (list) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (parser, listParser) {
				return A2(
					$elm$parser$Parser$Advanced$andThen,
					function (soFar) {
						return A2(
							$elm$parser$Parser$Advanced$map,
							function (a) {
								return A2($elm$core$List$cons, a, soFar);
							},
							parser);
					},
					listParser);
			}),
		$elm$parser$Parser$Advanced$succeed(_List_Nil),
		list);
};
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString = function (deadEnds) {
	return A2(
		$elm$core$String$join,
		'\n',
		A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, deadEnds));
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $dillonkearns$elm_markdown$Markdown$RawBlock$Heading = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$String$trimRight = _String_trimRight;
var $dillonkearns$elm_markdown$Markdown$Parser$dropTrailingHashes = function (headingString) {
	return A2($elm$core$String$endsWith, '#', headingString) ? $dillonkearns$elm_markdown$Markdown$Parser$dropTrailingHashes(
		$elm$core$String$trimRight(
			A2($elm$core$String$dropRight, 1, headingString))) : headingString;
};
var $elm$core$Basics$ge = _Utils_ge;
var $dillonkearns$elm_markdown$Helpers$isSpacebar = function (c) {
	if (' ' === c) {
		return true;
	} else {
		return false;
	}
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $dillonkearns$elm_markdown$Markdown$Parser$heading = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$RawBlock$Heading),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'#',
					$elm$parser$Parser$Expecting('#')))),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$andThen,
				function (additionalHashes) {
					var level = $elm$core$String$length(additionalHashes) + 1;
					return (level >= 7) ? $elm$parser$Parser$Advanced$problem(
						$elm$parser$Parser$Expecting('heading with < 7 #\'s')) : $elm$parser$Parser$Advanced$succeed(level);
				},
				$elm$parser$Parser$Advanced$getChompedString(
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed(0),
						$elm$parser$Parser$Advanced$chompWhile(
							function (c) {
								if ('#' === c) {
									return true;
								} else {
									return false;
								}
							})))),
			$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Helpers$isSpacebar))),
	A2(
		$elm$parser$Parser$Advanced$andThen,
		function (headingText) {
			return $elm$parser$Parser$Advanced$succeed(
				$dillonkearns$elm_markdown$Markdown$Parser$dropTrailingHashes(headingText));
		},
		$elm$parser$Parser$Advanced$getChompedString(
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed(0),
				$elm$parser$Parser$Advanced$chompUntilEndOr('\n')))));
var $dillonkearns$elm_markdown$HtmlParser$Cdata = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_markdown$HtmlParser$Element = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$HtmlParser$Text = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$HtmlParser$Attribute = F2(
	function (name, value) {
		return {b5: name, bA: value};
	});
var $elm$parser$Parser$Advanced$Located = F3(
	function (row, col, context) {
		return {cC: col, f: context, at: row};
	});
var $elm$parser$Parser$Advanced$changeContext = F2(
	function (newContext, s) {
		return {cC: s.cC, f: newContext, h: s.h, b: s.b, at: s.at, cg: s.cg};
	});
var $elm$parser$Parser$Advanced$inContext = F2(
	function (context, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(
				A2(
					$elm$parser$Parser$Advanced$changeContext,
					A2(
						$elm$core$List$cons,
						A3($elm$parser$Parser$Advanced$Located, s0.at, s0.cC, context),
						s0.f),
					s0));
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					a,
					A2($elm$parser$Parser$Advanced$changeContext, s0.f, s1));
			} else {
				var step = _v1;
				return step;
			}
		};
	});
var $dillonkearns$elm_markdown$HtmlParser$isUninteresting = function (c) {
	switch (c) {
		case '/':
			return false;
		case '<':
			return false;
		case '>':
			return false;
		case '\"':
			return false;
		case '\'':
			return false;
		case '=':
			return false;
		default:
			return true;
	}
};
var $dillonkearns$elm_markdown$HtmlParser$isWhitespace = function (c) {
	switch (c) {
		case ' ':
			return true;
		case '\u000D':
			return true;
		case '\n':
			return true;
		case '\t':
			return true;
		default:
			return false;
	}
};
var $elm$parser$Parser$BadRepeat = {$: 13};
var $dillonkearns$elm_markdown$HtmlParser$keep = F2(
	function (count, predicate) {
		var n = count;
		return A2(
			$elm$parser$Parser$Advanced$andThen,
			function (str) {
				return (_Utils_cmp(
					n,
					$elm$core$String$length(str)) < 1) ? $elm$parser$Parser$Advanced$succeed(str) : $elm$parser$Parser$Advanced$problem($elm$parser$Parser$BadRepeat);
			},
			$elm$parser$Parser$Advanced$getChompedString(
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed(0),
					$elm$parser$Parser$Advanced$chompWhile(predicate))));
	});
var $dillonkearns$elm_markdown$HtmlParser$AtLeast = $elm$core$Basics$identity;
var $dillonkearns$elm_markdown$HtmlParser$oneOrMore = 1;
var $dillonkearns$elm_markdown$HtmlParser$attributeName = A2(
	$elm$parser$Parser$Advanced$inContext,
	'attributeName',
	A2(
		$dillonkearns$elm_markdown$HtmlParser$keep,
		$dillonkearns$elm_markdown$HtmlParser$oneOrMore,
		function (c) {
			return (!$dillonkearns$elm_markdown$HtmlParser$isWhitespace(c)) && $dillonkearns$elm_markdown$HtmlParser$isUninteresting(c);
		}));
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $dillonkearns$elm_markdown$HtmlParser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $elm$core$String$cons = _String_cons;
var $dillonkearns$elm_markdown$HtmlParser$entities = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('quot', '\"')
		]));
var $elm$core$Char$fromCode = _Char_fromCode;
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Basics$pow = _Basics_pow;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $dillonkearns$elm_markdown$HtmlParser$decodeEscape = function (s) {
	return A2($elm$core$String$startsWith, '#x', s) ? A2(
		$elm$core$Result$mapError,
		$elm$parser$Parser$Problem,
		A2(
			$elm$core$Result$map,
			$elm$core$Char$fromCode,
			$rtfeldman$elm_hex$Hex$fromString(
				A2($elm$core$String$dropLeft, 2, s)))) : (A2($elm$core$String$startsWith, '#', s) ? A2(
		$elm$core$Result$fromMaybe,
		$elm$parser$Parser$Problem('Invalid escaped character: ' + s),
		A2(
			$elm$core$Maybe$map,
			$elm$core$Char$fromCode,
			$elm$core$String$toInt(
				A2($elm$core$String$dropLeft, 1, s)))) : A2(
		$elm$core$Result$fromMaybe,
		$elm$parser$Parser$Problem('No entity named \"&' + (s + ';\" found.')),
		A2($elm$core$Dict$get, s, $dillonkearns$elm_markdown$HtmlParser$entities)));
};
var $dillonkearns$elm_markdown$HtmlParser$fail = function (str) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(str));
};
var $dillonkearns$elm_markdown$HtmlParser$notSemiColon = function (c) {
	if (';' === c) {
		return false;
	} else {
		return true;
	}
};
var $dillonkearns$elm_markdown$HtmlParser$escapedChar = function (end_) {
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'escapedChar',
		A2(
			$elm$parser$Parser$Advanced$andThen,
			function (s) {
				return $elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$Advanced$andThen,
							function (_v0) {
								var _v1 = $dillonkearns$elm_markdown$HtmlParser$decodeEscape(s);
								if (!_v1.$) {
									var c = _v1.a;
									return $elm$parser$Parser$Advanced$succeed(c);
								} else {
									var e = _v1.a;
									return $elm$parser$Parser$Advanced$problem(e);
								}
							},
							$dillonkearns$elm_markdown$HtmlParser$symbol(';')),
							$dillonkearns$elm_markdown$HtmlParser$fail('Entities must end_ with \";\": &' + s)
						]));
			},
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
					$dillonkearns$elm_markdown$HtmlParser$symbol('&')),
				A2(
					$dillonkearns$elm_markdown$HtmlParser$keep,
					$dillonkearns$elm_markdown$HtmlParser$oneOrMore,
					function (c) {
						return (!_Utils_eq(c, end_)) && $dillonkearns$elm_markdown$HtmlParser$notSemiColon(c);
					}))));
};
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $dillonkearns$elm_markdown$HtmlParser$notAmpersand = function (c) {
	if ('&' === c) {
		return false;
	} else {
		return true;
	}
};
var $dillonkearns$elm_markdown$HtmlParser$zeroOrMore = 0;
var $dillonkearns$elm_markdown$HtmlParser$textString = function (end_) {
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'textString',
		A2(
			$elm$parser$Parser$Advanced$andThen,
			function (s) {
				return $elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$Advanced$keeper,
							A2(
								$elm$parser$Parser$Advanced$keeper,
								$elm$parser$Parser$Advanced$succeed(
									F2(
										function (c, t) {
											return _Utils_ap(
												s,
												A2($elm$core$String$cons, c, t));
										})),
								$dillonkearns$elm_markdown$HtmlParser$escapedChar(end_)),
							$elm$parser$Parser$Advanced$lazy(
								function (_v0) {
									return $dillonkearns$elm_markdown$HtmlParser$textString(end_);
								})),
							$elm$parser$Parser$Advanced$succeed(s)
						]));
			},
			A2(
				$dillonkearns$elm_markdown$HtmlParser$keep,
				$dillonkearns$elm_markdown$HtmlParser$zeroOrMore,
				function (c) {
					return (!_Utils_eq(c, end_)) && $dillonkearns$elm_markdown$HtmlParser$notAmpersand(c);
				})));
};
var $dillonkearns$elm_markdown$HtmlParser$attributeValue = A2(
	$elm$parser$Parser$Advanced$inContext,
	'attributeValue',
	$elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
					$dillonkearns$elm_markdown$HtmlParser$symbol('\"')),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$dillonkearns$elm_markdown$HtmlParser$textString('\"'),
					$dillonkearns$elm_markdown$HtmlParser$symbol('\"'))),
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
					$dillonkearns$elm_markdown$HtmlParser$symbol('\'')),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$dillonkearns$elm_markdown$HtmlParser$textString('\''),
					$dillonkearns$elm_markdown$HtmlParser$symbol('\'')))
			])));
var $dillonkearns$elm_markdown$HtmlParser$ignore = F2(
	function (count, predicate) {
		return A2(
			$elm$parser$Parser$Advanced$map,
			function (_v0) {
				return 0;
			},
			A2($dillonkearns$elm_markdown$HtmlParser$keep, count, predicate));
	});
var $dillonkearns$elm_markdown$HtmlParser$whiteSpace = A2($dillonkearns$elm_markdown$HtmlParser$ignore, $dillonkearns$elm_markdown$HtmlParser$zeroOrMore, $dillonkearns$elm_markdown$HtmlParser$isWhitespace);
var $dillonkearns$elm_markdown$HtmlParser$attribute = A2(
	$elm$parser$Parser$Advanced$inContext,
	'attribute',
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$HtmlParser$Attribute),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2($elm$parser$Parser$Advanced$ignorer, $dillonkearns$elm_markdown$HtmlParser$attributeName, $dillonkearns$elm_markdown$HtmlParser$whiteSpace),
					$dillonkearns$elm_markdown$HtmlParser$symbol('=')),
				$dillonkearns$elm_markdown$HtmlParser$whiteSpace)),
		$dillonkearns$elm_markdown$HtmlParser$attributeValue));
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm$core$String$toLower = _String_toLower;
var $dillonkearns$elm_markdown$HtmlParser$attributes = function (keys) {
	return A2(
		$elm$parser$Parser$Advanced$map,
		function (attrs) {
			return A2(
				$elm$core$List$map,
				function (attr) {
					return {
						b5: $elm$core$String$toLower(attr.b5),
						bA: attr.bA
					};
				},
				attrs);
		},
		A2(
			$elm$parser$Parser$Advanced$inContext,
			'attributes',
			$elm$parser$Parser$Advanced$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$Advanced$andThen,
						function (attr) {
							return A2($elm$core$Set$member, attr.b5, keys) ? $dillonkearns$elm_markdown$HtmlParser$fail('attribute ' + (attr.b5 + ' is duplicated')) : A2(
								$elm$parser$Parser$Advanced$keeper,
								A2(
									$elm$parser$Parser$Advanced$ignorer,
									$elm$parser$Parser$Advanced$succeed(
										$elm$core$List$cons(attr)),
									$dillonkearns$elm_markdown$HtmlParser$whiteSpace),
								$dillonkearns$elm_markdown$HtmlParser$attributes(
									A2($elm$core$Set$insert, attr.b5, keys)));
						},
						$dillonkearns$elm_markdown$HtmlParser$attribute),
						$elm$parser$Parser$Advanced$succeed(_List_Nil)
					]))));
};
var $dillonkearns$elm_markdown$HtmlParser$cdata = A2(
	$elm$parser$Parser$Advanced$inContext,
	'cdata',
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			$dillonkearns$elm_markdown$HtmlParser$symbol('<![CDATA[')),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$getChompedString(
				$elm$parser$Parser$Advanced$chompUntilEndOr(']]>')),
			$dillonkearns$elm_markdown$HtmlParser$symbol(']]>'))));
var $dillonkearns$elm_markdown$HtmlParser$tagName = A2(
	$elm$parser$Parser$Advanced$map,
	function (name) {
		return $elm$core$String$toLower(name);
	},
	A2(
		$elm$parser$Parser$Advanced$inContext,
		'tagName',
		A2(
			$dillonkearns$elm_markdown$HtmlParser$keep,
			$dillonkearns$elm_markdown$HtmlParser$oneOrMore,
			function (c) {
				return (!$dillonkearns$elm_markdown$HtmlParser$isWhitespace(c)) && $dillonkearns$elm_markdown$HtmlParser$isUninteresting(c);
			})));
var $dillonkearns$elm_markdown$HtmlParser$closingTag = function (startTagName) {
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'closingTag',
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							$elm$parser$Parser$Advanced$succeed(0),
							$dillonkearns$elm_markdown$HtmlParser$symbol('</')),
						$dillonkearns$elm_markdown$HtmlParser$whiteSpace),
					A2(
						$elm$parser$Parser$Advanced$andThen,
						function (endTagName) {
							return _Utils_eq(startTagName, endTagName) ? $elm$parser$Parser$Advanced$succeed(0) : $dillonkearns$elm_markdown$HtmlParser$fail('tag name mismatch: ' + (startTagName + (' and ' + endTagName)));
						},
						$dillonkearns$elm_markdown$HtmlParser$tagName)),
				$dillonkearns$elm_markdown$HtmlParser$whiteSpace),
			$dillonkearns$elm_markdown$HtmlParser$symbol('>')));
};
var $dillonkearns$elm_markdown$HtmlParser$Comment = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$HtmlParser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $dillonkearns$elm_markdown$HtmlParser$comment = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$HtmlParser$Comment),
		$elm$parser$Parser$Advanced$token(
			$dillonkearns$elm_markdown$HtmlParser$toToken('<!--'))),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr('-->')),
		$elm$parser$Parser$Advanced$token(
			$dillonkearns$elm_markdown$HtmlParser$toToken('-->'))));
var $dillonkearns$elm_markdown$HtmlParser$Declaration = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $dillonkearns$elm_markdown$HtmlParser$allUppercase = A2(
	$dillonkearns$elm_markdown$HtmlParser$keep,
	$dillonkearns$elm_markdown$HtmlParser$oneOrMore,
	function (c) {
		return $elm$core$Char$isUpper(c);
	});
var $dillonkearns$elm_markdown$HtmlParser$oneOrMoreWhiteSpace = A2($dillonkearns$elm_markdown$HtmlParser$ignore, $dillonkearns$elm_markdown$HtmlParser$oneOrMore, $dillonkearns$elm_markdown$HtmlParser$isWhitespace);
var $dillonkearns$elm_markdown$HtmlParser$docType = A2(
	$elm$parser$Parser$Advanced$inContext,
	'declaration',
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$HtmlParser$Declaration),
				$dillonkearns$elm_markdown$HtmlParser$symbol('<!')),
			A2($elm$parser$Parser$Advanced$ignorer, $dillonkearns$elm_markdown$HtmlParser$allUppercase, $dillonkearns$elm_markdown$HtmlParser$oneOrMoreWhiteSpace)),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$getChompedString(
				$elm$parser$Parser$Advanced$chompUntilEndOr('>')),
			$dillonkearns$elm_markdown$HtmlParser$symbol('>'))));
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $dillonkearns$elm_markdown$HtmlParser$ProcessingInstruction = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$HtmlParser$processingInstruction = A2(
	$elm$parser$Parser$Advanced$inContext,
	'processingInstruction',
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$HtmlParser$ProcessingInstruction),
			$dillonkearns$elm_markdown$HtmlParser$symbol('<?')),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$getChompedString(
				$elm$parser$Parser$Advanced$chompUntilEndOr('?>')),
			$dillonkearns$elm_markdown$HtmlParser$symbol('?>'))));
function $dillonkearns$elm_markdown$HtmlParser$cyclic$textNodeString() {
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'textNodeString',
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$keeper,
						$elm$parser$Parser$Advanced$succeed(
							F2(
								function (s, maybeString) {
									return $elm$core$Maybe$Just(
										_Utils_ap(
											s,
											A2($elm$core$Maybe$withDefault, '', maybeString)));
								})),
						A2(
							$dillonkearns$elm_markdown$HtmlParser$keep,
							$dillonkearns$elm_markdown$HtmlParser$oneOrMore,
							function (c) {
								switch (c) {
									case '<':
										return false;
									case '&':
										return false;
									default:
										return true;
								}
							})),
					$elm$parser$Parser$Advanced$lazy(
						function (_v1) {
							return $dillonkearns$elm_markdown$HtmlParser$cyclic$textNodeString();
						})),
					A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$keeper,
						$elm$parser$Parser$Advanced$succeed(
							F2(
								function (c, maybeString) {
									return $elm$core$Maybe$Just(
										A2(
											$elm$core$String$cons,
											c,
											A2($elm$core$Maybe$withDefault, '', maybeString)));
								})),
						$dillonkearns$elm_markdown$HtmlParser$escapedChar('<')),
					$elm$parser$Parser$Advanced$lazy(
						function (_v2) {
							return $dillonkearns$elm_markdown$HtmlParser$cyclic$textNodeString();
						})),
					$elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Nothing)
				])));
}
var $dillonkearns$elm_markdown$HtmlParser$textNodeString = $dillonkearns$elm_markdown$HtmlParser$cyclic$textNodeString();
$dillonkearns$elm_markdown$HtmlParser$cyclic$textNodeString = function () {
	return $dillonkearns$elm_markdown$HtmlParser$textNodeString;
};
var $dillonkearns$elm_markdown$HtmlParser$children = function (startTagName) {
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'children',
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed(_List_Nil),
					$dillonkearns$elm_markdown$HtmlParser$closingTag(startTagName)),
					A2(
					$elm$parser$Parser$Advanced$andThen,
					function (maybeString) {
						if (!maybeString.$) {
							var s = maybeString.a;
							return A2(
								$elm$parser$Parser$Advanced$keeper,
								$elm$parser$Parser$Advanced$succeed(
									function (rest) {
										return A2(
											$elm$core$List$cons,
											$dillonkearns$elm_markdown$HtmlParser$Text(s),
											rest);
									}),
								$dillonkearns$elm_markdown$HtmlParser$children(startTagName));
						} else {
							return A2(
								$elm$parser$Parser$Advanced$ignorer,
								$elm$parser$Parser$Advanced$succeed(_List_Nil),
								$dillonkearns$elm_markdown$HtmlParser$closingTag(startTagName));
						}
					},
					$dillonkearns$elm_markdown$HtmlParser$textNodeString),
					$elm$parser$Parser$Advanced$lazy(
					function (_v2) {
						return A2(
							$elm$parser$Parser$Advanced$keeper,
							A2(
								$elm$parser$Parser$Advanced$keeper,
								$elm$parser$Parser$Advanced$succeed($elm$core$List$cons),
								$dillonkearns$elm_markdown$HtmlParser$cyclic$html()),
							$dillonkearns$elm_markdown$HtmlParser$children(startTagName));
					})
				])));
};
function $dillonkearns$elm_markdown$HtmlParser$cyclic$html() {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2($elm$parser$Parser$Advanced$map, $dillonkearns$elm_markdown$HtmlParser$Cdata, $dillonkearns$elm_markdown$HtmlParser$cdata),
				$dillonkearns$elm_markdown$HtmlParser$processingInstruction,
				$dillonkearns$elm_markdown$HtmlParser$comment,
				$dillonkearns$elm_markdown$HtmlParser$docType,
				$dillonkearns$elm_markdown$HtmlParser$cyclic$element()
			]));
}
function $dillonkearns$elm_markdown$HtmlParser$cyclic$element() {
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'element',
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
				$dillonkearns$elm_markdown$HtmlParser$symbol('<')),
			A2(
				$elm$parser$Parser$Advanced$andThen,
				function (startTagName) {
					return A2(
						$elm$parser$Parser$Advanced$keeper,
						A2(
							$elm$parser$Parser$Advanced$keeper,
							A2(
								$elm$parser$Parser$Advanced$ignorer,
								$elm$parser$Parser$Advanced$succeed(
									$dillonkearns$elm_markdown$HtmlParser$Element(startTagName)),
								$dillonkearns$elm_markdown$HtmlParser$whiteSpace),
							A2(
								$elm$parser$Parser$Advanced$ignorer,
								$dillonkearns$elm_markdown$HtmlParser$attributes($elm$core$Set$empty),
								$dillonkearns$elm_markdown$HtmlParser$whiteSpace)),
						$elm$parser$Parser$Advanced$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$Advanced$ignorer,
									$elm$parser$Parser$Advanced$succeed(_List_Nil),
									$dillonkearns$elm_markdown$HtmlParser$symbol('/>')),
									A2(
									$elm$parser$Parser$Advanced$keeper,
									A2(
										$elm$parser$Parser$Advanced$ignorer,
										$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
										$dillonkearns$elm_markdown$HtmlParser$symbol('>')),
									$elm$parser$Parser$Advanced$lazy(
										function (_v0) {
											return $dillonkearns$elm_markdown$HtmlParser$children(startTagName);
										}))
								])));
				},
				$dillonkearns$elm_markdown$HtmlParser$tagName)));
}
var $dillonkearns$elm_markdown$HtmlParser$html = $dillonkearns$elm_markdown$HtmlParser$cyclic$html();
$dillonkearns$elm_markdown$HtmlParser$cyclic$html = function () {
	return $dillonkearns$elm_markdown$HtmlParser$html;
};
var $dillonkearns$elm_markdown$HtmlParser$element = $dillonkearns$elm_markdown$HtmlParser$cyclic$element();
$dillonkearns$elm_markdown$HtmlParser$cyclic$element = function () {
	return $dillonkearns$elm_markdown$HtmlParser$element;
};
var $dillonkearns$elm_markdown$Markdown$Parser$indentedCodeBlock = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$RawBlock$IndentedCodeBlock),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'    ',
						$elm$parser$Parser$ExpectingSymbol('Indentation'))),
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'\t',
						$elm$parser$Parser$ExpectingSymbol('Indentation')))
				]))),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'\n',
						$elm$parser$Parser$ExpectingSymbol('\\n'))),
					$elm$parser$Parser$Advanced$end(
					$elm$parser$Parser$Expecting('End of input'))
				]))));
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith = F3(
	function (joinWith, string1, string2) {
		var _v0 = _Utils_Tuple2(string1, string2);
		if (_v0.a === '') {
			if (_v0.b === '') {
				return $elm$core$String$concat(
					_List_fromArray(
						[string1, string2]));
			} else {
				return $elm$core$String$concat(
					_List_fromArray(
						[string1, string2]));
			}
		} else {
			if (_v0.b === '') {
				return $elm$core$String$concat(
					_List_fromArray(
						[string1, string2]));
			} else {
				return $elm$core$String$concat(
					_List_fromArray(
						[string1, joinWith, string2]));
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$joinStringsPreserveAll = F2(
	function (string1, string2) {
		return $elm$core$String$concat(
			_List_fromArray(
				[string1, '\n', string2]));
	});
var $dillonkearns$elm_markdown$Markdown$Parser$just = function (value) {
	return $elm$parser$Parser$Advanced$succeed(
		$elm$core$Maybe$Just(value));
};
var $dillonkearns$elm_markdown$Markdown$Block$H1 = 0;
var $dillonkearns$elm_markdown$Markdown$Block$H2 = 1;
var $dillonkearns$elm_markdown$Markdown$Block$H3 = 2;
var $dillonkearns$elm_markdown$Markdown$Block$H4 = 3;
var $dillonkearns$elm_markdown$Markdown$Block$H5 = 4;
var $dillonkearns$elm_markdown$Markdown$Block$H6 = 5;
var $dillonkearns$elm_markdown$Markdown$Parser$levelParser = function (level) {
	switch (level) {
		case 1:
			return $elm$parser$Parser$Advanced$succeed(0);
		case 2:
			return $elm$parser$Parser$Advanced$succeed(1);
		case 3:
			return $elm$parser$Parser$Advanced$succeed(2);
		case 4:
			return $elm$parser$Parser$Advanced$succeed(3);
		case 5:
			return $elm$parser$Parser$Advanced$succeed(4);
		case 6:
			return $elm$parser$Parser$Advanced$succeed(5);
		default:
			return $elm$parser$Parser$Advanced$problem(
				$elm$parser$Parser$Expecting(
					'A heading with 1 to 6 #\'s, but found ' + $elm$core$String$fromInt(level)));
	}
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $dillonkearns$elm_markdown$Parser$Extra$oneOrMore = function (condition) {
	return A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2(
			$elm$parser$Parser$Advanced$chompIf,
			condition,
			$elm$parser$Parser$Problem('Expected one or more character')),
		$elm$parser$Parser$Advanced$chompWhile(condition));
};
var $dillonkearns$elm_markdown$Parser$Extra$positiveInteger = A2(
	$elm$parser$Parser$Advanced$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed(0),
		$dillonkearns$elm_markdown$Parser$Extra$oneOrMore($elm$core$Char$isDigit)));
var $dillonkearns$elm_markdown$Markdown$OrderedList$positiveIntegerMaxOf9Digits = A2(
	$elm$parser$Parser$Advanced$andThen,
	function (parsed) {
		return (parsed <= 999999999) ? $elm$parser$Parser$Advanced$succeed(parsed) : $elm$parser$Parser$Advanced$problem(
			$elm$parser$Parser$Problem('Starting numbers must be nine digits or less.'));
	},
	$dillonkearns$elm_markdown$Parser$Extra$positiveInteger);
var $dillonkearns$elm_markdown$Markdown$OrderedList$listMarkerParser = function () {
	var markerOption = function (marker) {
		return $elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					marker,
					$elm$parser$Parser$ExpectingSymbol(marker))));
	};
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			$elm$parser$Parser$Advanced$succeed($elm$core$Tuple$pair),
			$dillonkearns$elm_markdown$Markdown$OrderedList$positiveIntegerMaxOf9Digits),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					markerOption('.'),
					markerOption(')')
				])));
}();
var $dillonkearns$elm_markdown$Markdown$OrderedList$openingItemParser = function (lastBlock) {
	var validateStartsWith1 = function (parsed) {
		if (parsed.a === 1) {
			return $elm$parser$Parser$Advanced$succeed(parsed);
		} else {
			return $elm$parser$Parser$Advanced$problem(
				$elm$parser$Parser$Problem('Lists inside a paragraph or after a paragraph without a blank line must start with 1'));
		}
	};
	var validateStartsWith1IfInParagraph = function (parsed) {
		if ((!lastBlock.$) && (lastBlock.a.$ === 1)) {
			return validateStartsWith1(parsed);
		} else {
			return $elm$parser$Parser$Advanced$succeed(parsed);
		}
	};
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			$elm$parser$Parser$Advanced$succeed(
				F2(
					function (_v0, item) {
						var startingIndex = _v0.a;
						var marker = _v0.b;
						return _Utils_Tuple3(startingIndex, marker, item);
					})),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$backtrackable(
					A2($elm$parser$Parser$Advanced$andThen, validateStartsWith1IfInParagraph, $dillonkearns$elm_markdown$Markdown$OrderedList$listMarkerParser)),
				$dillonkearns$elm_markdown$Parser$Extra$oneOrMore($dillonkearns$elm_markdown$Helpers$isSpacebar))),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$getChompedString(
				$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'\n',
					$elm$parser$Parser$ExpectingSymbol('\n')))));
};
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.b, s);
};
var $elm$parser$Parser$Advanced$commit = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, true, a, s);
	};
};
var $dillonkearns$elm_markdown$Markdown$OrderedList$itemBody = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$Advanced$backtrackable(
						$dillonkearns$elm_markdown$Parser$Extra$oneOrMore($dillonkearns$elm_markdown$Helpers$isSpacebar))),
				$elm$parser$Parser$Advanced$commit('')),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$getChompedString(
					$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
				$elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							$elm$parser$Parser$Advanced$symbol(
							A2(
								$elm$parser$Parser$Advanced$Token,
								'\n',
								$elm$parser$Parser$ExpectingSymbol('\\n'))),
							$elm$parser$Parser$Advanced$end(
							$elm$parser$Parser$Expecting('End of input'))
						])))),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(''),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'\n',
					$elm$parser$Parser$ExpectingSymbol('\\n'))))
		]));
var $dillonkearns$elm_markdown$Markdown$OrderedList$singleItemParser = function (listMarker) {
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$Advanced$backtrackable(
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$dillonkearns$elm_markdown$Parser$Extra$positiveInteger,
					$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							listMarker,
							$elm$parser$Parser$ExpectingSymbol(listMarker)))))),
		$dillonkearns$elm_markdown$Markdown$OrderedList$itemBody);
};
var $dillonkearns$elm_markdown$Markdown$OrderedList$statementsHelp = F3(
	function (listMarker, firstItem, revStmts) {
		return $elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$keeper,
						A2(
							$elm$parser$Parser$Advanced$keeper,
							$elm$parser$Parser$Advanced$succeed(
								F3(
									function (offsetBefore, stmt, offsetAfter) {
										return $elm$parser$Parser$Advanced$Loop(
											A2($elm$core$List$cons, stmt, revStmts));
									})),
							$elm$parser$Parser$Advanced$getOffset),
						$dillonkearns$elm_markdown$Markdown$OrderedList$singleItemParser(listMarker)),
					$elm$parser$Parser$Advanced$getOffset),
					A2(
					$elm$parser$Parser$Advanced$map,
					function (_v0) {
						return $elm$parser$Parser$Advanced$Done(
							A2(
								$elm$core$List$cons,
								firstItem,
								$elm$core$List$reverse(revStmts)));
					},
					$elm$parser$Parser$Advanced$succeed(0))
				]));
	});
var $dillonkearns$elm_markdown$Markdown$OrderedList$parser = function (lastBlock) {
	return A2(
		$elm$parser$Parser$Advanced$andThen,
		function (_v0) {
			var startingIndex = _v0.a;
			var listMarker = _v0.b;
			var firstItem = _v0.c;
			return A2(
				$elm$parser$Parser$Advanced$map,
				function (items) {
					return _Utils_Tuple2(startingIndex, items);
				},
				A2(
					$elm$parser$Parser$Advanced$loop,
					_List_Nil,
					A2($dillonkearns$elm_markdown$Markdown$OrderedList$statementsHelp, listMarker, firstItem)));
		},
		$dillonkearns$elm_markdown$Markdown$OrderedList$openingItemParser(lastBlock));
};
var $dillonkearns$elm_markdown$Markdown$Parser$orderedListBlock = function (lastBlock) {
	return A2(
		$elm$parser$Parser$Advanced$map,
		function (_v0) {
			var startingIndex = _v0.a;
			var unparsedLines = _v0.b;
			return A2(
				$dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock,
				startingIndex,
				A2($elm$core$List$map, $elm$core$Basics$identity, unparsedLines));
		},
		$dillonkearns$elm_markdown$Markdown$OrderedList$parser(lastBlock));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$initParser = F2(
	function (refs, rawText) {
		return {c: _List_Nil, G: rawText, cb: refs, i: _List_Nil};
	});
var $dillonkearns$elm_markdown$Markdown$Inline$CodeInline = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Inline$Emphasis = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Inline$HardLineBreak = {$: 1};
var $dillonkearns$elm_markdown$Markdown$Inline$HtmlInline = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Inline$Image = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Inline$Link = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Inline$Text = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$matchToInline = function (_v0) {
	var match = _v0;
	var _v1 = match.R;
	switch (_v1.$) {
		case 0:
			return $dillonkearns$elm_markdown$Markdown$Inline$Text(match.D);
		case 1:
			return $dillonkearns$elm_markdown$Markdown$Inline$HardLineBreak;
		case 2:
			return $dillonkearns$elm_markdown$Markdown$Inline$CodeInline(match.D);
		case 3:
			var _v2 = _v1.a;
			var text = _v2.a;
			var url = _v2.b;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Inline$Link,
				url,
				$elm$core$Maybe$Nothing,
				_List_fromArray(
					[
						$dillonkearns$elm_markdown$Markdown$Inline$Text(text)
					]));
		case 4:
			var _v3 = _v1.a;
			var url = _v3.a;
			var maybeTitle = _v3.b;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Inline$Link,
				url,
				maybeTitle,
				$dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines(match.c));
		case 5:
			var _v4 = _v1.a;
			var url = _v4.a;
			var maybeTitle = _v4.b;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Inline$Image,
				url,
				maybeTitle,
				$dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines(match.c));
		case 6:
			var model = _v1.a;
			return $dillonkearns$elm_markdown$Markdown$Inline$HtmlInline(model);
		default:
			var length = _v1.a;
			return A2(
				$dillonkearns$elm_markdown$Markdown$Inline$Emphasis,
				length,
				$dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines(match.c));
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines = function (matches) {
	return A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$InlineParser$matchToInline, matches);
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$Match = $elm$core$Basics$identity;
var $dillonkearns$elm_markdown$Markdown$InlineParser$prepareChildMatch = F2(
	function (parentMatch, childMatch) {
		return _Utils_update(
			childMatch,
			{j: childMatch.j - parentMatch.ae, r: childMatch.r - parentMatch.ae, aE: childMatch.aE - parentMatch.ae, ae: childMatch.ae - parentMatch.ae});
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$addChild = F2(
	function (parentMatch, childMatch) {
		return _Utils_update(
			parentMatch,
			{
				c: A2(
					$elm$core$List$cons,
					A2($dillonkearns$elm_markdown$Markdown$InlineParser$prepareChildMatch, parentMatch, childMatch),
					parentMatch.c)
			});
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatch = F2(
	function (_v0, matches) {
		var match = _v0;
		if (!matches.b) {
			return _List_fromArray(
				[match]);
		} else {
			var prevMatch = matches.a;
			var matchesTail = matches.b;
			return (_Utils_cmp(prevMatch.j, match.r) < 1) ? A2($elm$core$List$cons, match, matches) : (((_Utils_cmp(prevMatch.r, match.r) < 0) && (_Utils_cmp(prevMatch.j, match.j) > 0)) ? A2(
				$elm$core$List$cons,
				A2($dillonkearns$elm_markdown$Markdown$InlineParser$addChild, prevMatch, match),
				matchesTail) : matches);
		}
	});
var $elm$core$List$sortBy = _List_sortBy;
function $dillonkearns$elm_markdown$Markdown$InlineParser$cyclic$organizeMatches() {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$sortBy(
			function (_v0) {
				var match = _v0;
				return match.r;
			}),
		A2(
			$elm$core$Basics$composeR,
			A2($elm$core$List$foldl, $dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatch, _List_Nil),
			$elm$core$List$map(
				function (_v1) {
					var match = _v1;
					return _Utils_update(
						match,
						{
							c: $dillonkearns$elm_markdown$Markdown$InlineParser$cyclic$organizeMatches()(match.c)
						});
				})));
}
var $dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatches = $dillonkearns$elm_markdown$Markdown$InlineParser$cyclic$organizeMatches();
$dillonkearns$elm_markdown$Markdown$InlineParser$cyclic$organizeMatches = function () {
	return $dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatches;
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$organizeParserMatches = function (model) {
	return _Utils_update(
		model,
		{
			c: $dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatches(model.c)
		});
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$NormalType = {$: 0};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {aB: index, b3: match, eR: number, ci: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{d5: false, eQ: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $dillonkearns$elm_markdown$Markdown$Entity$decimalRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&#([0-9]{1,8});'));
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $dillonkearns$elm_markdown$Markdown$Entity$isBadEndUnicode = function (_int) {
	var remain_ = A2($elm$core$Basics$modBy, 16, _int);
	var remain = A2($elm$core$Basics$modBy, 131070, _int);
	return (_int >= 131070) && ((((0 <= remain) && (remain <= 15)) || ((65536 <= remain) && (remain <= 65551))) && ((remain_ === 14) || (remain_ === 15)));
};
var $dillonkearns$elm_markdown$Markdown$Entity$isValidUnicode = function (_int) {
	return (_int === 9) || ((_int === 10) || ((_int === 13) || ((_int === 133) || (((32 <= _int) && (_int <= 126)) || (((160 <= _int) && (_int <= 55295)) || (((57344 <= _int) && (_int <= 64975)) || (((65008 <= _int) && (_int <= 65533)) || ((65536 <= _int) && (_int <= 1114109)))))))));
};
var $dillonkearns$elm_markdown$Markdown$Entity$validUnicode = function (_int) {
	return ($dillonkearns$elm_markdown$Markdown$Entity$isValidUnicode(_int) && (!$dillonkearns$elm_markdown$Markdown$Entity$isBadEndUnicode(_int))) ? $elm$core$String$fromChar(
		$elm$core$Char$fromCode(_int)) : $elm$core$String$fromChar(
		$elm$core$Char$fromCode(65533));
};
var $dillonkearns$elm_markdown$Markdown$Entity$replaceDecimal = function (match) {
	return A2(
		$elm$core$Maybe$withDefault,
		match.b3,
		A2(
			$elm$core$Maybe$map,
			$dillonkearns$elm_markdown$Markdown$Entity$validUnicode,
			A2(
				$elm$core$Maybe$andThen,
				$elm$core$String$toInt,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					$elm$core$List$head(match.ci)))));
};
var $dillonkearns$elm_markdown$Markdown$Entity$replaceDecimals = A2($elm$regex$Regex$replace, $dillonkearns$elm_markdown$Markdown$Entity$decimalRegex, $dillonkearns$elm_markdown$Markdown$Entity$replaceDecimal);
var $dillonkearns$elm_markdown$Markdown$Entity$entitiesRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&([0-9a-zA-Z]+);'));
var $dillonkearns$elm_markdown$Markdown$Entity$entities = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('quot', 34),
			_Utils_Tuple2('amp', 38),
			_Utils_Tuple2('apos', 39),
			_Utils_Tuple2('lt', 60),
			_Utils_Tuple2('gt', 62),
			_Utils_Tuple2('nbsp', 160),
			_Utils_Tuple2('iexcl', 161),
			_Utils_Tuple2('cent', 162),
			_Utils_Tuple2('pound', 163),
			_Utils_Tuple2('curren', 164),
			_Utils_Tuple2('yen', 165),
			_Utils_Tuple2('brvbar', 166),
			_Utils_Tuple2('sect', 167),
			_Utils_Tuple2('uml', 168),
			_Utils_Tuple2('copy', 169),
			_Utils_Tuple2('ordf', 170),
			_Utils_Tuple2('laquo', 171),
			_Utils_Tuple2('not', 172),
			_Utils_Tuple2('shy', 173),
			_Utils_Tuple2('reg', 174),
			_Utils_Tuple2('macr', 175),
			_Utils_Tuple2('deg', 176),
			_Utils_Tuple2('plusmn', 177),
			_Utils_Tuple2('sup2', 178),
			_Utils_Tuple2('sup3', 179),
			_Utils_Tuple2('acute', 180),
			_Utils_Tuple2('micro', 181),
			_Utils_Tuple2('para', 182),
			_Utils_Tuple2('middot', 183),
			_Utils_Tuple2('cedil', 184),
			_Utils_Tuple2('sup1', 185),
			_Utils_Tuple2('ordm', 186),
			_Utils_Tuple2('raquo', 187),
			_Utils_Tuple2('frac14', 188),
			_Utils_Tuple2('frac12', 189),
			_Utils_Tuple2('frac34', 190),
			_Utils_Tuple2('iquest', 191),
			_Utils_Tuple2('Agrave', 192),
			_Utils_Tuple2('Aacute', 193),
			_Utils_Tuple2('Acirc', 194),
			_Utils_Tuple2('Atilde', 195),
			_Utils_Tuple2('Auml', 196),
			_Utils_Tuple2('Aring', 197),
			_Utils_Tuple2('AElig', 198),
			_Utils_Tuple2('Ccedil', 199),
			_Utils_Tuple2('Egrave', 200),
			_Utils_Tuple2('Eacute', 201),
			_Utils_Tuple2('Ecirc', 202),
			_Utils_Tuple2('Euml', 203),
			_Utils_Tuple2('Igrave', 204),
			_Utils_Tuple2('Iacute', 205),
			_Utils_Tuple2('Icirc', 206),
			_Utils_Tuple2('Iuml', 207),
			_Utils_Tuple2('ETH', 208),
			_Utils_Tuple2('Ntilde', 209),
			_Utils_Tuple2('Ograve', 210),
			_Utils_Tuple2('Oacute', 211),
			_Utils_Tuple2('Ocirc', 212),
			_Utils_Tuple2('Otilde', 213),
			_Utils_Tuple2('Ouml', 214),
			_Utils_Tuple2('times', 215),
			_Utils_Tuple2('Oslash', 216),
			_Utils_Tuple2('Ugrave', 217),
			_Utils_Tuple2('Uacute', 218),
			_Utils_Tuple2('Ucirc', 219),
			_Utils_Tuple2('Uuml', 220),
			_Utils_Tuple2('Yacute', 221),
			_Utils_Tuple2('THORN', 222),
			_Utils_Tuple2('szlig', 223),
			_Utils_Tuple2('agrave', 224),
			_Utils_Tuple2('aacute', 225),
			_Utils_Tuple2('acirc', 226),
			_Utils_Tuple2('atilde', 227),
			_Utils_Tuple2('auml', 228),
			_Utils_Tuple2('aring', 229),
			_Utils_Tuple2('aelig', 230),
			_Utils_Tuple2('ccedil', 231),
			_Utils_Tuple2('egrave', 232),
			_Utils_Tuple2('eacute', 233),
			_Utils_Tuple2('ecirc', 234),
			_Utils_Tuple2('euml', 235),
			_Utils_Tuple2('igrave', 236),
			_Utils_Tuple2('iacute', 237),
			_Utils_Tuple2('icirc', 238),
			_Utils_Tuple2('iuml', 239),
			_Utils_Tuple2('eth', 240),
			_Utils_Tuple2('ntilde', 241),
			_Utils_Tuple2('ograve', 242),
			_Utils_Tuple2('oacute', 243),
			_Utils_Tuple2('ocirc', 244),
			_Utils_Tuple2('otilde', 245),
			_Utils_Tuple2('ouml', 246),
			_Utils_Tuple2('divide', 247),
			_Utils_Tuple2('oslash', 248),
			_Utils_Tuple2('ugrave', 249),
			_Utils_Tuple2('uacute', 250),
			_Utils_Tuple2('ucirc', 251),
			_Utils_Tuple2('uuml', 252),
			_Utils_Tuple2('yacute', 253),
			_Utils_Tuple2('thorn', 254),
			_Utils_Tuple2('yuml', 255),
			_Utils_Tuple2('OElig', 338),
			_Utils_Tuple2('oelig', 339),
			_Utils_Tuple2('Scaron', 352),
			_Utils_Tuple2('scaron', 353),
			_Utils_Tuple2('Yuml', 376),
			_Utils_Tuple2('fnof', 402),
			_Utils_Tuple2('circ', 710),
			_Utils_Tuple2('tilde', 732),
			_Utils_Tuple2('Alpha', 913),
			_Utils_Tuple2('Beta', 914),
			_Utils_Tuple2('Gamma', 915),
			_Utils_Tuple2('Delta', 916),
			_Utils_Tuple2('Epsilon', 917),
			_Utils_Tuple2('Zeta', 918),
			_Utils_Tuple2('Eta', 919),
			_Utils_Tuple2('Theta', 920),
			_Utils_Tuple2('Iota', 921),
			_Utils_Tuple2('Kappa', 922),
			_Utils_Tuple2('Lambda', 923),
			_Utils_Tuple2('Mu', 924),
			_Utils_Tuple2('Nu', 925),
			_Utils_Tuple2('Xi', 926),
			_Utils_Tuple2('Omicron', 927),
			_Utils_Tuple2('Pi', 928),
			_Utils_Tuple2('Rho', 929),
			_Utils_Tuple2('Sigma', 931),
			_Utils_Tuple2('Tau', 932),
			_Utils_Tuple2('Upsilon', 933),
			_Utils_Tuple2('Phi', 934),
			_Utils_Tuple2('Chi', 935),
			_Utils_Tuple2('Psi', 936),
			_Utils_Tuple2('Omega', 937),
			_Utils_Tuple2('alpha', 945),
			_Utils_Tuple2('beta', 946),
			_Utils_Tuple2('gamma', 947),
			_Utils_Tuple2('delta', 948),
			_Utils_Tuple2('epsilon', 949),
			_Utils_Tuple2('zeta', 950),
			_Utils_Tuple2('eta', 951),
			_Utils_Tuple2('theta', 952),
			_Utils_Tuple2('iota', 953),
			_Utils_Tuple2('kappa', 954),
			_Utils_Tuple2('lambda', 955),
			_Utils_Tuple2('mu', 956),
			_Utils_Tuple2('nu', 957),
			_Utils_Tuple2('xi', 958),
			_Utils_Tuple2('omicron', 959),
			_Utils_Tuple2('pi', 960),
			_Utils_Tuple2('rho', 961),
			_Utils_Tuple2('sigmaf', 962),
			_Utils_Tuple2('sigma', 963),
			_Utils_Tuple2('tau', 964),
			_Utils_Tuple2('upsilon', 965),
			_Utils_Tuple2('phi', 966),
			_Utils_Tuple2('chi', 967),
			_Utils_Tuple2('psi', 968),
			_Utils_Tuple2('omega', 969),
			_Utils_Tuple2('thetasym', 977),
			_Utils_Tuple2('upsih', 978),
			_Utils_Tuple2('piv', 982),
			_Utils_Tuple2('ensp', 8194),
			_Utils_Tuple2('emsp', 8195),
			_Utils_Tuple2('thinsp', 8201),
			_Utils_Tuple2('zwnj', 8204),
			_Utils_Tuple2('zwj', 8205),
			_Utils_Tuple2('lrm', 8206),
			_Utils_Tuple2('rlm', 8207),
			_Utils_Tuple2('ndash', 8211),
			_Utils_Tuple2('mdash', 8212),
			_Utils_Tuple2('lsquo', 8216),
			_Utils_Tuple2('rsquo', 8217),
			_Utils_Tuple2('sbquo', 8218),
			_Utils_Tuple2('ldquo', 8220),
			_Utils_Tuple2('rdquo', 8221),
			_Utils_Tuple2('bdquo', 8222),
			_Utils_Tuple2('dagger', 8224),
			_Utils_Tuple2('Dagger', 8225),
			_Utils_Tuple2('bull', 8226),
			_Utils_Tuple2('hellip', 8230),
			_Utils_Tuple2('permil', 8240),
			_Utils_Tuple2('prime', 8242),
			_Utils_Tuple2('Prime', 8243),
			_Utils_Tuple2('lsaquo', 8249),
			_Utils_Tuple2('rsaquo', 8250),
			_Utils_Tuple2('oline', 8254),
			_Utils_Tuple2('frasl', 8260),
			_Utils_Tuple2('euro', 8364),
			_Utils_Tuple2('image', 8465),
			_Utils_Tuple2('weierp', 8472),
			_Utils_Tuple2('real', 8476),
			_Utils_Tuple2('trade', 8482),
			_Utils_Tuple2('alefsym', 8501),
			_Utils_Tuple2('larr', 8592),
			_Utils_Tuple2('uarr', 8593),
			_Utils_Tuple2('rarr', 8594),
			_Utils_Tuple2('darr', 8595),
			_Utils_Tuple2('harr', 8596),
			_Utils_Tuple2('crarr', 8629),
			_Utils_Tuple2('lArr', 8656),
			_Utils_Tuple2('uArr', 8657),
			_Utils_Tuple2('rArr', 8658),
			_Utils_Tuple2('dArr', 8659),
			_Utils_Tuple2('hArr', 8660),
			_Utils_Tuple2('forall', 8704),
			_Utils_Tuple2('part', 8706),
			_Utils_Tuple2('exist', 8707),
			_Utils_Tuple2('empty', 8709),
			_Utils_Tuple2('nabla', 8711),
			_Utils_Tuple2('isin', 8712),
			_Utils_Tuple2('notin', 8713),
			_Utils_Tuple2('ni', 8715),
			_Utils_Tuple2('prod', 8719),
			_Utils_Tuple2('sum', 8721),
			_Utils_Tuple2('minus', 8722),
			_Utils_Tuple2('lowast', 8727),
			_Utils_Tuple2('radic', 8730),
			_Utils_Tuple2('prop', 8733),
			_Utils_Tuple2('infin', 8734),
			_Utils_Tuple2('ang', 8736),
			_Utils_Tuple2('and', 8743),
			_Utils_Tuple2('or', 8744),
			_Utils_Tuple2('cap', 8745),
			_Utils_Tuple2('cup', 8746),
			_Utils_Tuple2('int', 8747),
			_Utils_Tuple2('there4', 8756),
			_Utils_Tuple2('sim', 8764),
			_Utils_Tuple2('cong', 8773),
			_Utils_Tuple2('asymp', 8776),
			_Utils_Tuple2('ne', 8800),
			_Utils_Tuple2('equiv', 8801),
			_Utils_Tuple2('le', 8804),
			_Utils_Tuple2('ge', 8805),
			_Utils_Tuple2('sub', 8834),
			_Utils_Tuple2('sup', 8835),
			_Utils_Tuple2('nsub', 8836),
			_Utils_Tuple2('sube', 8838),
			_Utils_Tuple2('supe', 8839),
			_Utils_Tuple2('oplus', 8853),
			_Utils_Tuple2('otimes', 8855),
			_Utils_Tuple2('perp', 8869),
			_Utils_Tuple2('sdot', 8901),
			_Utils_Tuple2('lceil', 8968),
			_Utils_Tuple2('rceil', 8969),
			_Utils_Tuple2('lfloor', 8970),
			_Utils_Tuple2('rfloor', 8971),
			_Utils_Tuple2('lang', 9001),
			_Utils_Tuple2('rang', 9002),
			_Utils_Tuple2('loz', 9674),
			_Utils_Tuple2('spades', 9824),
			_Utils_Tuple2('clubs', 9827),
			_Utils_Tuple2('hearts', 9829),
			_Utils_Tuple2('diams', 9830)
		]));
var $dillonkearns$elm_markdown$Markdown$Entity$replaceEntity = function (match) {
	return A2(
		$elm$core$Maybe$withDefault,
		match.b3,
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			A2(
				$elm$core$Maybe$andThen,
				function (a) {
					return A2($elm$core$Dict$get, a, $dillonkearns$elm_markdown$Markdown$Entity$entities);
				},
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					$elm$core$List$head(match.ci)))));
};
var $dillonkearns$elm_markdown$Markdown$Entity$replaceEntities = A2($elm$regex$Regex$replace, $dillonkearns$elm_markdown$Markdown$Entity$entitiesRegex, $dillonkearns$elm_markdown$Markdown$Entity$replaceEntity);
var $dillonkearns$elm_markdown$Markdown$Helpers$escapableRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\+)([!\"#$%&\\\'()*+,./:;<=>?@[\\\\\\]^_`{|}~-])'));
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $dillonkearns$elm_markdown$Markdown$Helpers$replaceEscapable = A2(
	$elm$regex$Regex$replace,
	$dillonkearns$elm_markdown$Markdown$Helpers$escapableRegex,
	function (regexMatch) {
		var _v0 = regexMatch.ci;
		if (((_v0.b && (!_v0.a.$)) && _v0.b.b) && (!_v0.b.a.$)) {
			var backslashes = _v0.a.a;
			var _v1 = _v0.b;
			var escapedStr = _v1.a.a;
			return _Utils_ap(
				A2(
					$elm$core$String$repeat,
					($elm$core$String$length(backslashes) / 2) | 0,
					'\\'),
				escapedStr);
		} else {
			return regexMatch.b3;
		}
	});
var $dillonkearns$elm_markdown$Markdown$Entity$hexadecimalRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&#[Xx]([0-9a-fA-F]{1,8});'));
var $dillonkearns$elm_markdown$Markdown$Entity$hexToInt = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$toLower,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$toList,
		A2(
			$elm$core$List$foldl,
			F2(
				function (hexDigit, _int) {
					return ((_int * 16) + A2(
						$elm$core$Basics$modBy,
						39,
						$elm$core$Char$toCode(hexDigit))) - 9;
				}),
			0)));
var $dillonkearns$elm_markdown$Markdown$Entity$replaceHexadecimal = function (match) {
	return A2(
		$elm$core$Maybe$withDefault,
		match.b3,
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeR, $dillonkearns$elm_markdown$Markdown$Entity$hexToInt, $dillonkearns$elm_markdown$Markdown$Entity$validUnicode),
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Maybe$Nothing,
				$elm$core$List$head(match.ci))));
};
var $dillonkearns$elm_markdown$Markdown$Entity$replaceHexadecimals = A2($elm$regex$Regex$replace, $dillonkearns$elm_markdown$Markdown$Entity$hexadecimalRegex, $dillonkearns$elm_markdown$Markdown$Entity$replaceHexadecimal);
var $dillonkearns$elm_markdown$Markdown$Helpers$formatStr = function (str) {
	return $dillonkearns$elm_markdown$Markdown$Entity$replaceHexadecimals(
		$dillonkearns$elm_markdown$Markdown$Entity$replaceDecimals(
			$dillonkearns$elm_markdown$Markdown$Entity$replaceEntities(
				$dillonkearns$elm_markdown$Markdown$Helpers$replaceEscapable(str))));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch = function (text) {
	return {
		j: 0,
		c: _List_Nil,
		r: 0,
		D: $dillonkearns$elm_markdown$Markdown$Helpers$formatStr(text),
		aE: 0,
		ae: 0,
		R: $dillonkearns$elm_markdown$Markdown$InlineParser$NormalType
	};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatch = F3(
	function (rawText, _v2, parsedMatches) {
		var matchModel = _v2;
		var updtMatch = _Utils_update(
			matchModel,
			{
				c: A3($dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatches, matchModel.D, _List_Nil, matchModel.c)
			});
		if (!parsedMatches.b) {
			var finalStr = A2($elm$core$String$dropLeft, matchModel.j, rawText);
			return $elm$core$String$isEmpty(finalStr) ? _List_fromArray(
				[updtMatch]) : _List_fromArray(
				[
					updtMatch,
					$dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch(finalStr)
				]);
		} else {
			var matchHead = parsedMatches.a;
			var matchesTail = parsedMatches.b;
			return _Utils_eq(matchHead.R, $dillonkearns$elm_markdown$Markdown$InlineParser$NormalType) ? A2($elm$core$List$cons, updtMatch, parsedMatches) : (_Utils_eq(matchModel.j, matchHead.r) ? A2($elm$core$List$cons, updtMatch, parsedMatches) : ((_Utils_cmp(matchModel.j, matchHead.r) < 0) ? A2(
				$elm$core$List$cons,
				updtMatch,
				A2(
					$elm$core$List$cons,
					$dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch(
						A3($elm$core$String$slice, matchModel.j, matchHead.r, rawText)),
					parsedMatches)) : parsedMatches));
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatches = F3(
	function (rawText, parsedMatches, matches) {
		parseTextMatches:
		while (true) {
			if (!matches.b) {
				if (!parsedMatches.b) {
					return $elm$core$String$isEmpty(rawText) ? _List_Nil : _List_fromArray(
						[
							$dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch(rawText)
						]);
				} else {
					var matchModel = parsedMatches.a;
					return (matchModel.r > 0) ? A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch(
							A2($elm$core$String$left, matchModel.r, rawText)),
						parsedMatches) : parsedMatches;
				}
			} else {
				var match = matches.a;
				var matchesTail = matches.b;
				var $temp$rawText = rawText,
					$temp$parsedMatches = A3($dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatch, rawText, match, parsedMatches),
					$temp$matches = matchesTail;
				rawText = $temp$rawText;
				parsedMatches = $temp$parsedMatches;
				matches = $temp$matches;
				continue parseTextMatches;
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$parseText = function (model) {
	return _Utils_update(
		model,
		{
			c: A3($dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatches, model.G, _List_Nil, model.c)
		});
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketLTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\<)'));
var $elm$regex$Regex$find = _Regex_findAtMost(_Regex_infinity);
var $dillonkearns$elm_markdown$Markdown$InlineParser$CharToken = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Helpers$isEven = function (_int) {
	return !A2($elm$core$Basics$modBy, 2, _int);
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketLToken = function (regMatch) {
	var _v0 = regMatch.ci;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var delimiter = _v1.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
			{
				aB: regMatch.aB + backslashesLength,
				d: 1,
				g: $dillonkearns$elm_markdown$Markdown$InlineParser$CharToken('<')
			}) : $elm$core$Maybe$Nothing;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findAngleBracketLTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketLToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketLTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketRTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\>)'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$RightAngleBracket = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketRToken = function (regMatch) {
	var _v0 = regMatch.ci;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $elm$core$Maybe$Just(
			{
				aB: regMatch.aB + backslashesLength,
				d: 1,
				g: $dillonkearns$elm_markdown$Markdown$InlineParser$RightAngleBracket(
					!$dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength))
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findAngleBracketRTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketRToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketRTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$asteriskEmphasisTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)([^*])?(\\*+)([^*])?'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$EmphasisToken = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $dillonkearns$elm_markdown$Markdown$InlineParser$punctuationRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('[!-#%-\\*,-/:;\\?@\\[-\\]_\\{\\}]'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$containPunctuation = $elm$regex$Regex$contains($dillonkearns$elm_markdown$Markdown$InlineParser$punctuationRegex);
var $dillonkearns$elm_markdown$Markdown$InlineParser$spaceRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\s'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$containSpace = $elm$regex$Regex$contains($dillonkearns$elm_markdown$Markdown$InlineParser$spaceRegex);
var $dillonkearns$elm_markdown$Markdown$InlineParser$charFringeRank = function (_char) {
	var string = $elm$core$String$fromChar(_char);
	return $dillonkearns$elm_markdown$Markdown$InlineParser$containSpace(string) ? 0 : ($dillonkearns$elm_markdown$Markdown$InlineParser$containPunctuation(string) ? 1 : 2);
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$maybeCharFringeRank = function (maybeChar) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2($elm$core$Maybe$map, $dillonkearns$elm_markdown$Markdown$InlineParser$charFringeRank, maybeChar));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$getFringeRank = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$String$uncons,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($elm$core$Tuple$first),
				$dillonkearns$elm_markdown$Markdown$InlineParser$maybeCharFringeRank))),
	$elm$core$Maybe$withDefault(0));
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken = F3(
	function (_char, rawText, regMatch) {
		var _v0 = regMatch.ci;
		if ((((_v0.b && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.a.$)) && _v0.b.b.b.b) {
			var maybeBackslashes = _v0.a;
			var _v1 = _v0.b;
			var maybeLeftFringe = _v1.a;
			var _v2 = _v1.b;
			var delimiter = _v2.a.a;
			var _v3 = _v2.b;
			var maybeRightFringe = _v3.a;
			var leftFringeLength = A2(
				$elm$core$Maybe$withDefault,
				0,
				A2($elm$core$Maybe$map, $elm$core$String$length, maybeLeftFringe));
			var mLeftFringe = ((!(!regMatch.aB)) && (!leftFringeLength)) ? $elm$core$Maybe$Just(
				A3($elm$core$String$slice, regMatch.aB - 1, regMatch.aB, rawText)) : maybeLeftFringe;
			var backslashesLength = A2(
				$elm$core$Maybe$withDefault,
				0,
				A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
			var isEscaped = ((!$dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength)) && (!leftFringeLength)) || _Utils_eq(
				mLeftFringe,
				$elm$core$Maybe$Just('\\'));
			var delimiterLength = isEscaped ? ($elm$core$String$length(delimiter) - 1) : $elm$core$String$length(delimiter);
			var fringeRank = _Utils_Tuple2(
				isEscaped ? 1 : $dillonkearns$elm_markdown$Markdown$InlineParser$getFringeRank(mLeftFringe),
				$dillonkearns$elm_markdown$Markdown$InlineParser$getFringeRank(maybeRightFringe));
			var index = ((regMatch.aB + backslashesLength) + leftFringeLength) + (isEscaped ? 1 : 0);
			return ((delimiterLength <= 0) || ((_char === '_') && _Utils_eq(
				fringeRank,
				_Utils_Tuple2(2, 2)))) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				{
					aB: index,
					d: delimiterLength,
					g: A2($dillonkearns$elm_markdown$Markdown$InlineParser$EmphasisToken, _char, fringeRank)
				});
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$findAsteriskEmphasisTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		A2($dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken, '*', str),
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$asteriskEmphasisTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$codeTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\`+)'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$CodeToken = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToCodeToken = function (regMatch) {
	var _v0 = regMatch.ci;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var backtick = _v1.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $elm$core$Maybe$Just(
			{
				aB: regMatch.aB + backslashesLength,
				d: $elm$core$String$length(backtick),
				g: $dillonkearns$elm_markdown$Markdown$InlineParser$CodeToken(
					!$dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength))
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findCodeTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToCodeToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$codeTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$hardBreakTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(?:(\\\\+)|( {2,}))\\n'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken = {$: 8};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToHardBreakToken = function (regMatch) {
	var _v0 = regMatch.ci;
	_v0$2:
	while (true) {
		if (_v0.b) {
			if (!_v0.a.$) {
				var backslashes = _v0.a.a;
				var backslashesLength = $elm$core$String$length(backslashes);
				return (!$dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength)) ? $elm$core$Maybe$Just(
					{aB: (regMatch.aB + backslashesLength) - 1, d: 2, g: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken}) : $elm$core$Maybe$Nothing;
			} else {
				if (_v0.b.b && (!_v0.b.a.$)) {
					var _v1 = _v0.b;
					return $elm$core$Maybe$Just(
						{
							aB: regMatch.aB,
							d: $elm$core$String$length(regMatch.b3),
							g: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken
						});
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToSoftHardBreakToken = function (regMatch) {
	var _v0 = regMatch.ci;
	_v0$2:
	while (true) {
		if (_v0.b) {
			if (!_v0.a.$) {
				var backslashes = _v0.a.a;
				var backslashesLength = $elm$core$String$length(backslashes);
				return $dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
					{aB: regMatch.aB + backslashesLength, d: 1, g: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken}) : $elm$core$Maybe$Just(
					{aB: (regMatch.aB + backslashesLength) - 1, d: 2, g: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken});
			} else {
				if (_v0.b.b) {
					var _v1 = _v0.b;
					var maybeSpaces = _v1.a;
					return $elm$core$Maybe$Just(
						{
							aB: regMatch.aB,
							d: $elm$core$String$length(regMatch.b3),
							g: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken
						});
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$softAsHardLineBreak = false;
var $dillonkearns$elm_markdown$Markdown$InlineParser$softAsHardLineBreakTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(?:(\\\\+)|( *))\\n'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$findHardBreakTokens = function (str) {
	return $dillonkearns$elm_markdown$Markdown$InlineParser$softAsHardLineBreak ? A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToSoftHardBreakToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$softAsHardLineBreakTokenRegex, str)) : A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToHardBreakToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$hardBreakTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageCloseTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\])'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToLinkImageCloseToken = function (regMatch) {
	var _v0 = regMatch.ci;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var delimiter = _v1.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
			{
				aB: regMatch.aB + backslashesLength,
				d: 1,
				g: $dillonkearns$elm_markdown$Markdown$InlineParser$CharToken(']')
			}) : $elm$core$Maybe$Nothing;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findLinkImageCloseTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToLinkImageCloseToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageCloseTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageOpenTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\!)?(\\[)'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$ImageOpenToken = {$: 2};
var $dillonkearns$elm_markdown$Markdown$InlineParser$LinkOpenToken = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToLinkImageOpenToken = function (regMatch) {
	var _v0 = regMatch.ci;
	if (((_v0.b && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var maybeImageOpen = _v1.a;
		var _v2 = _v1.b;
		var delimiter = _v2.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		var isEscaped = !$dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength);
		var index = (regMatch.aB + backslashesLength) + ((isEscaped && _Utils_eq(
			maybeImageOpen,
			$elm$core$Maybe$Just('!'))) ? 1 : 0);
		var meaning = isEscaped ? A2(
			$elm$core$Maybe$map,
			function (_v3) {
				return $dillonkearns$elm_markdown$Markdown$InlineParser$LinkOpenToken(true);
			},
			maybeImageOpen) : $elm$core$Maybe$Just(
			A2(
				$elm$core$Maybe$withDefault,
				$dillonkearns$elm_markdown$Markdown$InlineParser$LinkOpenToken(true),
				A2(
					$elm$core$Maybe$map,
					function (_v4) {
						return $dillonkearns$elm_markdown$Markdown$InlineParser$ImageOpenToken;
					},
					maybeImageOpen)));
		var length = _Utils_eq(
			meaning,
			$elm$core$Maybe$Just($dillonkearns$elm_markdown$Markdown$InlineParser$ImageOpenToken)) ? 2 : 1;
		var toModel = function (m) {
			return {aB: index, d: length, g: m};
		};
		return A2($elm$core$Maybe$map, toModel, meaning);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findLinkImageOpenTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToLinkImageOpenToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageOpenTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$underlineEmphasisTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)([^_])?(\\_+)([^_])?'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$findUnderlineEmphasisTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		A2($dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken, '_', str),
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$underlineEmphasisTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$tokenize = function (model) {
	return _Utils_update(
		model,
		{
			i: A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.aB;
				},
				_Utils_ap(
					$dillonkearns$elm_markdown$Markdown$InlineParser$findAngleBracketRTokens(model.G),
					_Utils_ap(
						$dillonkearns$elm_markdown$Markdown$InlineParser$findAngleBracketLTokens(model.G),
						_Utils_ap(
							$dillonkearns$elm_markdown$Markdown$InlineParser$findHardBreakTokens(model.G),
							_Utils_ap(
								$dillonkearns$elm_markdown$Markdown$InlineParser$findLinkImageCloseTokens(model.G),
								_Utils_ap(
									$dillonkearns$elm_markdown$Markdown$InlineParser$findLinkImageOpenTokens(model.G),
									_Utils_ap(
										$dillonkearns$elm_markdown$Markdown$InlineParser$findUnderlineEmphasisTokens(model.G),
										_Utils_ap(
											$dillonkearns$elm_markdown$Markdown$InlineParser$findAsteriskEmphasisTokens(model.G),
											$dillonkearns$elm_markdown$Markdown$InlineParser$findCodeTokens(model.G)))))))))
		});
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$CodeType = {$: 2};
var $dillonkearns$elm_markdown$Markdown$InlineParser$EmphasisType = function (a) {
	return {$: 7, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$HtmlType = function (a) {
	return {$: 6, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$ImageType = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$LinkType = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$addMatch = F2(
	function (model, match) {
		return _Utils_update(
			model,
			{
				c: A2($elm$core$List$cons, match, model.c)
			});
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$addToken = F2(
	function (model, token) {
		return _Utils_update(
			model,
			{
				i: A2($elm$core$List$cons, token, model.i)
			});
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$applyTTM = F2(
	function (finderFunction, model) {
		return finderFunction(
			_Utils_Tuple2(
				model.i,
				_Utils_update(
					model,
					{i: _List_Nil})));
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$AutolinkType = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$decodeUrlRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('%(?:3B|2C|2F|3F|3A|40|26|3D|2B|24|23|25)'));
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $dillonkearns$elm_markdown$Markdown$InlineParser$encodeUrl = A2(
	$elm$core$Basics$composeR,
	$elm$url$Url$percentEncode,
	A2(
		$elm$regex$Regex$replace,
		$dillonkearns$elm_markdown$Markdown$InlineParser$decodeUrlRegex,
		function (match) {
			return A2(
				$elm$core$Maybe$withDefault,
				match.b3,
				$elm$url$Url$percentDecode(match.b3));
		}));
var $dillonkearns$elm_markdown$Markdown$InlineParser$urlRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([A-Za-z][A-Za-z0-9.+\\-]{1,31}:[^<>\\x00-\\x20]*)$'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$autolinkToMatch = function (_v0) {
	var match = _v0;
	return A2($elm$regex$Regex$contains, $dillonkearns$elm_markdown$Markdown$InlineParser$urlRegex, match.D) ? $elm$core$Result$Ok(
		_Utils_update(
			match,
			{
				R: $dillonkearns$elm_markdown$Markdown$InlineParser$AutolinkType(
					_Utils_Tuple2(
						match.D,
						$dillonkearns$elm_markdown$Markdown$InlineParser$encodeUrl(match.D)))
			})) : $elm$core$Result$Err(match);
};
var $elm$regex$Regex$findAtMost = _Regex_findAtMost;
var $dillonkearns$elm_markdown$Markdown$Helpers$lineEndChars = '\\f\\v\\r\\n';
var $dillonkearns$elm_markdown$Markdown$Helpers$whiteSpaceChars = ' \\t\\f\\v\\r\\n';
var $dillonkearns$elm_markdown$Markdown$InlineParser$hrefRegex = '(?:<([^<>' + ($dillonkearns$elm_markdown$Markdown$Helpers$lineEndChars + (']*)>|([^' + ($dillonkearns$elm_markdown$Markdown$Helpers$whiteSpaceChars + ('\\(\\)\\\\]*(?:\\\\.[^' + ($dillonkearns$elm_markdown$Markdown$Helpers$whiteSpaceChars + '\\(\\)\\\\]*)*))')))));
var $dillonkearns$elm_markdown$Markdown$Helpers$titleRegex = '(?:[' + ($dillonkearns$elm_markdown$Markdown$Helpers$whiteSpaceChars + (']+' + ('(?:\'([^\'\\\\]*(?:\\\\.[^\'\\\\]*)*)\'|' + ('\"([^\"\\\\]*(?:\\\\.[^\"\\\\]*)*)\"|' + '\\(([^\\)\\\\]*(?:\\\\.[^\\)\\\\]*)*)\\)))?'))));
var $dillonkearns$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\(\\s*' + ($dillonkearns$elm_markdown$Markdown$InlineParser$hrefRegex + ($dillonkearns$elm_markdown$Markdown$Helpers$titleRegex + '\\s*\\)'))));
var $dillonkearns$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle = function (_v0) {
	var rawUrl = _v0.a;
	var maybeTitle = _v0.b;
	return _Utils_Tuple2(
		$dillonkearns$elm_markdown$Markdown$InlineParser$encodeUrl(
			$dillonkearns$elm_markdown$Markdown$Helpers$formatStr(rawUrl)),
		A2($elm$core$Maybe$map, $dillonkearns$elm_markdown$Markdown$Helpers$formatStr, maybeTitle));
};
var $dillonkearns$elm_markdown$Markdown$Helpers$returnFirstJust = function (maybes) {
	var process = F2(
		function (a, maybeFound) {
			if (!maybeFound.$) {
				var found = maybeFound.a;
				return $elm$core$Maybe$Just(found);
			} else {
				return a;
			}
		});
	return A3($elm$core$List$foldl, process, $elm$core$Maybe$Nothing, maybes);
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegexToMatch = F3(
	function (matchModel, model, regexMatch) {
		var _v0 = regexMatch.ci;
		if ((((_v0.b && _v0.b.b) && _v0.b.b.b) && _v0.b.b.b.b) && _v0.b.b.b.b.b) {
			var maybeRawUrlAngleBrackets = _v0.a;
			var _v1 = _v0.b;
			var maybeRawUrlWithoutBrackets = _v1.a;
			var _v2 = _v1.b;
			var maybeTitleSingleQuotes = _v2.a;
			var _v3 = _v2.b;
			var maybeTitleDoubleQuotes = _v3.a;
			var _v4 = _v3.b;
			var maybeTitleParenthesis = _v4.a;
			var maybeTitle = $dillonkearns$elm_markdown$Markdown$Helpers$returnFirstJust(
				_List_fromArray(
					[maybeTitleSingleQuotes, maybeTitleDoubleQuotes, maybeTitleParenthesis]));
			var toMatch = function (rawUrl) {
				return _Utils_update(
					matchModel,
					{
						j: matchModel.j + $elm$core$String$length(regexMatch.b3),
						R: function () {
							var _v5 = matchModel.R;
							if (_v5.$ === 5) {
								return $dillonkearns$elm_markdown$Markdown$InlineParser$ImageType;
							} else {
								return $dillonkearns$elm_markdown$Markdown$InlineParser$LinkType;
							}
						}()(
							$dillonkearns$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle(
								_Utils_Tuple2(rawUrl, maybeTitle)))
					});
			};
			var maybeRawUrl = $dillonkearns$elm_markdown$Markdown$Helpers$returnFirstJust(
				_List_fromArray(
					[maybeRawUrlAngleBrackets, maybeRawUrlWithoutBrackets]));
			return $elm$core$Maybe$Just(
				toMatch(
					A2($elm$core$Maybe$withDefault, '', maybeRawUrl)));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType = function (_v0) {
	var remainText = _v0.a;
	var tempMatch = _v0.b;
	var model = _v0.c;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple3(remainText, tempMatch, model),
		A2(
			$elm$core$Maybe$map,
			$dillonkearns$elm_markdown$Markdown$InlineParser$addMatch(model),
			A2(
				$elm$core$Maybe$andThen,
				A2($dillonkearns$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegexToMatch, tempMatch, model),
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $dillonkearns$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegex, remainText)))));
};
var $dillonkearns$elm_markdown$Markdown$Helpers$insideSquareBracketRegex = '[^\\[\\]\\\\]*(?:\\\\.[^\\[\\]\\\\]*)*';
var $dillonkearns$elm_markdown$Markdown$InlineParser$refLabelRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\[\\s*(' + ($dillonkearns$elm_markdown$Markdown$Helpers$insideSquareBracketRegex + ')\\s*\\]')));
var $dillonkearns$elm_markdown$Markdown$Helpers$cleanWhitespaces = function (original) {
	return original;
};
var $dillonkearns$elm_markdown$Markdown$Helpers$prepareRefLabel = A2($elm$core$Basics$composeR, $dillonkearns$elm_markdown$Markdown$Helpers$cleanWhitespaces, $elm$core$String$toLower);
var $dillonkearns$elm_markdown$Markdown$InlineParser$refRegexToMatch = F3(
	function (matchModel, model, maybeRegexMatch) {
		var regexMatchLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.b3;
					},
					$elm$core$String$length),
				maybeRegexMatch));
		var toMatch = function (urlTitle) {
			return _Utils_update(
				matchModel,
				{
					j: matchModel.j + regexMatchLength,
					R: function () {
						var _v0 = matchModel.R;
						if (_v0.$ === 5) {
							return $dillonkearns$elm_markdown$Markdown$InlineParser$ImageType;
						} else {
							return $dillonkearns$elm_markdown$Markdown$InlineParser$LinkType;
						}
					}()(
						$dillonkearns$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle(urlTitle))
				});
		};
		var refLabel = function (str) {
			return $elm$core$String$isEmpty(str) ? matchModel.D : str;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				matchModel.D,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Maybe$Nothing,
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.ci;
								},
								$elm$core$List$head),
							maybeRegexMatch)))));
		var maybeRefItem = A2(
			$elm$core$Dict$get,
			$dillonkearns$elm_markdown$Markdown$Helpers$prepareRefLabel(refLabel),
			model.cb);
		return A2($elm$core$Maybe$map, toMatch, maybeRefItem);
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$checkForRefLinkTypeOrImageType = function (_v0) {
	var remainText = _v0.a;
	var tempMatch = _v0.b;
	var model = _v0.c;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple3(remainText, tempMatch, model),
		A2(
			$elm$core$Maybe$map,
			$dillonkearns$elm_markdown$Markdown$InlineParser$addMatch(model),
			A3(
				$dillonkearns$elm_markdown$Markdown$InlineParser$refRegexToMatch,
				tempMatch,
				model,
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $dillonkearns$elm_markdown$Markdown$InlineParser$refLabelRegex, remainText)))));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping = function (parser) {
	var _v0 = parser.c;
	if (!_v0.b) {
		return $elm$core$Result$Err(0);
	} else {
		var match = _v0.a;
		var remainMatches = _v0.b;
		var overlappingMatches = A2(
			$elm$core$List$filter,
			function (_v1) {
				var testMatch = _v1;
				return (_Utils_cmp(match.j, testMatch.r) > 0) && (_Utils_cmp(match.j, testMatch.j) < 0);
			},
			remainMatches);
		return ($elm$core$List$isEmpty(remainMatches) || $elm$core$List$isEmpty(overlappingMatches)) ? $elm$core$Result$Ok(parser) : $elm$core$Result$Err(0);
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$emailRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~\\-]+@[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?)*)$'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$emailAutolinkTypeToMatch = function (_v0) {
	var match = _v0;
	return A2($elm$regex$Regex$contains, $dillonkearns$elm_markdown$Markdown$InlineParser$emailRegex, match.D) ? $elm$core$Result$Ok(
		_Utils_update(
			match,
			{
				R: $dillonkearns$elm_markdown$Markdown$InlineParser$AutolinkType(
					_Utils_Tuple2(
						match.D,
						'mailto:' + $dillonkearns$elm_markdown$Markdown$InlineParser$encodeUrl(match.D)))
			})) : $elm$core$Result$Err(match);
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$filterTokens = F2(
	function (filter, model) {
		return _Utils_update(
			model,
			{
				i: A2($elm$core$List$filter, filter, model.i)
			});
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$findToken = F2(
	function (isToken, tokens) {
		var search = F2(
			function (token, _v2) {
				var maybeToken = _v2.a;
				var innerTokens = _v2.b;
				var remainTokens = _v2.c;
				if (maybeToken.$ === 1) {
					return isToken(token) ? _Utils_Tuple3(
						$elm$core$Maybe$Just(token),
						innerTokens,
						_List_Nil) : _Utils_Tuple3(
						$elm$core$Maybe$Nothing,
						A2($elm$core$List$cons, token, innerTokens),
						_List_Nil);
				} else {
					return _Utils_Tuple3(
						maybeToken,
						innerTokens,
						A2($elm$core$List$cons, token, remainTokens));
				}
			});
		var _return = function (_v0) {
			var maybeToken = _v0.a;
			var innerTokens = _v0.b;
			var remainTokens = _v0.c;
			return A2(
				$elm$core$Maybe$map,
				function (token) {
					return _Utils_Tuple3(
						token,
						$elm$core$List$reverse(innerTokens),
						$elm$core$List$reverse(remainTokens));
				},
				maybeToken);
		};
		return _return(
			A3(
				$elm$core$List$foldl,
				search,
				_Utils_Tuple3($elm$core$Maybe$Nothing, _List_Nil, _List_Nil),
				tokens));
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$HtmlToken = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$log = F2(
	function (label, value) {
		return value;
	});
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{cC: 1, f: _List_Nil, h: 1, b: 0, at: 1, cg: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$htmlFromRegex = F2(
	function (model, match) {
		var consumedCharacters = A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$keeper,
					$elm$parser$Parser$Advanced$succeed(
						F3(
							function (startOffset, htmlTag, endOffset) {
								return {cW: htmlTag, d: endOffset - startOffset};
							})),
					$elm$parser$Parser$Advanced$getOffset),
				$dillonkearns$elm_markdown$HtmlParser$html),
			$elm$parser$Parser$Advanced$getOffset);
		var parsed = A2(
			$elm$parser$Parser$Advanced$run,
			consumedCharacters,
			A2(
				$dillonkearns$elm_markdown$Markdown$InlineParser$log,
				'dropped',
				A2(
					$elm$core$String$dropLeft,
					match.r,
					A2($dillonkearns$elm_markdown$Markdown$InlineParser$log, 'rawText', model.G))));
		var _v0 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$log, 'match', match);
		if (!parsed.$) {
			var htmlTag = parsed.a.cW;
			var length = parsed.a.d;
			var htmlToken = A2($dillonkearns$elm_markdown$Markdown$InlineParser$HtmlToken, false, htmlTag);
			return $elm$core$Maybe$Just(
				A2(
					$dillonkearns$elm_markdown$Markdown$InlineParser$addToken,
					model,
					{aB: match.r, d: length, g: htmlToken}));
		} else {
			var error = parsed.a;
			var _v2 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$log, 'error', error);
			return $elm$core$Maybe$Nothing;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$htmlToToken = F2(
	function (model, _v0) {
		var match = _v0;
		return A2($dillonkearns$elm_markdown$Markdown$InlineParser$htmlFromRegex, model, match);
	});
var $dillonkearns$elm_markdown$Markdown$Helpers$ifError = F2(
	function (_function, result) {
		if (!result.$) {
			return result;
		} else {
			var err = result.a;
			return _function(err);
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$isCloseToken = F2(
	function (htmlModel, token) {
		return false;
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$isCodeTokenPair = F2(
	function (closeToken, openToken) {
		var _v0 = openToken.g;
		if (!_v0.$) {
			var isEscaped = _v0.a;
			return isEscaped ? _Utils_eq(openToken.d - 1, closeToken.d) : _Utils_eq(openToken.d, closeToken.d);
		} else {
			return false;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$isLinkTypeOrImageOpenToken = function (token) {
	var _v0 = token.g;
	switch (_v0.$) {
		case 1:
			return true;
		case 2:
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken = F2(
	function (closeToken, openToken) {
		var _v0 = openToken.g;
		if (_v0.$ === 6) {
			var openChar = _v0.a;
			var _v1 = _v0.b;
			var openLR = _v1.a;
			var openRR = _v1.b;
			var _v2 = closeToken.g;
			if (_v2.$ === 6) {
				var closeChar = _v2.a;
				var _v3 = _v2.b;
				var closeLR = _v3.a;
				var closeRR = _v3.b;
				return _Utils_eq(openChar, closeChar) ? ((_Utils_eq(openLR, openRR) || _Utils_eq(closeLR, closeRR)) ? (!(!A2($elm$core$Basics$modBy, 3, closeToken.d + openToken.d))) : true) : false;
			} else {
				return false;
			}
		} else {
			return false;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$isVoidTag = function (htmlModel) {
	return false;
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakType = {$: 1};
var $dillonkearns$elm_markdown$Markdown$InlineParser$SoftLineBreakToken = {$: 7};
var $dillonkearns$elm_markdown$Markdown$InlineParser$reverseTokens = function (model) {
	return _Utils_update(
		model,
		{
			i: $elm$core$List$reverse(model.i)
		});
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$tokenToMatch = F2(
	function (token, type_) {
		return {j: token.aB + token.d, c: _List_Nil, r: token.aB, D: '', aE: 0, ae: 0, R: type_};
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$lineBreakTTM = function (_v0) {
	lineBreakTTM:
	while (true) {
		var tokens = _v0.a;
		var model = _v0.b;
		if (!tokens.b) {
			return $dillonkearns$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			if (_Utils_eq(token.g, $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken) || (_Utils_eq(token.g, $dillonkearns$elm_markdown$Markdown$InlineParser$SoftLineBreakToken) && $dillonkearns$elm_markdown$Markdown$InlineParser$softAsHardLineBreak)) {
				return $dillonkearns$elm_markdown$Markdown$InlineParser$lineBreakTTM(
					function (b) {
						return _Utils_Tuple2(tokensTail, b);
					}(
						_Utils_update(
							model,
							{
								c: A2(
									$elm$core$List$cons,
									A2($dillonkearns$elm_markdown$Markdown$InlineParser$tokenToMatch, token, $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakType),
									model.c)
							})));
			} else {
				var $temp$_v0 = _Utils_Tuple2(
					tokensTail,
					A2($dillonkearns$elm_markdown$Markdown$InlineParser$addToken, model, token));
				_v0 = $temp$_v0;
				continue lineBreakTTM;
			}
		}
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens = F2(
	function (tokensTail, parser) {
		var _v0 = parser.c;
		if (!_v0.b) {
			return _Utils_Tuple2(tokensTail, parser);
		} else {
			var match = _v0.a;
			return _Utils_Tuple2(
				A2(
					$elm$core$List$filter,
					function (token) {
						return _Utils_cmp(token.aB, match.j) > -1;
					},
					tokensTail),
				parser);
		}
	});
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketsToMatch = F4(
	function (closeToken, isEscaped, model, _v24) {
		var openToken = _v24.a;
		var remainTokens = _v24.c;
		return function (result) {
			if (result.$ === 1) {
				var tempMatch = result.a;
				return (!isEscaped) ? A2(
					$dillonkearns$elm_markdown$Markdown$InlineParser$htmlToToken,
					_Utils_update(
						model,
						{i: remainTokens}),
					tempMatch) : $elm$core$Result$toMaybe(result);
			} else {
				return $elm$core$Result$toMaybe(result);
			}
		}(
			A2(
				$elm$core$Result$map,
				function (newMatch) {
					return _Utils_update(
						model,
						{
							c: A2($elm$core$List$cons, newMatch, model.c),
							i: remainTokens
						});
				},
				A2(
					$dillonkearns$elm_markdown$Markdown$Helpers$ifError,
					$dillonkearns$elm_markdown$Markdown$InlineParser$emailAutolinkTypeToMatch,
					$dillonkearns$elm_markdown$Markdown$InlineParser$autolinkToMatch(
						A6(
							$dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
							model,
							function (s) {
								return s;
							},
							$dillonkearns$elm_markdown$Markdown$InlineParser$CodeType,
							openToken,
							closeToken,
							_List_Nil)))));
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM = function (_v21) {
	codeAutolinkTypeHtmlTagTTM:
	while (true) {
		var tokens = _v21.a;
		var model = _v21.b;
		if (!tokens.b) {
			return $dillonkearns$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			var _v23 = token.g;
			switch (_v23.$) {
				case 0:
					var isEscaped = _v23.a;
					return $dillonkearns$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM(
						function (b) {
							return _Utils_Tuple2(tokensTail, b);
						}(
							A2(
								$elm$core$Maybe$withDefault,
								A2($dillonkearns$elm_markdown$Markdown$InlineParser$addToken, model, token),
								A2(
									$elm$core$Maybe$map,
									A2($dillonkearns$elm_markdown$Markdown$InlineParser$codeToMatch, token, model),
									A2(
										$dillonkearns$elm_markdown$Markdown$InlineParser$findToken,
										$dillonkearns$elm_markdown$Markdown$InlineParser$isCodeTokenPair(token),
										model.i)))));
				case 4:
					var isEscaped = _v23.a;
					return $dillonkearns$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM(
						function (b) {
							return _Utils_Tuple2(tokensTail, b);
						}(
							A2(
								$dillonkearns$elm_markdown$Markdown$InlineParser$filterTokens,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.g;
									},
									$elm$core$Basics$neq(
										$dillonkearns$elm_markdown$Markdown$InlineParser$CharToken('<'))),
								A2(
									$elm$core$Maybe$withDefault,
									model,
									A2(
										$elm$core$Maybe$andThen,
										A3($dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketsToMatch, token, isEscaped, model),
										A2(
											$dillonkearns$elm_markdown$Markdown$InlineParser$findToken,
											A2(
												$elm$core$Basics$composeR,
												function ($) {
													return $.g;
												},
												$elm$core$Basics$eq(
													$dillonkearns$elm_markdown$Markdown$InlineParser$CharToken('<'))),
											model.i))))));
				default:
					var $temp$_v21 = _Utils_Tuple2(
						tokensTail,
						A2($dillonkearns$elm_markdown$Markdown$InlineParser$addToken, model, token));
					_v21 = $temp$_v21;
					continue codeAutolinkTypeHtmlTagTTM;
			}
		}
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$codeToMatch = F3(
	function (closeToken, model, _v20) {
		var openToken = _v20.a;
		var remainTokens = _v20.c;
		var updtOpenToken = _Utils_eq(
			openToken.g,
			$dillonkearns$elm_markdown$Markdown$InlineParser$CodeToken(true)) ? _Utils_update(
			openToken,
			{aB: openToken.aB + 1, d: openToken.d - 1}) : openToken;
		return _Utils_update(
			model,
			{
				c: A2(
					$elm$core$List$cons,
					A6($dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch, model, $dillonkearns$elm_markdown$Markdown$Helpers$cleanWhitespaces, $dillonkearns$elm_markdown$Markdown$InlineParser$CodeType, updtOpenToken, closeToken, _List_Nil),
					model.c),
				i: remainTokens
			});
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$emphasisTTM = function (_v16) {
	emphasisTTM:
	while (true) {
		var tokens = _v16.a;
		var model = _v16.b;
		if (!tokens.b) {
			return $dillonkearns$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			var _v18 = token.g;
			if (_v18.$ === 6) {
				var _char = _v18.a;
				var _v19 = _v18.b;
				var leftRank = _v19.a;
				var rightRank = _v19.b;
				if (_Utils_eq(leftRank, rightRank)) {
					if ((!(!rightRank)) && ((_char !== '_') || (rightRank === 1))) {
						return $dillonkearns$elm_markdown$Markdown$InlineParser$emphasisTTM(
							A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(
									tokensTail,
									A2($dillonkearns$elm_markdown$Markdown$InlineParser$addToken, model, token)),
								A2(
									$elm$core$Maybe$map,
									A3($dillonkearns$elm_markdown$Markdown$InlineParser$emphasisToMatch, token, tokensTail, model),
									A2(
										$dillonkearns$elm_markdown$Markdown$InlineParser$findToken,
										$dillonkearns$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken(token),
										model.i))));
					} else {
						var $temp$_v16 = _Utils_Tuple2(tokensTail, model);
						_v16 = $temp$_v16;
						continue emphasisTTM;
					}
				} else {
					if (_Utils_cmp(leftRank, rightRank) < 0) {
						var $temp$_v16 = _Utils_Tuple2(
							tokensTail,
							A2($dillonkearns$elm_markdown$Markdown$InlineParser$addToken, model, token));
						_v16 = $temp$_v16;
						continue emphasisTTM;
					} else {
						return $dillonkearns$elm_markdown$Markdown$InlineParser$emphasisTTM(
							A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(tokensTail, model),
								A2(
									$elm$core$Maybe$map,
									A3($dillonkearns$elm_markdown$Markdown$InlineParser$emphasisToMatch, token, tokensTail, model),
									A2(
										$dillonkearns$elm_markdown$Markdown$InlineParser$findToken,
										$dillonkearns$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken(token),
										model.i))));
					}
				}
			} else {
				var $temp$_v16 = _Utils_Tuple2(
					tokensTail,
					A2($dillonkearns$elm_markdown$Markdown$InlineParser$addToken, model, token));
				_v16 = $temp$_v16;
				continue emphasisTTM;
			}
		}
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$emphasisToMatch = F4(
	function (closeToken, tokensTail, model, _v15) {
		var openToken = _v15.a;
		var innerTokens = _v15.b;
		var remainTokens = _v15.c;
		var remainLength = openToken.d - closeToken.d;
		var updt = (!remainLength) ? {a6: closeToken, aP: openToken, bs: remainTokens, bz: tokensTail} : ((remainLength > 0) ? {
			a6: closeToken,
			aP: _Utils_update(
				openToken,
				{aB: openToken.aB + remainLength, d: closeToken.d}),
			bs: A2(
				$elm$core$List$cons,
				_Utils_update(
					openToken,
					{d: remainLength}),
				remainTokens),
			bz: tokensTail
		} : {
			a6: _Utils_update(
				closeToken,
				{d: openToken.d}),
			aP: openToken,
			bs: remainTokens,
			bz: A2(
				$elm$core$List$cons,
				_Utils_update(
					closeToken,
					{aB: closeToken.aB + openToken.d, d: -remainLength}),
				tokensTail)
		});
		var match = A6(
			$dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
			model,
			function (s) {
				return s;
			},
			$dillonkearns$elm_markdown$Markdown$InlineParser$EmphasisType(updt.aP.d),
			updt.aP,
			updt.a6,
			$elm$core$List$reverse(innerTokens));
		return _Utils_Tuple2(
			updt.bz,
			_Utils_update(
				model,
				{
					c: A2($elm$core$List$cons, match, model.c),
					i: updt.bs
				}));
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$htmlElementTTM = function (_v12) {
	htmlElementTTM:
	while (true) {
		var tokens = _v12.a;
		var model = _v12.b;
		if (!tokens.b) {
			return $dillonkearns$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			var _v14 = token.g;
			if (_v14.$ === 5) {
				var isOpen = _v14.a;
				var htmlModel = _v14.b;
				return ($dillonkearns$elm_markdown$Markdown$InlineParser$isVoidTag(htmlModel) || (!isOpen)) ? $dillonkearns$elm_markdown$Markdown$InlineParser$htmlElementTTM(
					function (b) {
						return _Utils_Tuple2(tokensTail, b);
					}(
						A2(
							$dillonkearns$elm_markdown$Markdown$InlineParser$addMatch,
							model,
							A2(
								$dillonkearns$elm_markdown$Markdown$InlineParser$tokenToMatch,
								token,
								$dillonkearns$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel))))) : $dillonkearns$elm_markdown$Markdown$InlineParser$htmlElementTTM(
					A2(
						$elm$core$Maybe$withDefault,
						function (b) {
							return _Utils_Tuple2(tokensTail, b);
						}(
							A2(
								$dillonkearns$elm_markdown$Markdown$InlineParser$addMatch,
								model,
								A2(
									$dillonkearns$elm_markdown$Markdown$InlineParser$tokenToMatch,
									token,
									$dillonkearns$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel)))),
						A2(
							$elm$core$Maybe$map,
							A3($dillonkearns$elm_markdown$Markdown$InlineParser$htmlElementToMatch, token, model, htmlModel),
							A2(
								$dillonkearns$elm_markdown$Markdown$InlineParser$findToken,
								$dillonkearns$elm_markdown$Markdown$InlineParser$isCloseToken(htmlModel),
								tokensTail))));
			} else {
				var $temp$_v12 = _Utils_Tuple2(
					tokensTail,
					A2($dillonkearns$elm_markdown$Markdown$InlineParser$addToken, model, token));
				_v12 = $temp$_v12;
				continue htmlElementTTM;
			}
		}
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$htmlElementToMatch = F4(
	function (openToken, model, htmlModel, _v11) {
		var closeToken = _v11.a;
		var innerTokens = _v11.b;
		var remainTokens = _v11.c;
		return _Utils_Tuple2(
			remainTokens,
			_Utils_update(
				model,
				{
					c: A2(
						$elm$core$List$cons,
						A6(
							$dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
							model,
							function (s) {
								return s;
							},
							$dillonkearns$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel),
							openToken,
							closeToken,
							innerTokens),
						model.c)
				}));
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageTypeTTM = function (_v8) {
	linkImageTypeTTM:
	while (true) {
		var tokens = _v8.a;
		var model = _v8.b;
		if (!tokens.b) {
			return $dillonkearns$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			var _v10 = token.g;
			if ((_v10.$ === 3) && (']' === _v10.a)) {
				return $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageTypeTTM(
					A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(tokensTail, model),
						A2(
							$elm$core$Maybe$andThen,
							A3($dillonkearns$elm_markdown$Markdown$InlineParser$linkOrImageTypeToMatch, token, tokensTail, model),
							A2($dillonkearns$elm_markdown$Markdown$InlineParser$findToken, $dillonkearns$elm_markdown$Markdown$InlineParser$isLinkTypeOrImageOpenToken, model.i))));
			} else {
				var $temp$_v8 = _Utils_Tuple2(
					tokensTail,
					A2($dillonkearns$elm_markdown$Markdown$InlineParser$addToken, model, token));
				_v8 = $temp$_v8;
				continue linkImageTypeTTM;
			}
		}
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$linkOrImageTypeToMatch = F4(
	function (closeToken, tokensTail, model, _v1) {
		var openToken = _v1.a;
		var innerTokens = _v1.b;
		var remainTokens = _v1.c;
		var tempMatch = function (isLinkType) {
			return A6(
				$dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
				model,
				function (s) {
					return s;
				},
				isLinkType ? $dillonkearns$elm_markdown$Markdown$InlineParser$LinkType(
					_Utils_Tuple2('', $elm$core$Maybe$Nothing)) : $dillonkearns$elm_markdown$Markdown$InlineParser$ImageType(
					_Utils_Tuple2('', $elm$core$Maybe$Nothing)),
				openToken,
				closeToken,
				$elm$core$List$reverse(innerTokens));
		};
		var removeOpenToken = _Utils_Tuple2(
			tokensTail,
			_Utils_update(
				model,
				{
					i: _Utils_ap(innerTokens, remainTokens)
				}));
		var remainText = A2($elm$core$String$dropLeft, closeToken.aB + 1, model.G);
		var linkOpenTokenToInactive = function (model_) {
			var process = function (token) {
				var _v7 = token.g;
				if (_v7.$ === 1) {
					return _Utils_update(
						token,
						{
							g: $dillonkearns$elm_markdown$Markdown$InlineParser$LinkOpenToken(false)
						});
				} else {
					return token;
				}
			};
			return _Utils_update(
				model_,
				{
					i: A2($elm$core$List$map, process, model_.i)
				});
		};
		var args = function (isLinkType) {
			return _Utils_Tuple3(
				remainText,
				tempMatch(isLinkType),
				_Utils_update(
					model,
					{i: remainTokens}));
		};
		var _v2 = openToken.g;
		switch (_v2.$) {
			case 2:
				return $elm$core$Result$toMaybe(
					A2(
						$dillonkearns$elm_markdown$Markdown$Helpers$ifError,
						function (_v4) {
							return $elm$core$Result$Ok(removeOpenToken);
						},
						A2(
							$elm$core$Result$map,
							$dillonkearns$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens(tokensTail),
							A2(
								$elm$core$Result$andThen,
								$dillonkearns$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping,
								A2(
									$elm$core$Result$mapError,
									function (_v3) {
										return 0;
									},
									A2(
										$dillonkearns$elm_markdown$Markdown$Helpers$ifError,
										$dillonkearns$elm_markdown$Markdown$InlineParser$checkForRefLinkTypeOrImageType,
										$dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType(
											args(false))))))));
			case 1:
				if (_v2.a) {
					return $elm$core$Result$toMaybe(
						A2(
							$dillonkearns$elm_markdown$Markdown$Helpers$ifError,
							function (_v6) {
								return $elm$core$Result$Ok(removeOpenToken);
							},
							A2(
								$elm$core$Result$map,
								$dillonkearns$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens(tokensTail),
								A2(
									$elm$core$Result$map,
									linkOpenTokenToInactive,
									A2(
										$elm$core$Result$andThen,
										$dillonkearns$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping,
										A2(
											$elm$core$Result$mapError,
											function (_v5) {
												return 0;
											},
											A2(
												$dillonkearns$elm_markdown$Markdown$Helpers$ifError,
												$dillonkearns$elm_markdown$Markdown$InlineParser$checkForRefLinkTypeOrImageType,
												$dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType(
													args(true)))))))));
				} else {
					return $elm$core$Maybe$Just(removeOpenToken);
				}
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch = F6(
	function (model, processText, type_, openToken, closeToken, innerTokens) {
		var textStart = openToken.aB + openToken.d;
		var textEnd = closeToken.aB;
		var start = openToken.aB;
		var end = closeToken.aB + closeToken.d;
		var match = {
			j: end,
			c: _List_Nil,
			r: start,
			D: processText(
				A3($elm$core$String$slice, textStart, textEnd, model.G)),
			aE: textEnd,
			ae: textStart,
			R: type_
		};
		var matches = A2(
			$elm$core$List$map,
			function (_v0) {
				var matchModel = _v0;
				return A2($dillonkearns$elm_markdown$Markdown$InlineParser$prepareChildMatch, match, matchModel);
			},
			$dillonkearns$elm_markdown$Markdown$InlineParser$cyclic$tokensToMatches()(
				_Utils_update(
					model,
					{c: _List_Nil, i: innerTokens})).c);
		return _Utils_update(
			match,
			{c: matches});
	});
function $dillonkearns$elm_markdown$Markdown$InlineParser$cyclic$tokensToMatches() {
	return A2(
		$elm$core$Basics$composeR,
		$dillonkearns$elm_markdown$Markdown$InlineParser$applyTTM($dillonkearns$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM),
		A2(
			$elm$core$Basics$composeR,
			$dillonkearns$elm_markdown$Markdown$InlineParser$applyTTM($dillonkearns$elm_markdown$Markdown$InlineParser$htmlElementTTM),
			A2(
				$elm$core$Basics$composeR,
				$dillonkearns$elm_markdown$Markdown$InlineParser$applyTTM($dillonkearns$elm_markdown$Markdown$InlineParser$linkImageTypeTTM),
				A2(
					$elm$core$Basics$composeR,
					$dillonkearns$elm_markdown$Markdown$InlineParser$applyTTM($dillonkearns$elm_markdown$Markdown$InlineParser$emphasisTTM),
					$dillonkearns$elm_markdown$Markdown$InlineParser$applyTTM($dillonkearns$elm_markdown$Markdown$InlineParser$lineBreakTTM)))));
}
var $dillonkearns$elm_markdown$Markdown$InlineParser$tokensToMatches = $dillonkearns$elm_markdown$Markdown$InlineParser$cyclic$tokensToMatches();
$dillonkearns$elm_markdown$Markdown$InlineParser$cyclic$tokensToMatches = function () {
	return $dillonkearns$elm_markdown$Markdown$InlineParser$tokensToMatches;
};
var $elm$core$String$trim = _String_trim;
var $dillonkearns$elm_markdown$Markdown$InlineParser$parse = F2(
	function (refs, rawText) {
		return $dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines(
			$dillonkearns$elm_markdown$Markdown$InlineParser$parseText(
				$dillonkearns$elm_markdown$Markdown$InlineParser$organizeParserMatches(
					$dillonkearns$elm_markdown$Markdown$InlineParser$tokensToMatches(
						$dillonkearns$elm_markdown$Markdown$InlineParser$tokenize(
							A2(
								$dillonkearns$elm_markdown$Markdown$InlineParser$initParser,
								refs,
								$elm$core$String$trim(rawText)))))).c);
	});
var $dillonkearns$elm_markdown$Markdown$Parser$endOfLineOrFile = A2(
	$elm$parser$Parser$Advanced$ignorer,
	$elm$parser$Parser$Advanced$chompUntilEndOr('\n'),
	$elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'\n',
					$elm$parser$Parser$ExpectingSymbol('\\n'))),
				$elm$parser$Parser$Advanced$end(
				$elm$parser$Parser$Expecting('End of input'))
			])));
var $dillonkearns$elm_markdown$Markdown$Parser$thisIsDefinitelyNotAnHtmlTag = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$elm$parser$Parser$Advanced$token(
			A2(
				$elm$parser$Parser$Advanced$Token,
				' ',
				$elm$parser$Parser$Expecting(' '))),
			$elm$parser$Parser$Advanced$token(
			A2(
				$elm$parser$Parser$Advanced$Token,
				'>',
				$elm$parser$Parser$Expecting('>'))),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$chompIf,
					$elm$core$Char$isAlpha,
					$elm$parser$Parser$Expecting('Alpha')),
				$elm$parser$Parser$Advanced$chompWhile(
					function (c) {
						return $elm$core$Char$isAlphaNum(c) || (c === '-');
					})),
			$elm$parser$Parser$Advanced$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							':',
							$elm$parser$Parser$Expecting(':'))),
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'@',
							$elm$parser$Parser$Expecting('@'))),
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'\\',
							$elm$parser$Parser$Expecting('\\'))),
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'+',
							$elm$parser$Parser$Expecting('+'))),
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'.',
							$elm$parser$Parser$Expecting('.')))
					])))
		]));
var $dillonkearns$elm_markdown$Markdown$Parser$parseAsParagraphInsteadOfHtmlBlock = $elm$parser$Parser$Advanced$backtrackable(
	A2(
		$elm$parser$Parser$Advanced$map,
		function (rawLine) {
			return $dillonkearns$elm_markdown$Markdown$RawBlock$Body(rawLine);
		},
		$elm$parser$Parser$Advanced$getChompedString(
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed(0),
						$elm$parser$Parser$Advanced$token(
							A2(
								$elm$parser$Parser$Advanced$Token,
								'<',
								$elm$parser$Parser$Expecting('<')))),
					$dillonkearns$elm_markdown$Markdown$Parser$thisIsDefinitelyNotAnHtmlTag),
				$dillonkearns$elm_markdown$Markdown$Parser$endOfLineOrFile))));
var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$findSubString, str, s.b, s.at, s.cC, s.cg);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A4($elm$parser$Parser$Advanced$fromInfo, newRow, newCol, expecting, s.f)) : A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.b, newOffset) < 0,
			0,
			{cC: newCol, f: s.f, h: s.h, b: newOffset, at: newRow, cg: s.cg});
	};
};
var $dillonkearns$elm_markdown$Helpers$isEmptyString = function (string) {
	if (string === '') {
		return true;
	} else {
		return false;
	}
};
var $dillonkearns$elm_markdown$Markdown$CodeBlock$parserHelp = function (delimeter) {
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed(
					F2(
						function (language, body) {
							return {
								d2: body,
								c7: $dillonkearns$elm_markdown$Helpers$isEmptyString(language) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(language)
							};
						})),
				$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						delimeter,
						$elm$parser$Parser$ExpectingSymbol(delimeter)))),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$getChompedString(
					$elm$parser$Parser$Advanced$chompUntil(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'\n',
							$elm$parser$Parser$Problem('Expecting newline')))),
				$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'\n',
						$elm$parser$Parser$ExpectingSymbol('\n'))))),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$getChompedString(
				$elm$parser$Parser$Advanced$chompUntilEndOr('\n' + delimeter)),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'\n' + delimeter,
					$elm$parser$Parser$ExpectingSymbol(delimeter)))));
};
var $dillonkearns$elm_markdown$Markdown$CodeBlock$parser = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$dillonkearns$elm_markdown$Markdown$CodeBlock$parserHelp('```'),
			$dillonkearns$elm_markdown$Markdown$CodeBlock$parserHelp('~~~')
		]));
var $dillonkearns$elm_markdown$Helpers$isGfmWhitespace = function (_char) {
	switch (_char) {
		case ' ':
			return true;
		case '\n':
			return true;
		case '\t':
			return true;
		case '\u000B':
			return true;
		case '\u000C':
			return true;
		case '\u000D':
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$destinationParser = A2(
	$elm$parser$Parser$Advanced$inContext,
	'link destination',
	$elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$url$Url$percentEncode),
					$elm$parser$Parser$Advanced$token(
						$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$toToken('<'))),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$getChompedString(
						$elm$parser$Parser$Advanced$chompUntilEndOr('>')),
					$elm$parser$Parser$Advanced$token(
						$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$toToken('>')))),
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$keeper,
					$elm$parser$Parser$Advanced$succeed(
						F2(
							function (first, second) {
								return _Utils_ap(first, second);
							})),
					$elm$parser$Parser$Advanced$getChompedString(
						A2(
							$elm$parser$Parser$Advanced$chompIf,
							function (c) {
								return !$dillonkearns$elm_markdown$Helpers$isGfmWhitespace(c);
							},
							$elm$parser$Parser$Expecting('non-whitespace character')))),
				$elm$parser$Parser$Advanced$getChompedString(
					$elm$parser$Parser$Advanced$chompWhile(
						function (c) {
							return !$dillonkearns$elm_markdown$Helpers$isGfmWhitespace(c);
						})))
			])));
var $dillonkearns$elm_markdown$LineEnding$CanHaveLineEnding = 0;
var $dillonkearns$elm_markdown$LineEnding$CannotHaveLineEnding = 1;
var $dillonkearns$elm_markdown$LineEnding$carriageReturn = '\u000D';
var $dillonkearns$elm_markdown$LineEnding$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $dillonkearns$elm_markdown$LineEnding$lineEnding = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$elm$parser$Parser$Advanced$token(
			$dillonkearns$elm_markdown$LineEnding$toToken('\n')),
			$elm$parser$Parser$Advanced$token(
			$dillonkearns$elm_markdown$LineEnding$toToken($dillonkearns$elm_markdown$LineEnding$carriageReturn + '\n')),
			$elm$parser$Parser$Advanced$token(
			$dillonkearns$elm_markdown$LineEnding$toToken($dillonkearns$elm_markdown$LineEnding$carriageReturn))
		]));
var $dillonkearns$elm_markdown$LineEnding$statementsHelp = function (state) {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$andThen,
				function (_v0) {
					if (!state) {
						return $elm$parser$Parser$Advanced$succeed(
							$elm$parser$Parser$Advanced$Loop(1));
					} else {
						return $elm$parser$Parser$Advanced$problem(
							$elm$parser$Parser$Problem('Can\'t have more than one line ending character.'));
					}
				},
				$dillonkearns$elm_markdown$LineEnding$lineEnding),
				A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed(
					$elm$parser$Parser$Advanced$Loop(state)),
				A2(
					$elm$parser$Parser$Advanced$chompIf,
					$dillonkearns$elm_markdown$Helpers$isGfmWhitespace,
					$elm$parser$Parser$Expecting('Whitespace'))),
				$elm$parser$Parser$Advanced$succeed(
				$elm$parser$Parser$Advanced$Done(0))
			]));
};
var $dillonkearns$elm_markdown$LineEnding$optionalWhitespaceUpToOneLineEnding = A2($elm$parser$Parser$Advanced$loop, 0, $dillonkearns$elm_markdown$LineEnding$statementsHelp);
var $dillonkearns$elm_markdown$Helpers$requiredWhitespace = A2(
	$elm$parser$Parser$Advanced$ignorer,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed(0),
		A2(
			$elm$parser$Parser$Advanced$chompIf,
			$dillonkearns$elm_markdown$Helpers$isGfmWhitespace,
			$elm$parser$Parser$Expecting('whitespace'))),
	$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Helpers$isGfmWhitespace));
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$titleParser = A2(
	$elm$parser$Parser$Advanced$inContext,
	'title',
	$elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
					$dillonkearns$elm_markdown$Helpers$requiredWhitespace),
				$elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$Advanced$keeper,
							A2(
								$elm$parser$Parser$Advanced$ignorer,
								$elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Just),
								$elm$parser$Parser$Advanced$token(
									$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$toToken('\"'))),
							A2(
								$elm$parser$Parser$Advanced$ignorer,
								$elm$parser$Parser$Advanced$getChompedString(
									$elm$parser$Parser$Advanced$chompUntilEndOr('\"')),
								$elm$parser$Parser$Advanced$token(
									$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$toToken('\"')))),
							A2(
							$elm$parser$Parser$Advanced$keeper,
							A2(
								$elm$parser$Parser$Advanced$ignorer,
								$elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Just),
								$elm$parser$Parser$Advanced$token(
									$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$toToken('\''))),
							A2(
								$elm$parser$Parser$Advanced$ignorer,
								$elm$parser$Parser$Advanced$getChompedString(
									$elm$parser$Parser$Advanced$chompUntilEndOr('\'')),
								$elm$parser$Parser$Advanced$token(
									$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$toToken('\'')))),
							$elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Nothing)
						]))),
				$elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Nothing)
			])));
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$parser = A2(
	$elm$parser$Parser$Advanced$inContext,
	'link reference definition',
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed(
						F3(
							function (label, destination, title) {
								return _Utils_Tuple2(
									$dillonkearns$elm_markdown$Markdown$Helpers$prepareRefLabel(label),
									{em: destination, fr: title});
							})),
					$elm$parser$Parser$Advanced$token(
						$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$toToken('['))),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$getChompedString(
							$elm$parser$Parser$Advanced$chompUntilEndOr(']')),
						$elm$parser$Parser$Advanced$token(
							$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$toToken(']:'))),
					$dillonkearns$elm_markdown$LineEnding$optionalWhitespaceUpToOneLineEnding)),
			$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$destinationParser),
		$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$titleParser));
var $dillonkearns$elm_markdown$ThematicBreak$NoMatchYet = {$: 3};
var $dillonkearns$elm_markdown$ThematicBreak$Asterisk = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$ThematicBreak$Dash = 1;
var $dillonkearns$elm_markdown$ThematicBreak$Finished = 3;
var $dillonkearns$elm_markdown$ThematicBreak$Hyphen = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$ThematicBreak$Star = 0;
var $dillonkearns$elm_markdown$ThematicBreak$TokenUnderscore = 2;
var $dillonkearns$elm_markdown$ThematicBreak$Underscore = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$ThematicBreak$Whitespace = 4;
var $dillonkearns$elm_markdown$ThematicBreak$ThematicBreak = 0;
var $dillonkearns$elm_markdown$ThematicBreak$succeedIfEnough = function (occurences) {
	return (occurences > 2) ? $elm$parser$Parser$Advanced$succeed(
		$elm$parser$Parser$Advanced$Done(0)) : $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Expecting('...?'));
};
var $elm$core$Debug$toString = _Debug_toString;
var $dillonkearns$elm_markdown$Parser$Extra$tokenHelp = function (_char) {
	return $elm$parser$Parser$Advanced$token(
		A2(
			$elm$parser$Parser$Advanced$Token,
			_char,
			$elm$parser$Parser$Expecting(_char)));
};
var $dillonkearns$elm_markdown$ThematicBreak$statementsHelp = function (state) {
	return A2(
		$elm$parser$Parser$Advanced$andThen,
		function (thematicToken) {
			var _v6 = _Utils_Tuple2(thematicToken, state);
			_v6$11:
			while (true) {
				switch (_v6.a) {
					case 3:
						switch (_v6.b.$) {
							case 3:
								var _v7 = _v6.a;
								var _v8 = _v6.b;
								return $elm$parser$Parser$Advanced$problem(
									$elm$parser$Parser$Expecting('TODO'));
							case 0:
								var _v9 = _v6.a;
								var occurrences = _v6.b.a;
								return $dillonkearns$elm_markdown$ThematicBreak$succeedIfEnough(occurrences);
							case 1:
								var _v10 = _v6.a;
								var occurrences = _v6.b.a;
								return $dillonkearns$elm_markdown$ThematicBreak$succeedIfEnough(occurrences);
							default:
								var _v11 = _v6.a;
								var occurrences = _v6.b.a;
								return $dillonkearns$elm_markdown$ThematicBreak$succeedIfEnough(occurrences);
						}
					case 2:
						switch (_v6.b.$) {
							case 2:
								var _v14 = _v6.a;
								var occurrences = _v6.b.a;
								return $elm$parser$Parser$Advanced$succeed(
									$elm$parser$Parser$Advanced$Loop(
										$dillonkearns$elm_markdown$ThematicBreak$Underscore(occurrences + 1)));
							case 3:
								var _v15 = _v6.a;
								var _v16 = _v6.b;
								return $elm$parser$Parser$Advanced$succeed(
									$elm$parser$Parser$Advanced$Loop(
										$dillonkearns$elm_markdown$ThematicBreak$Underscore(1)));
							default:
								break _v6$11;
						}
					case 0:
						switch (_v6.b.$) {
							case 0:
								var _v12 = _v6.a;
								var occurrences = _v6.b.a;
								return $elm$parser$Parser$Advanced$succeed(
									$elm$parser$Parser$Advanced$Loop(
										$dillonkearns$elm_markdown$ThematicBreak$Asterisk(occurrences + 1)));
							case 3:
								var _v17 = _v6.a;
								var _v18 = _v6.b;
								return $elm$parser$Parser$Advanced$succeed(
									$elm$parser$Parser$Advanced$Loop(
										$dillonkearns$elm_markdown$ThematicBreak$Asterisk(1)));
							default:
								break _v6$11;
						}
					case 1:
						switch (_v6.b.$) {
							case 1:
								var _v13 = _v6.a;
								var occurrences = _v6.b.a;
								return $elm$parser$Parser$Advanced$succeed(
									$elm$parser$Parser$Advanced$Loop(
										$dillonkearns$elm_markdown$ThematicBreak$Hyphen(occurrences + 1)));
							case 3:
								var _v19 = _v6.a;
								var _v20 = _v6.b;
								return $elm$parser$Parser$Advanced$succeed(
									$elm$parser$Parser$Advanced$Loop(
										$dillonkearns$elm_markdown$ThematicBreak$Hyphen(1)));
							default:
								break _v6$11;
						}
					default:
						var _v21 = _v6.a;
						return $elm$parser$Parser$Advanced$succeed(
							$elm$parser$Parser$Advanced$Loop(state));
				}
			}
			return $elm$parser$Parser$Advanced$problem(
				$elm$parser$Parser$Expecting(
					$elm$core$Debug$toString(state)));
		},
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$map,
					function (_v0) {
						return 1;
					},
					$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('-')),
					A2(
					$elm$parser$Parser$Advanced$map,
					function (_v1) {
						return 0;
					},
					$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('*')),
					A2(
					$elm$parser$Parser$Advanced$map,
					function (_v2) {
						return 2;
					},
					$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('_')),
					A2(
					$elm$parser$Parser$Advanced$map,
					function (_v3) {
						return 3;
					},
					$elm$parser$Parser$Advanced$end(
						$elm$parser$Parser$Expecting('end'))),
					A2(
					$elm$parser$Parser$Advanced$map,
					function (_v4) {
						return 3;
					},
					$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('\n')),
					A2(
					$elm$parser$Parser$Advanced$map,
					function (_v5) {
						return 4;
					},
					A2(
						$elm$parser$Parser$Advanced$chompIf,
						function (c) {
							return c === ' ';
						},
						$elm$parser$Parser$Expecting('Space')))
				])));
};
var $dillonkearns$elm_markdown$ThematicBreak$parser = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$Advanced$chompIf,
							function (c) {
								return c === ' ';
							},
							$elm$parser$Parser$Expecting('Space')),
							$elm$parser$Parser$Advanced$succeed(0)
						]))),
			$elm$parser$Parser$Advanced$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$Advanced$chompIf,
						function (c) {
							return c === ' ';
						},
						$elm$parser$Parser$Expecting('Space')),
						$elm$parser$Parser$Advanced$succeed(0)
					]))),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$chompIf,
					function (c) {
						return c === ' ';
					},
					$elm$parser$Parser$Expecting('Space')),
					$elm$parser$Parser$Advanced$succeed(0)
				]))),
	A2($elm$parser$Parser$Advanced$loop, $dillonkearns$elm_markdown$ThematicBreak$NoMatchYet, $dillonkearns$elm_markdown$ThematicBreak$statementsHelp));
var $dillonkearns$elm_markdown$Markdown$Parser$innerParagraphParser = $elm$parser$Parser$Advanced$getChompedString(
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(0),
			A2(
				$elm$parser$Parser$Advanced$chompIf,
				function (c) {
					return !$dillonkearns$elm_markdown$Helpers$isNewline(c);
				},
				$elm$parser$Parser$Expecting('Not newline.'))),
		$elm$parser$Parser$Advanced$chompUntilEndOr('\n')));
var $dillonkearns$elm_markdown$Markdown$Parser$plainLine = A2(
	$elm$parser$Parser$Advanced$keeper,
	$elm$parser$Parser$Advanced$succeed(
		function (rawLine) {
			return $dillonkearns$elm_markdown$Markdown$RawBlock$Body(rawLine);
		}),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$dillonkearns$elm_markdown$Markdown$Parser$innerParagraphParser,
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$chompIf,
					$dillonkearns$elm_markdown$Helpers$isNewline,
					$elm$parser$Parser$Expecting('A single non-newline char.')),
					$elm$parser$Parser$Advanced$end(
					$elm$parser$Parser$Expecting('End'))
				]))));
var $dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_markdown$Markdown$UnorderedList$listMarkerParser = function () {
	var markerOption = function (marker) {
		return $elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					marker,
					$elm$parser$Parser$ExpectingSymbol(marker))));
	};
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				markerOption('-'),
				markerOption('+'),
				markerOption('*')
			]));
}();
var $dillonkearns$elm_markdown$Markdown$ListItem$PlainItem = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$ListItem$TaskItem = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$ListItem$Complete = 1;
var $dillonkearns$elm_markdown$Markdown$ListItem$Incomplete = 0;
var $dillonkearns$elm_markdown$Markdown$ListItem$taskItemParser = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(1),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'[x] ',
					$elm$parser$Parser$ExpectingSymbol('[x] ')))),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(1),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'[X] ',
					$elm$parser$Parser$ExpectingSymbol('[X] ')))),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(0),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'[ ] ',
					$elm$parser$Parser$ExpectingSymbol('[ ] '))))
		]));
var $dillonkearns$elm_markdown$Parser$Extra$zeroOrMore = function (condition) {
	return $elm$parser$Parser$Advanced$chompWhile(condition);
};
var $dillonkearns$elm_markdown$Markdown$ListItem$parser = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$ListItem$TaskItem),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$dillonkearns$elm_markdown$Markdown$ListItem$taskItemParser,
					$dillonkearns$elm_markdown$Parser$Extra$zeroOrMore($dillonkearns$elm_markdown$Helpers$isSpacebar))),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$getChompedString(
					$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
				$elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							$elm$parser$Parser$Advanced$symbol(
							A2(
								$elm$parser$Parser$Advanced$Token,
								'\n',
								$elm$parser$Parser$ExpectingSymbol('\\n'))),
							$elm$parser$Parser$Advanced$end(
							$elm$parser$Parser$Expecting('End of input'))
						])))),
			A2(
			$elm$parser$Parser$Advanced$keeper,
			$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$ListItem$PlainItem),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$getChompedString(
					$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
				$elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							$elm$parser$Parser$Advanced$symbol(
							A2(
								$elm$parser$Parser$Advanced$Token,
								'\n',
								$elm$parser$Parser$ExpectingSymbol('\\n'))),
							$elm$parser$Parser$Advanced$end(
							$elm$parser$Parser$Expecting('End of input'))
						]))))
		]));
var $dillonkearns$elm_markdown$Markdown$UnorderedList$openingItemParser = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$keeper,
		$elm$parser$Parser$Advanced$succeed($elm$core$Tuple$pair),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$UnorderedList$listMarkerParser),
			$dillonkearns$elm_markdown$Parser$Extra$oneOrMore($dillonkearns$elm_markdown$Helpers$isSpacebar))),
	$dillonkearns$elm_markdown$Markdown$ListItem$parser);
var $dillonkearns$elm_markdown$Markdown$UnorderedList$itemBody = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$Advanced$backtrackable(
						$dillonkearns$elm_markdown$Parser$Extra$oneOrMore($dillonkearns$elm_markdown$Helpers$isSpacebar))),
				$elm$parser$Parser$Advanced$commit('')),
			$dillonkearns$elm_markdown$Markdown$ListItem$parser),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(
				$dillonkearns$elm_markdown$Markdown$ListItem$PlainItem('')),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'\n',
					$elm$parser$Parser$ExpectingSymbol('\\n'))))
		]));
var $dillonkearns$elm_markdown$Markdown$UnorderedList$singleItemParser = function (listMarker) {
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$Advanced$backtrackable(
				$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						listMarker,
						$elm$parser$Parser$ExpectingSymbol(listMarker))))),
		$dillonkearns$elm_markdown$Markdown$UnorderedList$itemBody);
};
var $dillonkearns$elm_markdown$Markdown$UnorderedList$statementsHelp = F3(
	function (listMarker, firstItem, revStmts) {
		return $elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$keeper,
					$elm$parser$Parser$Advanced$succeed(
						function (stmt) {
							return $elm$parser$Parser$Advanced$Loop(
								A2($elm$core$List$cons, stmt, revStmts));
						}),
					$dillonkearns$elm_markdown$Markdown$UnorderedList$singleItemParser(listMarker)),
					A2(
					$elm$parser$Parser$Advanced$map,
					function (_v0) {
						return $elm$parser$Parser$Advanced$Done(
							A2(
								$elm$core$List$cons,
								firstItem,
								$elm$core$List$reverse(revStmts)));
					},
					$elm$parser$Parser$Advanced$succeed(0))
				]));
	});
var $dillonkearns$elm_markdown$Markdown$UnorderedList$parser = A2(
	$elm$parser$Parser$Advanced$andThen,
	function (_v0) {
		var listMarker = _v0.a;
		var firstItem = _v0.b;
		return A2(
			$elm$parser$Parser$Advanced$loop,
			_List_Nil,
			A2($dillonkearns$elm_markdown$Markdown$UnorderedList$statementsHelp, listMarker, firstItem));
	},
	$dillonkearns$elm_markdown$Markdown$UnorderedList$openingItemParser);
var $dillonkearns$elm_markdown$Markdown$Parser$unorderedListBlock = A2(
	$elm$parser$Parser$Advanced$map,
	$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
	A2(
		$elm$parser$Parser$Advanced$map,
		$elm$core$List$map(
			function (unparsedListItem) {
				if (!unparsedListItem.$) {
					var completion = unparsedListItem.a;
					var body = unparsedListItem.b;
					return {
						d2: body,
						cn: $elm$core$Maybe$Just(
							function () {
								if (completion === 1) {
									return true;
								} else {
									return false;
								}
							}())
					};
				} else {
					var body = unparsedListItem.a;
					return {d2: body, cn: $elm$core$Maybe$Nothing};
				}
			}),
		$dillonkearns$elm_markdown$Markdown$UnorderedList$parser));
var $dillonkearns$elm_markdown$Markdown$Parser$updateRawBlocks = F2(
	function (state, updatedRawBlocks) {
		return _Utils_update(
			state,
			{al: updatedRawBlocks});
	});
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$childToParser = function (node) {
	switch (node.$) {
		case 0:
			var tag = node.a;
			var attributes = node.b;
			var children = node.c;
			return A2(
				$elm$parser$Parser$Advanced$andThen,
				function (childrenAsBlocks) {
					return $elm$parser$Parser$Advanced$succeed(
						_List_fromArray(
							[
								$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
								A3($dillonkearns$elm_markdown$Markdown$Block$HtmlElement, tag, attributes, childrenAsBlocks))
							]));
				},
				$dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocksParser(children));
		case 1:
			var innerText = node.a;
			var _v24 = A2(
				$elm$parser$Parser$Advanced$run,
				$dillonkearns$elm_markdown$Markdown$Parser$cyclic$multiParser2(),
				innerText);
			if (!_v24.$) {
				var value = _v24.a;
				return $elm$parser$Parser$Advanced$succeed(value);
			} else {
				var error = _v24.a;
				return $elm$parser$Parser$Advanced$problem(
					$elm$parser$Parser$Expecting(
						A2(
							$elm$core$String$join,
							'\n',
							A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, error))));
			}
		case 2:
			var string = node.a;
			return $elm$parser$Parser$Advanced$succeed(
				_List_fromArray(
					[
						$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
						$dillonkearns$elm_markdown$Markdown$Block$HtmlComment(string))
					]));
		case 3:
			var string = node.a;
			return $elm$parser$Parser$Advanced$succeed(
				_List_fromArray(
					[
						$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
						$dillonkearns$elm_markdown$Markdown$Block$Cdata(string))
					]));
		case 4:
			var string = node.a;
			return $elm$parser$Parser$Advanced$succeed(
				_List_fromArray(
					[
						$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
						$dillonkearns$elm_markdown$Markdown$Block$ProcessingInstruction(string))
					]));
		default:
			var declarationType = node.a;
			var content = node.b;
			return $elm$parser$Parser$Advanced$succeed(
				_List_fromArray(
					[
						$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
						A2($dillonkearns$elm_markdown$Markdown$Block$HtmlDeclaration, declarationType, content))
					]));
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$combineBlocks = F3(
	function (linkReferences, rawBlock, soFar) {
		return A2(
			$elm$parser$Parser$Advanced$andThen,
			function (parsedBlocks) {
				return A2(
					$elm$parser$Parser$Advanced$map,
					function (maybeNewParsedBlock) {
						if (!maybeNewParsedBlock.$) {
							var newParsedBlock = maybeNewParsedBlock.a;
							return A2($elm$core$List$cons, newParsedBlock, parsedBlocks);
						} else {
							return parsedBlocks;
						}
					},
					A2($dillonkearns$elm_markdown$Markdown$Parser$parseInlines, linkReferences, rawBlock));
			},
			soFar);
	});
var $dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper = F2(
	function (referencesDict, _v20) {
		var unparsedInlines = _v20;
		var referencesDict2 = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$mapSecond(
					function (_v21) {
						var destination = _v21.em;
						var title = _v21.fr;
						return _Utils_Tuple2(destination, title);
					}),
				referencesDict));
		return A2(
			$elm$core$List$map,
			$dillonkearns$elm_markdown$Markdown$Parser$mapInline,
			A2($dillonkearns$elm_markdown$Markdown$InlineParser$parse, referencesDict2, unparsedInlines));
	});
var $dillonkearns$elm_markdown$Markdown$Parser$mapInline = function (inline) {
	switch (inline.$) {
		case 0:
			var string = inline.a;
			return $dillonkearns$elm_markdown$Markdown$Block$Text(string);
		case 1:
			return $dillonkearns$elm_markdown$Markdown$Block$HardLineBreak;
		case 2:
			var string = inline.a;
			return $dillonkearns$elm_markdown$Markdown$Block$CodeSpan(string);
		case 3:
			var string = inline.a;
			var maybeString = inline.b;
			var inlines = inline.c;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Block$Link,
				string,
				maybeString,
				A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
		case 4:
			var string = inline.a;
			var maybeString = inline.b;
			var inlines = inline.c;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Block$Image,
				string,
				maybeString,
				A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
		case 5:
			var node = inline.a;
			return $dillonkearns$elm_markdown$Markdown$Block$HtmlInline(
				$dillonkearns$elm_markdown$Markdown$Parser$nodeToRawBlock(node));
		default:
			var level = inline.a;
			var inlines = inline.b;
			switch (level) {
				case 1:
					return $dillonkearns$elm_markdown$Markdown$Block$Emphasis(
						A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
				case 2:
					return $dillonkearns$elm_markdown$Markdown$Block$Strong(
						A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
				default:
					return $dillonkearns$elm_markdown$Markdown$Block$Strong(
						A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
			}
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$nodeToRawBlock = function (node) {
	switch (node.$) {
		case 1:
			var innerText = node.a;
			return $dillonkearns$elm_markdown$Markdown$Block$HtmlComment('TODO this never happens, but use types to drop this case.');
		case 0:
			var tag = node.a;
			var attributes = node.b;
			var children = node.c;
			var parsedChildren = $elm$core$List$concat(
				A2(
					$elm$core$List$map,
					function (child) {
						if (child.$ === 1) {
							var text = child.a;
							return $dillonkearns$elm_markdown$Markdown$Parser$textNodeToBlocks(text);
						} else {
							return _List_fromArray(
								[
									$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
									$dillonkearns$elm_markdown$Markdown$Parser$nodeToRawBlock(child))
								]);
						}
					},
					children));
			return A3($dillonkearns$elm_markdown$Markdown$Block$HtmlElement, tag, attributes, parsedChildren);
		case 2:
			var string = node.a;
			return $dillonkearns$elm_markdown$Markdown$Block$HtmlComment(string);
		case 3:
			var string = node.a;
			return $dillonkearns$elm_markdown$Markdown$Block$Cdata(string);
		case 4:
			var string = node.a;
			return $dillonkearns$elm_markdown$Markdown$Block$ProcessingInstruction(string);
		default:
			var declarationType = node.a;
			var content = node.b;
			return A2($dillonkearns$elm_markdown$Markdown$Block$HtmlDeclaration, declarationType, content);
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocksParser = function (children) {
	return A2(
		$elm$parser$Parser$Advanced$map,
		$elm$core$List$concat,
		$dillonkearns$elm_markdown$Markdown$Parser$combine(
			A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$childToParser, children)));
};
var $dillonkearns$elm_markdown$Markdown$Parser$parseAllInlines = function (state) {
	return A3(
		$elm$core$List$foldl,
		$dillonkearns$elm_markdown$Markdown$Parser$combineBlocks(state.aN),
		$elm$parser$Parser$Advanced$succeed(_List_Nil),
		state.al);
};
var $dillonkearns$elm_markdown$Markdown$Parser$parseInlines = F2(
	function (linkReferences, rawBlock) {
		switch (rawBlock.$) {
			case 0:
				var level = rawBlock.a;
				var unparsedInlines = rawBlock.b;
				return A2(
					$elm$parser$Parser$Advanced$andThen,
					function (parsedLevel) {
						return function (styledLine) {
							return $dillonkearns$elm_markdown$Markdown$Parser$just(
								A2($dillonkearns$elm_markdown$Markdown$Block$Heading, parsedLevel, styledLine));
						}(
							A2($dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper, linkReferences, unparsedInlines));
					},
					$dillonkearns$elm_markdown$Markdown$Parser$levelParser(level));
			case 1:
				var unparsedInlines = rawBlock.a;
				return function (styledLine) {
					return $dillonkearns$elm_markdown$Markdown$Parser$just(
						$dillonkearns$elm_markdown$Markdown$Block$Paragraph(styledLine));
				}(
					A2($dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper, linkReferences, unparsedInlines));
			case 2:
				var html = rawBlock.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$just(
					$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(html));
			case 3:
				var unparsedItems = rawBlock.a;
				return A2(
					$elm$parser$Parser$Advanced$map,
					$elm$core$Maybe$Just,
					A2(
						$elm$parser$Parser$Advanced$map,
						$dillonkearns$elm_markdown$Markdown$Block$UnorderedList,
						$dillonkearns$elm_markdown$Markdown$Parser$combine(
							A2(
								$elm$core$List$map,
								function (unparsedItem) {
									return A2(
										$elm$parser$Parser$Advanced$map,
										function (parsedInlines) {
											var task = function () {
												var _v11 = unparsedItem.cn;
												if (!_v11.$) {
													if (!_v11.a) {
														return 1;
													} else {
														return 2;
													}
												} else {
													return 0;
												}
											}();
											return A2($dillonkearns$elm_markdown$Markdown$Block$ListItem, task, parsedInlines);
										},
										A3($dillonkearns$elm_markdown$Markdown$Parser$parseRawInline, linkReferences, $elm$core$Basics$identity, unparsedItem.d2));
								},
								unparsedItems))));
			case 4:
				var startingIndex = rawBlock.a;
				var unparsedInlines = rawBlock.b;
				return A2(
					$elm$parser$Parser$Advanced$map,
					$elm$core$Maybe$Just,
					A2(
						$elm$parser$Parser$Advanced$map,
						$dillonkearns$elm_markdown$Markdown$Block$OrderedList(startingIndex),
						$dillonkearns$elm_markdown$Markdown$Parser$combine(
							A2(
								$elm$core$List$map,
								A2($dillonkearns$elm_markdown$Markdown$Parser$parseRawInline, linkReferences, $elm$core$Basics$identity),
								unparsedInlines))));
			case 5:
				var codeBlock = rawBlock.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$just(
					$dillonkearns$elm_markdown$Markdown$Block$CodeBlock(codeBlock));
			case 7:
				return $dillonkearns$elm_markdown$Markdown$Parser$just($dillonkearns$elm_markdown$Markdown$Block$ThematicBreak);
			case 9:
				return $elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Nothing);
			case 10:
				var rawBlocks = rawBlock.a;
				var _v12 = A2(
					$elm$parser$Parser$Advanced$run,
					$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
					rawBlocks);
				if (!_v12.$) {
					var value = _v12.a;
					return A2(
						$elm$parser$Parser$Advanced$map,
						function (parsedBlocks) {
							return $elm$core$Maybe$Just(
								$dillonkearns$elm_markdown$Markdown$Block$BlockQuote(parsedBlocks));
						},
						$dillonkearns$elm_markdown$Markdown$Parser$parseAllInlines(value));
				} else {
					var error = _v12.a;
					return $elm$parser$Parser$Advanced$problem(
						$elm$parser$Parser$Problem(
							$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(error)));
				}
			case 6:
				var codeBlockBody = rawBlock.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$just(
					$dillonkearns$elm_markdown$Markdown$Block$CodeBlock(
						{d2: codeBlockBody, c7: $elm$core$Maybe$Nothing}));
			default:
				var _v13 = rawBlock.a;
				var header = _v13.a;
				var rows = _v13.b;
				var parsedHeader = $dillonkearns$elm_markdown$Markdown$Parser$combine(
					A2(
						$elm$core$List$map,
						function (_v14) {
							var label = _v14.aL;
							var alignment = _v14.a2;
							return A3(
								$dillonkearns$elm_markdown$Markdown$Parser$parseRawInline,
								linkReferences,
								function (parsedHeaderLabel) {
									return {a2: alignment, aL: parsedHeaderLabel};
								},
								label);
						},
						header));
				return A2(
					$elm$parser$Parser$Advanced$andThen,
					function (headerThing) {
						return $dillonkearns$elm_markdown$Markdown$Parser$just(
							A2($dillonkearns$elm_markdown$Markdown$Block$Table, headerThing, _List_Nil));
					},
					parsedHeader);
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$parseRawInline = F3(
	function (linkReferences, wrap, unparsedInlines) {
		return $elm$parser$Parser$Advanced$succeed(
			function (styledLine) {
				return wrap(styledLine);
			}(
				A2($dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper, linkReferences, unparsedInlines)));
	});
var $dillonkearns$elm_markdown$Markdown$Parser$statementsHelp2 = function (revStmts) {
	var keepLooping = function (parser) {
		return A2(
			$elm$parser$Parser$Advanced$map,
			function (newRawBlock) {
				var _v4 = _Utils_Tuple2(newRawBlock, revStmts.al);
				_v4$5:
				while (true) {
					if (_v4.b.b) {
						switch (_v4.a.$) {
							case 5:
								if (_v4.b.a.$ === 5) {
									var block1 = _v4.a.a;
									var _v5 = _v4.b;
									var block2 = _v5.a.a;
									var rest = _v5.b;
									return $elm$parser$Parser$Advanced$Loop(
										A2(
											$dillonkearns$elm_markdown$Markdown$Parser$updateRawBlocks,
											revStmts,
											A2(
												$elm$core$List$cons,
												$dillonkearns$elm_markdown$Markdown$RawBlock$CodeBlock(
													{
														d2: A2($dillonkearns$elm_markdown$Markdown$Parser$joinStringsPreserveAll, block2.d2, block1.d2),
														c7: $elm$core$Maybe$Nothing
													}),
												rest)));
								} else {
									break _v4$5;
								}
							case 6:
								if (_v4.b.a.$ === 6) {
									var block1 = _v4.a.a;
									var _v6 = _v4.b;
									var block2 = _v6.a.a;
									var rest = _v6.b;
									return $elm$parser$Parser$Advanced$Loop(
										A2(
											$dillonkearns$elm_markdown$Markdown$Parser$updateRawBlocks,
											revStmts,
											A2(
												$elm$core$List$cons,
												$dillonkearns$elm_markdown$Markdown$RawBlock$IndentedCodeBlock(
													A2($dillonkearns$elm_markdown$Markdown$Parser$joinStringsPreserveAll, block2, block1)),
												rest)));
								} else {
									break _v4$5;
								}
							case 10:
								if (_v4.b.a.$ === 10) {
									var body1 = _v4.a.a;
									var _v8 = _v4.b;
									var body2 = _v8.a.a;
									var rest = _v8.b;
									return $elm$parser$Parser$Advanced$Loop(
										A2(
											$dillonkearns$elm_markdown$Markdown$Parser$updateRawBlocks,
											revStmts,
											A2(
												$elm$core$List$cons,
												$dillonkearns$elm_markdown$Markdown$RawBlock$BlockQuote(
													A2($dillonkearns$elm_markdown$Markdown$Parser$joinStringsPreserveAll, body2, body1)),
												rest)));
								} else {
									break _v4$5;
								}
							case 1:
								switch (_v4.b.a.$) {
									case 10:
										var body1 = _v4.a.a;
										var _v7 = _v4.b;
										var body2 = _v7.a.a;
										var rest = _v7.b;
										return $elm$parser$Parser$Advanced$Loop(
											A2(
												$dillonkearns$elm_markdown$Markdown$Parser$updateRawBlocks,
												revStmts,
												A2(
													$elm$core$List$cons,
													$dillonkearns$elm_markdown$Markdown$RawBlock$BlockQuote(
														A3($dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith, '\n', body2, body1)),
													rest)));
									case 1:
										var body1 = _v4.a.a;
										var _v9 = _v4.b;
										var body2 = _v9.a.a;
										var rest = _v9.b;
										return $elm$parser$Parser$Advanced$Loop(
											A2(
												$dillonkearns$elm_markdown$Markdown$Parser$updateRawBlocks,
												revStmts,
												A2(
													$elm$core$List$cons,
													$dillonkearns$elm_markdown$Markdown$RawBlock$Body(
														A3($dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith, '\n', body2, body1)),
													rest)));
									default:
										break _v4$5;
								}
							default:
								break _v4$5;
						}
					} else {
						break _v4$5;
					}
				}
				return $elm$parser$Parser$Advanced$Loop(
					A2(
						$dillonkearns$elm_markdown$Markdown$Parser$updateRawBlocks,
						revStmts,
						A2($elm$core$List$cons, newRawBlock, revStmts.al)));
			},
			parser);
	};
	var indentedCodeParser = function () {
		var _v3 = revStmts.al;
		if (_v3.b && (_v3.a.$ === 1)) {
			return $elm$parser$Parser$Advanced$oneOf(_List_Nil);
		} else {
			return keepLooping($dillonkearns$elm_markdown$Markdown$Parser$indentedCodeBlock);
		}
	}();
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v1) {
					return $elm$parser$Parser$Advanced$Done(revStmts);
				},
				$elm$parser$Parser$Advanced$end(
					$elm$parser$Parser$Expecting('End'))),
				keepLooping($dillonkearns$elm_markdown$Markdown$Parser$parseAsParagraphInsteadOfHtmlBlock),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (linkReference) {
					return $elm$parser$Parser$Advanced$Loop(
						A2($dillonkearns$elm_markdown$Markdown$Parser$addReference, revStmts, linkReference));
				},
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$parser)),
				keepLooping($dillonkearns$elm_markdown$Markdown$Parser$blankLine),
				keepLooping($dillonkearns$elm_markdown$Markdown$Parser$blockQuote),
				keepLooping(
				A2(
					$elm$parser$Parser$Advanced$map,
					$dillonkearns$elm_markdown$Markdown$RawBlock$CodeBlock,
					$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$CodeBlock$parser))),
				indentedCodeParser,
				keepLooping(
				A2(
					$elm$parser$Parser$Advanced$map,
					function (_v2) {
						return $dillonkearns$elm_markdown$Markdown$RawBlock$ThematicBreak;
					},
					$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$ThematicBreak$parser))),
				keepLooping($dillonkearns$elm_markdown$Markdown$Parser$unorderedListBlock),
				keepLooping(
				$dillonkearns$elm_markdown$Markdown$Parser$orderedListBlock(
					$elm$core$List$head(revStmts.al))),
				keepLooping(
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$Parser$heading)),
				keepLooping(
				$dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser()),
				keepLooping($dillonkearns$elm_markdown$Markdown$Parser$plainLine)
			]));
};
var $dillonkearns$elm_markdown$Markdown$Parser$textNodeToBlocks = function (textNodeValue) {
	return A2(
		$elm$core$Result$withDefault,
		_List_Nil,
		A2(
			$elm$parser$Parser$Advanced$run,
			$dillonkearns$elm_markdown$Markdown$Parser$cyclic$multiParser2(),
			textNodeValue));
};
var $dillonkearns$elm_markdown$Markdown$Parser$xmlNodeToHtmlNode = function (parser) {
	return A2(
		$elm$parser$Parser$Advanced$andThen,
		function (xmlNode) {
			switch (xmlNode.$) {
				case 1:
					var innerText = xmlNode.a;
					return $elm$parser$Parser$Advanced$succeed(
						$dillonkearns$elm_markdown$Markdown$RawBlock$Body(innerText));
				case 0:
					var tag = xmlNode.a;
					var attributes = xmlNode.b;
					var children = xmlNode.c;
					return A2(
						$elm$parser$Parser$Advanced$andThen,
						function (parsedChildren) {
							return $elm$parser$Parser$Advanced$succeed(
								$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
									A3($dillonkearns$elm_markdown$Markdown$Block$HtmlElement, tag, attributes, parsedChildren)));
						},
						$dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocksParser(children));
				case 2:
					var string = xmlNode.a;
					return $elm$parser$Parser$Advanced$succeed(
						$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
							$dillonkearns$elm_markdown$Markdown$Block$HtmlComment(string)));
				case 3:
					var string = xmlNode.a;
					return $elm$parser$Parser$Advanced$succeed(
						$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
							$dillonkearns$elm_markdown$Markdown$Block$Cdata(string)));
				case 4:
					var string = xmlNode.a;
					return $elm$parser$Parser$Advanced$succeed(
						$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
							$dillonkearns$elm_markdown$Markdown$Block$ProcessingInstruction(string)));
				default:
					var declarationType = xmlNode.a;
					var content = xmlNode.b;
					return $elm$parser$Parser$Advanced$succeed(
						$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
							A2($dillonkearns$elm_markdown$Markdown$Block$HtmlDeclaration, declarationType, content)));
			}
		},
		parser);
};
function $dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser() {
	return $dillonkearns$elm_markdown$Markdown$Parser$xmlNodeToHtmlNode($dillonkearns$elm_markdown$HtmlParser$html);
}
function $dillonkearns$elm_markdown$Markdown$Parser$cyclic$multiParser2() {
	return A2(
		$elm$parser$Parser$Advanced$map,
		$elm$core$List$filter(
			function (item) {
				if ((item.$ === 5) && (!item.a.b)) {
					return false;
				} else {
					return true;
				}
			}),
		A2(
			$elm$parser$Parser$Advanced$andThen,
			$dillonkearns$elm_markdown$Markdown$Parser$parseAllInlines,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
				$elm$parser$Parser$Advanced$succeed($elm$parser$Parser$Advanced$end))));
}
function $dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser() {
	return A2(
		$elm$parser$Parser$Advanced$loop,
		{aN: _List_Nil, al: _List_Nil},
		$dillonkearns$elm_markdown$Markdown$Parser$statementsHelp2);
}
var $dillonkearns$elm_markdown$Markdown$Parser$htmlParser = $dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser();
$dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser = function () {
	return $dillonkearns$elm_markdown$Markdown$Parser$htmlParser;
};
var $dillonkearns$elm_markdown$Markdown$Parser$multiParser2 = $dillonkearns$elm_markdown$Markdown$Parser$cyclic$multiParser2();
$dillonkearns$elm_markdown$Markdown$Parser$cyclic$multiParser2 = function () {
	return $dillonkearns$elm_markdown$Markdown$Parser$multiParser2;
};
var $dillonkearns$elm_markdown$Markdown$Parser$rawBlockParser = $dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser();
$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser = function () {
	return $dillonkearns$elm_markdown$Markdown$Parser$rawBlockParser;
};
var $dillonkearns$elm_markdown$Markdown$Parser$parse = function (input) {
	return A2($elm$parser$Parser$Advanced$run, $dillonkearns$elm_markdown$Markdown$Parser$multiParser2, input);
};
var $author$project$Components$Markdown$parseMarkdownLine = function (markdownLine) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('botPreviewBotDescriptionText')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(markdownLine),
				A2($elm$html$Html$br, _List_Nil, _List_Nil)
			]));
};
var $elm$core$Result$map2 = F3(
	function (func, ra, rb) {
		if (ra.$ === 1) {
			var x = ra.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = ra.a;
			if (rb.$ === 1) {
				var x = rb.a;
				return $elm$core$Result$Err(x);
			} else {
				var b = rb.a;
				return $elm$core$Result$Ok(
					A2(func, a, b));
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$combineResults = A2(
	$elm$core$List$foldr,
	$elm$core$Result$map2($elm$core$List$cons),
	$elm$core$Result$Ok(_List_Nil));
var $dillonkearns$elm_markdown$Markdown$Block$foldl = F3(
	function (_function, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var block = list.a;
				var remainingBlocks = list.b;
				switch (block.$) {
					case 0:
						var html = block.a;
						if (!html.$) {
							var children = html.c;
							var $temp$function = _function,
								$temp$acc = A2(_function, block, acc),
								$temp$list = _Utils_ap(children, remainingBlocks);
							_function = $temp$function;
							acc = $temp$acc;
							list = $temp$list;
							continue foldl;
						} else {
							var $temp$function = _function,
								$temp$acc = A2(_function, block, acc),
								$temp$list = remainingBlocks;
							_function = $temp$function;
							acc = $temp$acc;
							list = $temp$list;
							continue foldl;
						}
					case 1:
						var listItems = block.a;
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 2:
						var _int = block.a;
						var lists = block.b;
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 3:
						var blocks = block.a;
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = _Utils_ap(blocks, remainingBlocks);
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 4:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 5:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 6:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 7:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					default:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Block$extractInlineBlockText = function (block) {
	switch (block.$) {
		case 5:
			var inlines = block.a;
			return $dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines);
		case 0:
			var html = block.a;
			if (!html.$) {
				var blocks = html.c;
				return A3(
					$dillonkearns$elm_markdown$Markdown$Block$foldl,
					F2(
						function (nestedBlock, soFar) {
							return _Utils_ap(
								soFar,
								$dillonkearns$elm_markdown$Markdown$Block$extractInlineBlockText(nestedBlock));
						}),
					'',
					blocks);
			} else {
				return '';
			}
		case 1:
			var items = block.a;
			return A2(
				$elm$core$String$join,
				'\n',
				A2(
					$elm$core$List$map,
					function (_v4) {
						var task = _v4.a;
						var inlines = _v4.b;
						return $dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines);
					},
					items));
		case 2:
			var _int = block.a;
			var items = block.b;
			return A2(
				$elm$core$String$join,
				'\n',
				A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Block$extractInlineText, items));
		case 3:
			var blocks = block.a;
			return A2(
				$elm$core$String$join,
				'\n',
				A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Block$extractInlineBlockText, blocks));
		case 4:
			var headingLevel = block.a;
			var inlines = block.b;
			return $dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines);
		case 6:
			var header = block.a;
			var rows = block.b;
			return A2(
				$elm$core$String$join,
				'\n',
				$elm$core$List$concat(
					_List_fromArray(
						[
							A2(
							$elm$core$List$map,
							$dillonkearns$elm_markdown$Markdown$Block$extractInlineText,
							A2(
								$elm$core$List$map,
								function ($) {
									return $.aL;
								},
								header)),
							A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Block$extractInlineText, rows)
						])));
		case 7:
			var body = block.a.d2;
			return body;
		default:
			return '';
	}
};
var $dillonkearns$elm_markdown$Markdown$Block$extractInlineText = function (inlines) {
	return A3($elm$core$List$foldl, $dillonkearns$elm_markdown$Markdown$Block$extractTextHelp, '', inlines);
};
var $dillonkearns$elm_markdown$Markdown$Block$extractTextHelp = F2(
	function (inline, text) {
		switch (inline.$) {
			case 6:
				var str = inline.a;
				return _Utils_ap(text, str);
			case 7:
				return text + ' ';
			case 5:
				var str = inline.a;
				return _Utils_ap(text, str);
			case 1:
				var inlines = inline.c;
				return _Utils_ap(
					text,
					$dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines));
			case 2:
				var inlines = inline.c;
				return _Utils_ap(
					text,
					$dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines));
			case 0:
				var html = inline.a;
				if (!html.$) {
					var blocks = html.c;
					return A3(
						$dillonkearns$elm_markdown$Markdown$Block$foldl,
						F2(
							function (block, soFar) {
								return _Utils_ap(
									soFar,
									$dillonkearns$elm_markdown$Markdown$Block$extractInlineBlockText(block));
							}),
						text,
						blocks);
				} else {
					return text;
				}
			case 4:
				var inlines = inline.a;
				return _Utils_ap(
					text,
					$dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines));
			default:
				var inlines = inline.a;
				return _Utils_ap(
					text,
					$dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines));
		}
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderHtml = F5(
	function (tagName, attributes, children, _v0, renderedChildren) {
		var htmlRenderer = _v0;
		return A2(
			$elm$core$Result$andThen,
			function (okChildren) {
				return A2(
					$elm$core$Result$map,
					function (myRenderer) {
						return myRenderer(okChildren);
					},
					A3(htmlRenderer, tagName, attributes, children));
			},
			$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(renderedChildren));
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$foldThing = F3(
	function (renderer, topLevelInline, soFar) {
		var _v7 = A2($dillonkearns$elm_markdown$Markdown$Renderer$renderSingleInline, renderer, topLevelInline);
		if (!_v7.$) {
			var inline = _v7.a;
			return A2($elm$core$List$cons, inline, soFar);
		} else {
			return soFar;
		}
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderHelper = F2(
	function (renderer, blocks) {
		return A2(
			$elm$core$List$filterMap,
			$dillonkearns$elm_markdown$Markdown$Renderer$renderHelperSingle(renderer),
			blocks);
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderHelperSingle = function (renderer) {
	return function (block) {
		switch (block.$) {
			case 4:
				var level = block.a;
				var content = block.b;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						function (children) {
							return renderer.bZ(
								{
									cB: children,
									c8: level,
									G: $dillonkearns$elm_markdown$Markdown$Block$extractInlineText(content)
								});
						},
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, content)));
			case 5:
				var content = block.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.b9,
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, content)));
			case 0:
				var html = block.a;
				if (!html.$) {
					var tag = html.a;
					var attributes = html.b;
					var children = html.c;
					return $elm$core$Maybe$Just(
						A4($dillonkearns$elm_markdown$Markdown$Renderer$renderHtmlNode, renderer, tag, attributes, children));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			case 1:
				var items = block.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.cq,
						$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
							A2(
								$elm$core$List$map,
								function (_v4) {
									var task = _v4.a;
									var children = _v4.b;
									return A2(
										$elm$core$Result$map,
										function (renderedBody) {
											return A2($dillonkearns$elm_markdown$Markdown$Block$ListItem, task, renderedBody);
										},
										A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, children));
								},
								items))));
			case 2:
				var startingIndex = block.a;
				var items = block.b;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.b8(startingIndex),
						$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
							A2(
								$elm$core$List$map,
								$dillonkearns$elm_markdown$Markdown$Renderer$renderStyled(renderer),
								items))));
			case 7:
				var codeBlock = block.a;
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						renderer.bN(codeBlock)));
			case 8:
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(renderer.co));
			case 3:
				var nestedBlocks = block.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.bI,
						$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
							A2($dillonkearns$elm_markdown$Markdown$Renderer$renderHelper, renderer, nestedBlocks))));
			default:
				var header = block.a;
				var rows = block.b;
				var renderedHeaderCells = $dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
					A2(
						$elm$core$List$map,
						function (_v6) {
							var label = _v6.aL;
							var alignment = _v6.a2;
							return A2(
								$elm$core$Result$map,
								$elm$core$Tuple$pair(alignment),
								A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, label));
						},
						header));
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						function (h) {
							return renderer.ck(
								_List_fromArray(
									[h]));
						},
						A2(
							$elm$core$Result$map,
							function (listListView) {
								return renderer.cl(
									A2(
										$elm$core$List$map,
										function (_v5) {
											var maybeAlignment = _v5.a;
											var item = _v5.b;
											return A2(renderer.cm, maybeAlignment, item);
										},
										listListView));
							},
							renderedHeaderCells)));
		}
	};
};
var $dillonkearns$elm_markdown$Markdown$Renderer$renderHtmlNode = F4(
	function (renderer, tag, attributes, children) {
		return A5(
			$dillonkearns$elm_markdown$Markdown$Renderer$renderHtml,
			tag,
			attributes,
			children,
			renderer.b$,
			A2($dillonkearns$elm_markdown$Markdown$Renderer$renderHelper, renderer, children));
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderSingleInline = F2(
	function (renderer, inline) {
		switch (inline.$) {
			case 4:
				var innerInlines = inline.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.ch,
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, innerInlines)));
			case 3:
				var innerInlines = inline.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.bT,
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, innerInlines)));
			case 2:
				var src = inline.a;
				var title = inline.b;
				var children = inline.c;
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						renderer.c_(
							{
								bD: $dillonkearns$elm_markdown$Markdown$Block$extractInlineText(children),
								cg: src,
								fr: title
							})));
			case 6:
				var string = inline.a;
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						renderer.D(string)));
			case 5:
				var string = inline.a;
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						renderer.bO(string)));
			case 1:
				var destination = inline.a;
				var title = inline.b;
				var inlines = inline.c;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$andThen,
						function (children) {
							return $elm$core$Result$Ok(
								A2(
									renderer.b1,
									{em: destination, fr: title},
									children));
						},
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, inlines)));
			case 7:
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(renderer.bX));
			default:
				var html = inline.a;
				if (!html.$) {
					var tag = html.a;
					var attributes = html.b;
					var children = html.c;
					return $elm$core$Maybe$Just(
						A4($dillonkearns$elm_markdown$Markdown$Renderer$renderHtmlNode, renderer, tag, attributes, children));
				} else {
					return $elm$core$Maybe$Nothing;
				}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderStyled = F2(
	function (renderer, styledStrings) {
		return $dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
			A3(
				$elm$core$List$foldr,
				$dillonkearns$elm_markdown$Markdown$Renderer$foldThing(renderer),
				_List_Nil,
				styledStrings));
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$render = F2(
	function (renderer, ast) {
		return $dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
			A2($dillonkearns$elm_markdown$Markdown$Renderer$renderHelper, renderer, ast));
	});
var $author$project$Components$Markdown$parseOurMarkdown = function (markdown) {
	var result = A2(
		$elm$core$Result$andThen,
		function (ast) {
			return A2($dillonkearns$elm_markdown$Markdown$Renderer$render, $dillonkearns$elm_markdown$Markdown$Renderer$defaultHtmlRenderer, ast);
		},
		A2(
			$elm$core$Result$mapError,
			function (error) {
				return A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, error));
			},
			$dillonkearns$elm_markdown$Markdown$Parser$parse(markdown)));
	if (!result.$) {
		var rendered = result.a;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('botDescriptionContainer')
				]),
			rendered);
	} else {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$Components$Markdown$parseMarkdownLine,
				A2($elm$core$String$split, '\n', markdown)));
	}
};
var $author$project$Pages$BotApiDocumentation$botApiDocumentation = function (model) {
	return A2(
		$author$project$Components$ContentLeft$contentLeft,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Bot API documentation')
					])),
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Bot server count request')
					])),
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Request spec:')
					])),
				A2(
				$elm$html$Html$code,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Method:'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$elm$html$Html$text('POST'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$elm$html$Html$text('Endpoint: '),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$elm$html$Html$text('https://botdesignerlist.com/api/exposed/bot/update'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$elm$html$Html$text('Header:'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$elm$html$Html$text('authorization: bearer YOUR_BOT_API_TOKEN'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$elm$html$Html$text('content-type: application/json'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$elm$html$Html$text('Body (JSON):'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$elm$html$Html$text('{\"servers\": 12345}')
					])),
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Code samples:')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('botSettingsTokenBox')
					]),
				_List_fromArray(
					[
						A2($author$project$Pages$BotApiDocumentation$languageButton, 0, model),
						A2($author$project$Pages$BotApiDocumentation$languageButton, 1, model),
						A2($author$project$Pages$BotApiDocumentation$languageButton, 2, model),
						A2($author$project$Pages$BotApiDocumentation$languageButton, 3, model)
					])),
				A2(
				$elm$html$Html$code,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Components$Markdown$parseOurMarkdown(
						$author$project$Pages$BotApiDocumentation$documentationText(model.bR))
					]))
			]));
};
var $author$project$Pages$BotPreview$botStatusBoxText = function (botPreview) {
	var _v0 = botPreview.eV;
	if (!_v0.$) {
		var online = _v0.a;
		if (online) {
			var _v1 = botPreview.fe;
			if (!_v1.$) {
				var count = _v1.a;
				return '\uD83D\uDFE2 ' + ($elm$core$String$fromInt(count) + ' servers');
			} else {
				return '\uD83D\uDFE2 Online';
			}
		} else {
			return '🔴 Offline';
		}
	} else {
		return '🔴 Offline';
	}
};
var $author$project$Pages$BotPreview$botStatusBox = function (botPreview) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('botPreviewStatusBox')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Pages$BotPreview$botStatusBoxText(botPreview))
					]))
			]));
};
var $author$project$Pages$BotPreview$colorfulUsername = function (author) {
	return A2(
		$elm$html$Html$a,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('botPreviewAuthorTitle'),
				$elm$html$Html$Attributes$href('/dev/' + author.b0)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('botPreviewBlue')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('@')
					])),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(author.fw)
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('botPreviewBlack')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('#')
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('botPreviewOrange')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(author.fn)
					])),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(':')
					]))
			]));
};
var $author$project$Model$SetBotPreviewCommandSearch = function (a) {
	return {$: 61, a: a};
};
var $author$project$Pages$BotPreview$dbdCommandTile = function (dbdCommand) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('botPreviewDbdCommand')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('botPreviewDbdCommandTitle ')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(dbdCommand.ft + (' - ' + dbdCommand.b5))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('botPreviewDbdCommandDescription ')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(dbdCommand.ay)
					]))
			]));
};
var $author$project$Pages$BotPreview$searchCommandFilter = F2(
	function (search, dbdCommands) {
		var _v0 = $elm$core$String$isEmpty(search);
		if (_v0) {
			return dbdCommands;
		} else {
			return A2(
				$elm$core$List$filter,
				function (command) {
					return A2(
						$elm$core$String$contains,
						search,
						_Utils_ap(
							command.b5,
							_Utils_ap(command.ft, command.ay)));
				},
				dbdCommands);
		}
	});
var $author$project$Pages$BotPreview$dbdCommandList = F2(
	function (model, bot) {
		var _v0 = bot.c3;
		if (_v0) {
			var _v1 = bot.cI;
			if (!_v1.$) {
				var commands = _v1.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('botPreviewDbdCommandList')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('botPreviewDescriptionTitleBar botPreviewDbdCommandListPadding')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('botPreviewDescriptionTitle botPreviewNoBottomMargin')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Command list:')
										])),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('stdInput'),
											$elm$html$Html$Attributes$value(model.a9),
											$elm$html$Html$Events$onInput($author$project$Model$SetBotPreviewCommandSearch),
											$elm$html$Html$Attributes$placeholder('Search in commands')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							A2(
								$elm$core$List$map,
								$author$project$Pages$BotPreview$dbdCommandTile,
								A2($author$project$Pages$BotPreview$searchCommandFilter, model.a9, commands)))
						]));
			} else {
				return $author$project$Components$LoadingIndicator$loadingIndicator;
			}
		} else {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	});
var $author$project$Pages$BotPreview$rateButton = function (model) {
	var _v0 = model.fv;
	if (_v0.$ === 1) {
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('botRateButton'),
					$elm$html$Html$Attributes$href(model.t.q + '#rate-bot')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Rate bot')
				]));
	} else {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('botRateButton botRateButtonDisabled ')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Rate bot')
				]));
	}
};
var $author$project$Model$BotPreviewSubmitRating = function (a) {
	return {$: 37, a: a};
};
var $author$project$Pages$BotPreview$actionButtonClass = function (model) {
	var _v0 = model.S.aT;
	if (!_v0.$) {
		return '';
	} else {
		return ' botPreviewRateDialogActionButtonDisabled ';
	}
};
var $author$project$Model$hasBotRateDialogUrlSuffix = function (model) {
	var _v0 = model.t.aK;
	if (!_v0.$) {
		var fragment = _v0.a;
		return A2($elm$core$String$endsWith, 'rate-bot', fragment);
	} else {
		return false;
	}
};
var $author$project$Model$BotPreviewHoveredOnRatingStar = function (a) {
	return {$: 36, a: a};
};
var $author$project$Model$BotPreviewSelectedRatingStar = function (a) {
	return {$: 35, a: a};
};
var $elm$html$Html$Events$onMouseOver = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseover',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Model$isRatingStarHovered = F2(
	function (index, model) {
		var _v0 = model.S.aT;
		if (!_v0.$) {
			var a = _v0.a;
			return false;
		} else {
			return _Utils_cmp(index, model.S.b_) < 1;
		}
	});
var $author$project$Pages$BotPreview$rateStarHoverClass = F2(
	function (model, index) {
		return A2($author$project$Model$isRatingStarHovered, index, model) ? ' botRateDialogRateStarHovered  ' : ' ';
	});
var $author$project$Model$isRatingStarSelected = F2(
	function (index, model) {
		var _v0 = model.S.aT;
		if (!_v0.$) {
			var selectedStars = _v0.a;
			return _Utils_cmp(index, selectedStars) < 1;
		} else {
			return false;
		}
	});
var $author$project$Pages$BotPreview$rateStarSelectClass = F2(
	function (model, index) {
		return A2($author$project$Model$isRatingStarSelected, index, model) ? ' botRateDialogRateStarSelected ' : ' ';
	});
var $author$project$Pages$BotPreview$rateStarTitle = F2(
	function (model, index) {
		return (A2($author$project$Model$isRatingStarSelected, index, model) || A2($author$project$Model$isRatingStarHovered, index, model)) ? '★' : '☆';
	});
var $author$project$Pages$BotPreview$rateStar = F2(
	function (model, index) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					'botRateDialogRateStar' + (A2($author$project$Pages$BotPreview$rateStarSelectClass, model, index) + A2($author$project$Pages$BotPreview$rateStarHoverClass, model, index))),
					$elm$html$Html$Events$onMouseOver(
					$author$project$Model$BotPreviewHoveredOnRatingStar(index)),
					$elm$html$Html$Events$onClick(
					$author$project$Model$BotPreviewSelectedRatingStar(index))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					A2($author$project$Pages$BotPreview$rateStarTitle, model, index))
				]));
	});
var $author$project$Pages$BotPreview$rateDialog = function (model) {
	if ($author$project$Model$hasBotRateDialogUrlSuffix(model)) {
		var _v0 = model.fa;
		if (!_v0.$) {
			var result = _v0.a;
			if (!result.$) {
				var bot = result.a;
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('botRateDialogDiv'),
							$elm$html$Html$Attributes$href(
							'/bot/' + $author$project$Model$tmpBotId(bot))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('botRateDialogWindow'),
									$author$project$Model$onClickNoBubble($author$project$Model$NoOp)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('botRateDialogTitle')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Rate bot')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('botRateDialogStarContainer ')
										]),
									_List_fromArray(
										[
											A2($author$project$Pages$BotPreview$rateStar, model, 1),
											A2($author$project$Pages$BotPreview$rateStar, model, 2),
											A2($author$project$Pages$BotPreview$rateStar, model, 3),
											A2($author$project$Pages$BotPreview$rateStar, model, 4),
											A2($author$project$Pages$BotPreview$rateStar, model, 5)
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('botPreviewRateDialogActionContainer'),
											$elm$html$Html$Events$onClick(
											$author$project$Model$BotPreviewSubmitRating(
												$author$project$Model$tmpBotId(bot)))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class(
													'botPreviewRateDialogActionButton ' + $author$project$Pages$BotPreview$actionButtonClass(model))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Submit')
												]))
										]))
								]))
						]));
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		} else {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Pages$BotPreview$botPageContent = F2(
	function (model, bot) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('botPreviewTacticalDiv')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('botPreviewBlurredBackground ')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('botPreviewBottomRowContentContainerAligner')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('botPreviewBottomRowContentContainer')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('botPreviewAuthorAndStatusContainer')
												]),
											_List_fromArray(
												[
													$author$project$Pages$BotPreview$colorfulUsername(bot.dY),
													$author$project$Pages$BotPreview$botStatusBox(bot)
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('botPreviewBlurredBottomRow')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$img,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('botPreviewPreviewImg tileLoading'),
															$elm$html$Html$Attributes$src(
															$author$project$Model$profileImageUrl(bot.e4))
														]),
													_List_Nil),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('botPreviewBlurredBottomRowColumn')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$h1,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('botPreviewBotTitle ')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(bot.fr)
																])),
															A2(
															$elm$html$Html$a,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('botPreviewInviteButton'),
																	$elm$html$Html$Attributes$href(bot.eH)
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('+ Invite bot to your server')
																]))
														]))
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('centerFlexRow')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('content pushContentToLeft stretchContent botPreviewContentBackground')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('botPreviewDescriptionTitleBar')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('botPreviewDescriptionTitle')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Entry point:')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('botRateWithRatingContainer')
												]),
											_List_fromArray(
												[
													$author$project$Pages$BotPreview$rateButton(model),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('botPreviewDescriptionTitle')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															'⭐ ' + ($author$project$Pages$BotPreview$botRatingText(bot.dZ) + ' '))
														]))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('botPreviewEntryPointTitle')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(bot.aI)
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('botPreviewDescriptionTitle')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Bot description:')
										])),
									$author$project$Components$Markdown$parseOurMarkdown(bot.ay),
									A2($author$project$Pages$BotPreview$dbdCommandList, model, bot),
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('botPreviewReportBot'),
											$elm$html$Html$Attributes$href(
											'/report/bot/' + $author$project$Model$tmpBotId(bot))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Report bot')
										]))
								]))
						])),
					$author$project$Pages$BotPreview$rateDialog(model)
				]));
	});
var $author$project$Components$EntityLoadingError$previewErrorString = F2(
	function (error, entityLabel) {
		switch (error.$) {
			case 1:
				return 'Timeout occurred during ' + (entityLabel + 'loading');
			case 2:
				return 'Connection error';
			case 3:
				var code = error.a;
				return (code === 404) ? ('HTTP 404: ' + (entityLabel + ' not found')) : ((code === 500) ? 'Ooops, something went wrong, our trained monkey is already working on fixing it' : 'Something went really wrong');
			default:
				return 'Something went really wrong';
		}
	});
var $author$project$Components$EntityLoadingError$previewEntityLoadingError = F3(
	function (error, entityLabel, model) {
		var content = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('content')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2($author$project$Components$EntityLoadingError$previewErrorString, error, entityLabel))
						])),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('/'),
							$elm$html$Html$Attributes$class('stdA')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Back to homepage')
						]))
				]));
		if (error.$ === 3) {
			var code = error.a;
			return ((code === 404) && _Utils_eq(model.fv, $author$project$Model$DataModels$DETERMINING)) ? $author$project$Components$LoadingIndicator$loadingIndicator : content;
		} else {
			return content;
		}
	});
var $author$project$Components$LoadingGuard$loadingGuard = F4(
	function (model, entityName, entity, content) {
		if (!entity.$) {
			var result = entity.a;
			if (!result.$) {
				var value = result.a;
				return A2(content, model, value);
			} else {
				var error = result.a;
				return A3($author$project$Components$EntityLoadingError$previewEntityLoadingError, error, entityName, model);
			}
		} else {
			return $author$project$Components$LoadingIndicator$loadingIndicator;
		}
	});
var $author$project$Pages$BotPreview$botPage = function (model) {
	return A4($author$project$Components$LoadingGuard$loadingGuard, model, 'Bot', model.fa, $author$project$Pages$BotPreview$botPageContent);
};
var $author$project$Model$CopyToClipboard = function (a) {
	return {$: 43, a: a};
};
var $author$project$Model$InvalidateToken = {$: 44};
var $author$project$Model$DataModels$DELETE_BOT = {$: 0};
var $author$project$Model$isConfirmingDelete = function (model) {
	return A2(
		$elm$core$String$endsWith,
		'confirm-delete',
		A2($elm$core$Maybe$withDefault, '', model.t.aK));
};
var $author$project$Pages$BotSettings$confirmDelete = function (model) {
	var _v0 = $author$project$Model$isConfirmingDelete(model);
	if (_v0) {
		return $author$project$Components$Dialog$dialog(
			_List_fromArray(
				[
					$author$project$Components$Dialog$dialogTitle('Confirm bot delete'),
					$author$project$Components$Dialog$dialogContent(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('By clicking \"Confirm delete\" you are accepting, that your bot data will be permanently deleted')
								]))
						])),
					$author$project$Components$Dialog$dialogFooter(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('accountNewEntity'),
									$elm$html$Html$Attributes$href('#')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Cancel')
								])),
							$author$project$Pages$BotSettings$spacer,
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$Model$DeleteBot($author$project$Model$DataModels$DELETE_BOT)),
									$elm$html$Html$Attributes$class('accountNewEntity botSettingsBurningEyesRed'),
									$elm$html$Html$Attributes$href('#confirm-delete')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Confirm deleting bot')
								]))
						]))
				]));
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $elm$html$Html$Attributes$readonly = $elm$html$Html$Attributes$boolProperty('readOnly');
var $author$project$Pages$BotSettings$botSettingsContent = F2(
	function (model, settings) {
		return A2(
			$author$project$Components$ContentLeft$contentLeft,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Bot settings')
						])),
					A2(
					$elm$html$Html$h2,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('API key:')
						])),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('stdInput'),
							$elm$html$Html$Attributes$value(settings.dW),
							$elm$html$Html$Attributes$type_('password'),
							$elm$html$Html$Attributes$readonly(true)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('botSettingsTokenBox')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('stdButton'),
									$elm$html$Html$Events$onClick(
									$author$project$Model$CopyToClipboard(settings.dW))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Copy token')
								])),
							$author$project$Pages$BotSettings$spacer,
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('stdButton'),
									$elm$html$Html$Events$onClick($author$project$Model$InvalidateToken)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Invalidate token')
								])),
							$author$project$Pages$BotSettings$spacer,
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('stdButton'),
									$elm$html$Html$Attributes$href('/documentation/bot-api')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('API documentation')
								]))
						])),
					A2(
					$elm$html$Html$h2,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('marginBlockEndLess')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('⚠ Danger zone')
						])),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('accountNewEntity accountNewEntityRed'),
							$elm$html$Html$Attributes$href('#confirm-delete')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Delete bot')
						])),
					$author$project$Pages$BotSettings$confirmDelete(model)
				]));
	});
var $author$project$Pages$BotSettings$botSettings = function (_v0) {
	var model = _v0.a;
	return A4($author$project$Components$LoadingGuard$loadingGuard, model, 'Bot settings', model.aH, $author$project$Pages$BotSettings$botSettingsContent);
};
var $author$project$Pages$DevPreview$devRating = function (rating) {
	if (!rating.$) {
		var a = rating.a;
		return a;
	} else {
		return '???';
	}
};
var $author$project$Pages$DevPreview$devPreviewContent = F2(
	function (_v0, dev) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('content addNavBarPadding centerContent')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fakeH1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Developer')
						])),
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('devPreviewProfile tileLoading'),
							$elm$html$Html$Attributes$src(
							$author$project$Model$profileImageUrl(dev.ep.bE))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('accountTitleHeaderUsername')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(dev.ep.fw)
								])),
							$elm$html$Html$text('#'),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('accountTitleHeaderTag')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(dev.ep.fn)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('devPreviewOwnedBar')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Owned bots:')
								])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									'Avg. ⭐ ' + $author$project$Pages$DevPreview$devRating(dev.dZ))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('devPreviewBotContainer')
						]),
					A2($elm$core$List$map, $author$project$Components$BotComponent$botComponent, dev.br))
				]));
	});
var $author$project$Pages$DevPreview$devPreview = function (model) {
	return A4($author$project$Components$LoadingGuard$loadingGuard, model, 'Developer', model.fc, $author$project$Pages$DevPreview$devPreviewContent);
};
var $author$project$Model$DesignerBotToImportSelected = function (a) {
	return {$: 21, a: a};
};
var $author$project$Pages$ImportBot$dbdBotComponentButton = function (bot) {
	var _v0 = bot.c2;
	if (_v0) {
		return A2(
			$elm$html$Html$span,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Select bot')
				]));
	} else {
		return A2(
			$elm$html$Html$span,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Unavailable')
				]));
	}
};
var $author$project$Pages$ImportBot$dbdBotComponentClass = function (disabled) {
	if (disabled) {
		return 'importBotBotTile importBotBotTileDisabled';
	} else {
		return 'importBotBotTile';
	}
};
var $author$project$Pages$ImportBot$dbdBotComponent = function (bot) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(
				$author$project$Pages$ImportBot$dbdBotComponentClass(!bot.c2)),
				$elm$html$Html$Events$onClick(
				$author$project$Model$DesignerBotToImportSelected(bot))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h3,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('importBotBotTileTitle')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(bot.b5)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('importBotBotTileFooter')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('importBotBotTileFooter')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$String$fromInt(bot.ea) + (' commands, ' + ($elm$core$String$fromInt(bot.fx) + ' variables')))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('importBotBotTileFooterSpacer')
							]),
						_List_Nil),
						$author$project$Pages$ImportBot$dbdBotComponentButton(bot)
					]))
			]));
};
var $author$project$Pages$ImportBot$importBotContent = function (_v0) {
	var model = _v0.a;
	var user = _v0.b;
	var _v1 = user.dm;
	if (!_v1.$) {
		var bots = _v1.a;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			A2($elm$core$List$map, $author$project$Pages$ImportBot$dbdBotComponent, bots));
	} else {
		return $author$project$Components$LoadingIndicator$loadingIndicator;
	}
};
var $author$project$Pages$ImportBot$importBot = function (_v0) {
	var model = _v0.a;
	var user = _v0.b;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('content pushContentToLeft stretchContent')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Import Bot Designer Bot')
					])),
				$author$project$Pages$ImportBot$importBotContent(
				_Utils_Tuple2(model, user))
			]));
};
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $WhileTruu$elm_blurhash$Internal$Dimensions = F2(
	function (rows, columns) {
		return {W: columns, H: rows};
	});
var $WhileTruu$elm_blurhash$Internal$Triplet = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $WhileTruu$elm_blurhash$Internal$signPow = F2(
	function (value, exp) {
		return (value < 0) ? (-A2(
			$elm$core$Basics$pow,
			$elm$core$Basics$abs(value),
			exp)) : A2($elm$core$Basics$pow, value, exp);
	});
var $WhileTruu$elm_blurhash$Internal$decodeAC = F2(
	function (maximumValue, value) {
		return A3(
			$WhileTruu$elm_blurhash$Internal$Triplet,
			A2(
				$WhileTruu$elm_blurhash$Internal$signPow,
				($elm$core$Basics$floor(value / (19 * 19)) - 9.0) / 9.0,
				2.0) * maximumValue,
			A2(
				$WhileTruu$elm_blurhash$Internal$signPow,
				(A2(
					$elm$core$Basics$modBy,
					19,
					$elm$core$Basics$floor(value / 19)) - 9.0) / 9.0,
				2.0) * maximumValue,
			A2(
				$WhileTruu$elm_blurhash$Internal$signPow,
				(A2($elm$core$Basics$modBy, 19, value) - 9.0) / 9.0,
				2.0) * maximumValue);
	});
var $WhileTruu$elm_blurhash$Internal$alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz#$%*+,-.:;=?@[]^_{|}~';
var $elm$core$String$foldl = _String_foldl;
var $WhileTruu$elm_blurhash$Internal$base83chars = function () {
	var folder = F2(
		function (_char, _v0) {
			var index = _v0.aB;
			var dict = _v0.bc;
			return {
				bc: A3($elm$core$Dict$insert, _char, index, dict),
				aB: index + 1
			};
		});
	return A3(
		$elm$core$String$foldl,
		folder,
		{bc: $elm$core$Dict$empty, aB: 0},
		$WhileTruu$elm_blurhash$Internal$alphabet).bc;
}();
var $WhileTruu$elm_blurhash$Internal$decodeBase83 = function (str) {
	var folder = F2(
		function (a, acc) {
			var _v0 = A2($elm$core$Dict$get, a, $WhileTruu$elm_blurhash$Internal$base83chars);
			if (!_v0.$) {
				var v = _v0.a;
				return v + (acc * 83);
			} else {
				return acc;
			}
		});
	return A3($elm$core$String$foldl, folder, 0, str);
};
var $WhileTruu$elm_blurhash$Internal$foldDimensions = F3(
	function (_v0, folder, _default) {
		var rows = _v0.H;
		var columns = _v0.W;
		var go = F3(
			function (row, column, accum) {
				go:
				while (true) {
					if (_Utils_cmp(column, columns - 1) < 0) {
						var $temp$row = row,
							$temp$column = column + 1,
							$temp$accum = A3(folder, row, column, accum);
						row = $temp$row;
						column = $temp$column;
						accum = $temp$accum;
						continue go;
					} else {
						if (_Utils_cmp(row, rows - 1) < 0) {
							var $temp$row = row + 1,
								$temp$column = 0,
								$temp$accum = A3(folder, row, column, accum);
							row = $temp$row;
							column = $temp$column;
							accum = $temp$accum;
							continue go;
						} else {
							return A3(folder, row, column, accum);
						}
					}
				}
			});
		return A3(go, 0, 0, _default);
	});
var $WhileTruu$elm_blurhash$Internal$srgbToLinear = function (srgbInt) {
	var a = srgbInt / 255;
	return (a <= 0.04045) ? (a / 12.92) : A2($elm$core$Basics$pow, (a + 0.055) / 1.055, 2.4);
};
var $WhileTruu$elm_blurhash$Internal$buildDict = F3(
	function (dimensions, maximumValue, blurhash) {
		var cache = A3(
			$WhileTruu$elm_blurhash$Internal$foldDimensions,
			dimensions,
			F3(
				function (row, column, dict) {
					var _v1 = (row * dimensions.W) + column;
					if (!_v1) {
						var bits = $WhileTruu$elm_blurhash$Internal$decodeBase83(
							A3($elm$core$String$slice, 2, 6, blurhash));
						var value = A3(
							$WhileTruu$elm_blurhash$Internal$Triplet,
							$WhileTruu$elm_blurhash$Internal$srgbToLinear(255 & (bits >> 16)),
							$WhileTruu$elm_blurhash$Internal$srgbToLinear(255 & (bits >> 8)),
							$WhileTruu$elm_blurhash$Internal$srgbToLinear(255 & bits));
						return A3($elm$core$Dict$insert, 0, value, dict);
					} else {
						var i = _v1;
						var key = A3($elm$core$String$slice, 4 + (i * 2), 4 + ((i + 1) * 2), blurhash);
						var value = A2(
							$WhileTruu$elm_blurhash$Internal$decodeAC,
							maximumValue,
							$WhileTruu$elm_blurhash$Internal$decodeBase83(key));
						return A3($elm$core$Dict$insert, i, value, dict);
					}
				}),
			$elm$core$Dict$empty);
		return function (index) {
			var _v0 = A2($elm$core$Dict$get, index, cache);
			if (!_v0.$) {
				var v = _v0.a;
				return v;
			} else {
				return A3($WhileTruu$elm_blurhash$Internal$Triplet, 0, 0, 0);
			}
		};
	});
var $elm$core$Basics$cos = _Basics_cos;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $WhileTruu$elm_blurhash$Internal$linearToSrgb = function (linearFloat) {
	var a = A3($elm$core$Basics$clamp, 0, 1, linearFloat);
	return (a <= 0.0031308) ? $elm$core$Basics$floor(((a * 12.92) * 255) + 0.5) : $elm$core$Basics$floor(
		(((1.055 * A2($elm$core$Basics$pow, a, 1 / 2.4)) - 0.055) * 255) + 0.5);
};
var $elm$core$Basics$pi = _Basics_pi;
var $avh4$elm_color$Color$RgbaSpace = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $avh4$elm_color$Color$scaleFrom255 = function (c) {
	return c / 255;
};
var $avh4$elm_color$Color$rgb255 = F3(
	function (r, g, b) {
		return A4(
			$avh4$elm_color$Color$RgbaSpace,
			$avh4$elm_color$Color$scaleFrom255(r),
			$avh4$elm_color$Color$scaleFrom255(g),
			$avh4$elm_color$Color$scaleFrom255(b),
			1.0);
	});
var $WhileTruu$elm_blurhash$Internal$calcPixel = F6(
	function (x, y, width, height, filter, lookup) {
		var folder = F3(
			function (row, column, _v2) {
				var pixel0 = _v2.a;
				var pixel1 = _v2.b;
				var pixel2 = _v2.c;
				var basis = $elm$core$Basics$cos((($elm$core$Basics$pi * x) * column) / width) * $elm$core$Basics$cos((($elm$core$Basics$pi * y) * row) / height);
				var _v1 = lookup((row * filter.W) + column);
				var colour0 = _v1.a;
				var colour1 = _v1.b;
				var colour2 = _v1.c;
				return A3($WhileTruu$elm_blurhash$Internal$Triplet, pixel0 + (colour0 * basis), pixel1 + (colour1 * basis), pixel2 + (colour2 * basis));
			});
		var _v0 = A3(
			$WhileTruu$elm_blurhash$Internal$foldDimensions,
			filter,
			folder,
			A3($WhileTruu$elm_blurhash$Internal$Triplet, 0, 0, 0));
		var r = _v0.a;
		var g = _v0.b;
		var b = _v0.c;
		return A3(
			$avh4$elm_color$Color$rgb255,
			$WhileTruu$elm_blurhash$Internal$linearToSrgb(r),
			$WhileTruu$elm_blurhash$Internal$linearToSrgb(g),
			$WhileTruu$elm_blurhash$Internal$linearToSrgb(b));
	});
var $WhileTruu$elm_blurhash$Internal$foldDimensionsReversed = F3(
	function (_v0, folder, _default) {
		var rows = _v0.H;
		var columns = _v0.W;
		var go = F3(
			function (row, column, accum) {
				go:
				while (true) {
					if (column > 0) {
						var $temp$row = row,
							$temp$column = column - 1,
							$temp$accum = A3(folder, row, column, accum);
						row = $temp$row;
						column = $temp$column;
						accum = $temp$accum;
						continue go;
					} else {
						if (row > 0) {
							var $temp$row = row - 1,
								$temp$column = columns - 1,
								$temp$accum = A3(folder, row, column, accum);
							row = $temp$row;
							column = $temp$column;
							accum = $temp$accum;
							continue go;
						} else {
							return A3(folder, row, column, accum);
						}
					}
				}
			});
		return A3(go, rows - 1, columns - 1, _default);
	});
var $WhileTruu$elm_blurhash$Internal$foldGrid = F6(
	function (width, height, punch, blurhash, folder, _default) {
		var sizeInfo = $WhileTruu$elm_blurhash$Internal$decodeBase83(
			A3($elm$core$String$slice, 0, 1, blurhash));
		var maximumValue = function () {
			var a = $WhileTruu$elm_blurhash$Internal$decodeBase83(
				A3($elm$core$String$slice, 1, 2, blurhash));
			return ((a + 1) / 166) * punch;
		}();
		var filter = {
			W: A2($elm$core$Basics$modBy, 9, sizeInfo) + 1,
			H: ((sizeInfo / 9) | 0) + 1
		};
		var lookup = A3($WhileTruu$elm_blurhash$Internal$buildDict, filter, maximumValue, blurhash);
		var toValue = F2(
			function (row, column) {
				return A6($WhileTruu$elm_blurhash$Internal$calcPixel, column, row, width, height, filter, lookup);
			});
		var dimensions = A2($WhileTruu$elm_blurhash$Internal$Dimensions, height, width);
		return A3(
			$WhileTruu$elm_blurhash$Internal$foldDimensionsReversed,
			dimensions,
			folder(toValue),
			_default);
	});
var $WhileTruu$elm_blurhash$Internal$folderList2d = F4(
	function (toValue, row, column, r) {
		var value = A2(toValue, row, column);
		if (!column) {
			return {
				at: _List_Nil,
				H: A2(
					$elm$core$List$cons,
					A2($elm$core$List$cons, value, r.at),
					r.H)
			};
		} else {
			return {
				at: A2($elm$core$List$cons, value, r.at),
				H: r.H
			};
		}
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $elm$core$Basics$round = _Basics_round;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $avh4$elm_color$Color$toRgba = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	return {aw: a, cv: b, cS: g, dt: r};
};
var $justgook$elm_image$Image$Color$colorToInt32 = function (color) {
	var record = $avh4$elm_color$Color$toRgba(color);
	var byte4 = $elm$core$Basics$round(record.dt * 255) << 24;
	var byte3 = $elm$core$Basics$round(record.cS * 255) << 16;
	var byte2 = $elm$core$Basics$round(record.cv * 255) << 8;
	var byte1 = $elm$core$Basics$round(record.aw * 255);
	return (byte4 | (byte3 | (byte1 | byte2))) >>> 0;
};
var $justgook$elm_image$Image$Info$FromData = function (a) {
	return {$: 3, a: a};
};
var $justgook$elm_image$Image$Info$FromDataBitDepth8 = 3;
var $justgook$elm_image$Image$Info$FromDataChannel4 = function (a) {
	return {$: 3, a: a};
};
var $justgook$elm_image$Image$Internal$ImageData$List2d = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $justgook$elm_image$Image$fromList2d = function (l) {
	return A2(
		$justgook$elm_image$Image$Internal$ImageData$List2d,
		$justgook$elm_image$Image$Info$FromData(
			{
				a8: $justgook$elm_image$Image$Info$FromDataChannel4(3),
				ey: $elm$core$List$length(l),
				fB: A2(
					$elm$core$Maybe$withDefault,
					0,
					A2(
						$elm$core$Maybe$map,
						$elm$core$List$length,
						$elm$core$List$head(l)))
			}),
		l);
};
var $justgook$elm_image$Image$Color$fromList2d = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$map(
		$elm$core$List$map($justgook$elm_image$Image$Color$colorToInt32)),
	$justgook$elm_image$Image$fromList2d);
var $elm$bytes$Bytes$Encode$getWidth = function (builder) {
	switch (builder.$) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 4;
		case 6:
			return 4;
		case 7:
			return 8;
		case 8:
			var w = builder.a;
			return w;
		case 9:
			var w = builder.a;
			return w;
		default:
			var bs = builder.a;
			return _Bytes_width(bs);
	}
};
var $elm$bytes$Bytes$LE = 0;
var $elm$bytes$Bytes$Encode$write = F3(
	function (builder, mb, offset) {
		switch (builder.$) {
			case 0:
				var n = builder.a;
				return A3(_Bytes_write_i8, mb, offset, n);
			case 1:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i16, mb, offset, n, !e);
			case 2:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i32, mb, offset, n, !e);
			case 3:
				var n = builder.a;
				return A3(_Bytes_write_u8, mb, offset, n);
			case 4:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u16, mb, offset, n, !e);
			case 5:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u32, mb, offset, n, !e);
			case 6:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f32, mb, offset, n, !e);
			case 7:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f64, mb, offset, n, !e);
			case 8:
				var bs = builder.b;
				return A3($elm$bytes$Bytes$Encode$writeSequence, bs, mb, offset);
			case 9:
				var s = builder.b;
				return A3(_Bytes_write_string, mb, offset, s);
			default:
				var bs = builder.a;
				return A3(_Bytes_write_bytes, mb, offset, bs);
		}
	});
var $elm$bytes$Bytes$Encode$writeSequence = F3(
	function (builders, mb, offset) {
		writeSequence:
		while (true) {
			if (!builders.b) {
				return offset;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$builders = bs,
					$temp$mb = mb,
					$temp$offset = A3($elm$bytes$Bytes$Encode$write, b, mb, offset);
				builders = $temp$builders;
				mb = $temp$mb;
				offset = $temp$offset;
				continue writeSequence;
			}
		}
	});
var $elm$bytes$Bytes$Decode$decode = F2(
	function (_v0, bs) {
		var decoder = _v0;
		return A2(_Bytes_decode, decoder, bs);
	});
var $elm$bytes$Bytes$Decode$Decoder = $elm$core$Basics$identity;
var $elm$bytes$Bytes$Decode$loopHelp = F4(
	function (state, callback, bites, offset) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var decoder = _v0;
			var _v1 = A2(decoder, bites, offset);
			var newOffset = _v1.a;
			var step = _v1.b;
			if (!step.$) {
				var newState = step.a;
				var $temp$state = newState,
					$temp$callback = callback,
					$temp$bites = bites,
					$temp$offset = newOffset;
				state = $temp$state;
				callback = $temp$callback;
				bites = $temp$bites;
				offset = $temp$offset;
				continue loopHelp;
			} else {
				var result = step.a;
				return _Utils_Tuple2(newOffset, result);
			}
		}
	});
var $elm$bytes$Bytes$Decode$loop = F2(
	function (state, callback) {
		return A2($elm$bytes$Bytes$Decode$loopHelp, state, callback);
	});
var $elm$bytes$Bytes$Decode$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$bytes$Bytes$Decode$Loop = function (a) {
	return {$: 0, a: a};
};
var $danfishgold$base64_bytes$Decode$lowest6BitsMask = 63;
var $danfishgold$base64_bytes$Decode$unsafeToChar = function (n) {
	if (n <= 25) {
		return $elm$core$Char$fromCode(65 + n);
	} else {
		if (n <= 51) {
			return $elm$core$Char$fromCode(97 + (n - 26));
		} else {
			if (n <= 61) {
				return $elm$core$Char$fromCode(48 + (n - 52));
			} else {
				switch (n) {
					case 62:
						return '+';
					case 63:
						return '/';
					default:
						return '\u0000';
				}
			}
		}
	}
};
var $danfishgold$base64_bytes$Decode$bitsToChars = F2(
	function (bits, missing) {
		var s = $danfishgold$base64_bytes$Decode$unsafeToChar(bits & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var r = $danfishgold$base64_bytes$Decode$unsafeToChar((bits >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var q = $danfishgold$base64_bytes$Decode$unsafeToChar((bits >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var p = $danfishgold$base64_bytes$Decode$unsafeToChar(bits >>> 18);
		switch (missing) {
			case 0:
				return A2(
					$elm$core$String$cons,
					p,
					A2(
						$elm$core$String$cons,
						q,
						A2(
							$elm$core$String$cons,
							r,
							$elm$core$String$fromChar(s))));
			case 1:
				return A2(
					$elm$core$String$cons,
					p,
					A2(
						$elm$core$String$cons,
						q,
						A2($elm$core$String$cons, r, '=')));
			case 2:
				return A2(
					$elm$core$String$cons,
					p,
					A2($elm$core$String$cons, q, '=='));
			default:
				return '';
		}
	});
var $danfishgold$base64_bytes$Decode$bitsToCharSpecialized = F4(
	function (bits1, bits2, bits3, accum) {
		var z = $danfishgold$base64_bytes$Decode$unsafeToChar((bits3 >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var y = $danfishgold$base64_bytes$Decode$unsafeToChar((bits3 >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var x = $danfishgold$base64_bytes$Decode$unsafeToChar(bits3 >>> 18);
		var w = $danfishgold$base64_bytes$Decode$unsafeToChar(bits3 & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var s = $danfishgold$base64_bytes$Decode$unsafeToChar(bits1 & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var r = $danfishgold$base64_bytes$Decode$unsafeToChar((bits1 >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var q = $danfishgold$base64_bytes$Decode$unsafeToChar((bits1 >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var p = $danfishgold$base64_bytes$Decode$unsafeToChar(bits1 >>> 18);
		var d = $danfishgold$base64_bytes$Decode$unsafeToChar(bits2 & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var c = $danfishgold$base64_bytes$Decode$unsafeToChar((bits2 >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var b = $danfishgold$base64_bytes$Decode$unsafeToChar((bits2 >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var a = $danfishgold$base64_bytes$Decode$unsafeToChar(bits2 >>> 18);
		return A2(
			$elm$core$String$cons,
			x,
			A2(
				$elm$core$String$cons,
				y,
				A2(
					$elm$core$String$cons,
					z,
					A2(
						$elm$core$String$cons,
						w,
						A2(
							$elm$core$String$cons,
							a,
							A2(
								$elm$core$String$cons,
								b,
								A2(
									$elm$core$String$cons,
									c,
									A2(
										$elm$core$String$cons,
										d,
										A2(
											$elm$core$String$cons,
											p,
											A2(
												$elm$core$String$cons,
												q,
												A2(
													$elm$core$String$cons,
													r,
													A2($elm$core$String$cons, s, accum))))))))))));
	});
var $danfishgold$base64_bytes$Decode$decode18Help = F5(
	function (a, b, c, d, e) {
		var combined6 = ((255 & d) << 16) | e;
		var combined5 = d >>> 8;
		var combined4 = 16777215 & c;
		var combined3 = ((65535 & b) << 8) | (c >>> 24);
		var combined2 = ((255 & a) << 16) | (b >>> 16);
		var combined1 = a >>> 8;
		return A4(
			$danfishgold$base64_bytes$Decode$bitsToCharSpecialized,
			combined3,
			combined2,
			combined1,
			A4($danfishgold$base64_bytes$Decode$bitsToCharSpecialized, combined6, combined5, combined4, ''));
	});
var $elm$bytes$Bytes$Decode$map5 = F6(
	function (func, _v0, _v1, _v2, _v3, _v4) {
		var decodeA = _v0;
		var decodeB = _v1;
		var decodeC = _v2;
		var decodeD = _v3;
		var decodeE = _v4;
		return F2(
			function (bites, offset) {
				var _v5 = A2(decodeA, bites, offset);
				var aOffset = _v5.a;
				var a = _v5.b;
				var _v6 = A2(decodeB, bites, aOffset);
				var bOffset = _v6.a;
				var b = _v6.b;
				var _v7 = A2(decodeC, bites, bOffset);
				var cOffset = _v7.a;
				var c = _v7.b;
				var _v8 = A2(decodeD, bites, cOffset);
				var dOffset = _v8.a;
				var d = _v8.b;
				var _v9 = A2(decodeE, bites, dOffset);
				var eOffset = _v9.a;
				var e = _v9.b;
				return _Utils_Tuple2(
					eOffset,
					A5(func, a, b, c, d, e));
			});
	});
var $elm$bytes$Bytes$BE = 1;
var $elm$bytes$Bytes$Decode$unsignedInt16 = function (endianness) {
	return _Bytes_read_u16(!endianness);
};
var $danfishgold$base64_bytes$Decode$u16BE = $elm$bytes$Bytes$Decode$unsignedInt16(1);
var $elm$bytes$Bytes$Decode$unsignedInt32 = function (endianness) {
	return _Bytes_read_u32(!endianness);
};
var $danfishgold$base64_bytes$Decode$u32BE = $elm$bytes$Bytes$Decode$unsignedInt32(1);
var $danfishgold$base64_bytes$Decode$decode18Bytes = A6($elm$bytes$Bytes$Decode$map5, $danfishgold$base64_bytes$Decode$decode18Help, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u16BE);
var $elm$bytes$Bytes$Decode$map = F2(
	function (func, _v0) {
		var decodeA = _v0;
		return F2(
			function (bites, offset) {
				var _v1 = A2(decodeA, bites, offset);
				var aOffset = _v1.a;
				var a = _v1.b;
				return _Utils_Tuple2(
					aOffset,
					func(a));
			});
	});
var $elm$bytes$Bytes$Decode$map2 = F3(
	function (func, _v0, _v1) {
		var decodeA = _v0;
		var decodeB = _v1;
		return F2(
			function (bites, offset) {
				var _v2 = A2(decodeA, bites, offset);
				var aOffset = _v2.a;
				var a = _v2.b;
				var _v3 = A2(decodeB, bites, aOffset);
				var bOffset = _v3.a;
				var b = _v3.b;
				return _Utils_Tuple2(
					bOffset,
					A2(func, a, b));
			});
	});
var $elm$bytes$Bytes$Decode$map3 = F4(
	function (func, _v0, _v1, _v2) {
		var decodeA = _v0;
		var decodeB = _v1;
		var decodeC = _v2;
		return F2(
			function (bites, offset) {
				var _v3 = A2(decodeA, bites, offset);
				var aOffset = _v3.a;
				var a = _v3.b;
				var _v4 = A2(decodeB, bites, aOffset);
				var bOffset = _v4.a;
				var b = _v4.b;
				var _v5 = A2(decodeC, bites, bOffset);
				var cOffset = _v5.a;
				var c = _v5.b;
				return _Utils_Tuple2(
					cOffset,
					A3(func, a, b, c));
			});
	});
var $elm$bytes$Bytes$Decode$succeed = function (a) {
	return F2(
		function (_v0, offset) {
			return _Utils_Tuple2(offset, a);
		});
};
var $elm$bytes$Bytes$Decode$unsignedInt8 = _Bytes_read_u8;
var $danfishgold$base64_bytes$Decode$loopHelp = function (_v0) {
	var remaining = _v0.bt;
	var string = _v0.by;
	if (remaining >= 18) {
		return A2(
			$elm$bytes$Bytes$Decode$map,
			function (result) {
				return $elm$bytes$Bytes$Decode$Loop(
					{
						bt: remaining - 18,
						by: _Utils_ap(string, result)
					});
			},
			$danfishgold$base64_bytes$Decode$decode18Bytes);
	} else {
		if (remaining >= 3) {
			var helper = F3(
				function (a, b, c) {
					var combined = ((a << 16) | (b << 8)) | c;
					return $elm$bytes$Bytes$Decode$Loop(
						{
							bt: remaining - 3,
							by: _Utils_ap(
								string,
								A2($danfishgold$base64_bytes$Decode$bitsToChars, combined, 0))
						});
				});
			return A4($elm$bytes$Bytes$Decode$map3, helper, $elm$bytes$Bytes$Decode$unsignedInt8, $elm$bytes$Bytes$Decode$unsignedInt8, $elm$bytes$Bytes$Decode$unsignedInt8);
		} else {
			if (!remaining) {
				return $elm$bytes$Bytes$Decode$succeed(
					$elm$bytes$Bytes$Decode$Done(string));
			} else {
				if (remaining === 2) {
					var helper = F2(
						function (a, b) {
							var combined = (a << 16) | (b << 8);
							return $elm$bytes$Bytes$Decode$Done(
								_Utils_ap(
									string,
									A2($danfishgold$base64_bytes$Decode$bitsToChars, combined, 1)));
						});
					return A3($elm$bytes$Bytes$Decode$map2, helper, $elm$bytes$Bytes$Decode$unsignedInt8, $elm$bytes$Bytes$Decode$unsignedInt8);
				} else {
					return A2(
						$elm$bytes$Bytes$Decode$map,
						function (a) {
							return $elm$bytes$Bytes$Decode$Done(
								_Utils_ap(
									string,
									A2($danfishgold$base64_bytes$Decode$bitsToChars, a << 16, 2)));
						},
						$elm$bytes$Bytes$Decode$unsignedInt8);
				}
			}
		}
	}
};
var $danfishgold$base64_bytes$Decode$decoder = function (width) {
	return A2(
		$elm$bytes$Bytes$Decode$loop,
		{bt: width, by: ''},
		$danfishgold$base64_bytes$Decode$loopHelp);
};
var $elm$bytes$Bytes$width = _Bytes_width;
var $danfishgold$base64_bytes$Decode$fromBytes = function (bytes) {
	return A2(
		$elm$bytes$Bytes$Decode$decode,
		$danfishgold$base64_bytes$Decode$decoder(
			$elm$bytes$Bytes$width(bytes)),
		bytes);
};
var $danfishgold$base64_bytes$Base64$fromBytes = $danfishgold$base64_bytes$Decode$fromBytes;
var $justgook$elm_image$Image$Info$FromDataChannel3 = function (a) {
	return {$: 2, a: a};
};
var $elm$bytes$Bytes$Encode$encode = _Bytes_encode;
var $justgook$elm_image$Image$Internal$ImageData$bytesPerPixel = function (meta) {
	switch (meta.$) {
		case 0:
			var color = meta.a.a8;
			switch (color.$) {
				case 0:
					return 1;
				case 1:
					return 2;
				case 2:
					return 3;
				case 3:
					return 4;
				default:
					return 4;
			}
		case 1:
			var bitsPerPixel = meta.a.d0;
			switch (bitsPerPixel) {
				case 0:
					return 1;
				case 1:
					return 2;
				case 2:
					return 3;
				default:
					return 4;
			}
		case 3:
			var color = meta.a.a8;
			switch (color.$) {
				case 0:
					return 1;
				case 1:
					return 2;
				case 2:
					return 3;
				default:
					return 4;
			}
		default:
			return 1;
	}
};
var $justgook$elm_image$Image$Internal$ImageData$getInfo = function (image) {
	switch (image.$) {
		case 4:
			var meta = image.a;
			return meta;
		case 3:
			var meta = image.a;
			return meta;
		case 1:
			var meta = image.a;
			return meta;
		case 2:
			var meta = image.a;
			return meta;
		default:
			var meta = image.a;
			return meta;
	}
};
var $elm$bytes$Bytes$Encode$U16 = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$unsignedInt16 = $elm$bytes$Bytes$Encode$U16;
var $elm$bytes$Bytes$Encode$Seq = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$getWidths = F2(
	function (width, builders) {
		getWidths:
		while (true) {
			if (!builders.b) {
				return width;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$width = width + $elm$bytes$Bytes$Encode$getWidth(b),
					$temp$builders = bs;
				width = $temp$width;
				builders = $temp$builders;
				continue getWidths;
			}
		}
	});
var $elm$bytes$Bytes$Encode$sequence = function (builders) {
	return A2(
		$elm$bytes$Bytes$Encode$Seq,
		A2($elm$bytes$Bytes$Encode$getWidths, 0, builders),
		builders);
};
var $elm$bytes$Bytes$Encode$U8 = function (a) {
	return {$: 3, a: a};
};
var $elm$bytes$Bytes$Encode$unsignedInt8 = $elm$bytes$Bytes$Encode$U8;
var $justgook$elm_image$Image$Internal$Encode$unsignedInt24 = F2(
	function (endian, num) {
		return (!endian) ? $elm$bytes$Bytes$Encode$sequence(
			_List_fromArray(
				[
					$elm$bytes$Bytes$Encode$unsignedInt8(num & 255),
					$elm$bytes$Bytes$Encode$unsignedInt8((num & 65280) >> 8),
					$elm$bytes$Bytes$Encode$unsignedInt8((num & 16711680) >> 16)
				])) : $elm$bytes$Bytes$Encode$sequence(
			_List_fromArray(
				[
					$elm$bytes$Bytes$Encode$unsignedInt8((num & 16711680) >> 16),
					$elm$bytes$Bytes$Encode$unsignedInt8((num & 65280) >> 8),
					$elm$bytes$Bytes$Encode$unsignedInt8(num & 255)
				]));
	});
var $elm$bytes$Bytes$Encode$U32 = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$unsignedInt32 = $elm$bytes$Bytes$Encode$U32;
var $justgook$elm_image$Image$Internal$BMP$intToBytes = function (bpp) {
	switch (bpp) {
		case 1:
			return $elm$bytes$Bytes$Encode$unsignedInt8;
		case 2:
			return $elm$bytes$Bytes$Encode$unsignedInt16(0);
		case 3:
			return $justgook$elm_image$Image$Internal$Encode$unsignedInt24(0);
		default:
			return $elm$bytes$Bytes$Encode$unsignedInt32(0);
	}
};
var $justgook$elm_image$Image$Info$dimensions = function (meta) {
	switch (meta.$) {
		case 0:
			var width = meta.a.fB;
			var height = meta.a.ey;
			return {ey: height, fB: width};
		case 1:
			var width = meta.a.fB;
			var height = meta.a.ey;
			return {ey: height, fB: width};
		case 2:
			var width = meta.a.fB;
			var height = meta.a.ey;
			return {ey: height, fB: width};
		default:
			var width = meta.a.fB;
			var height = meta.a.ey;
			return {ey: height, fB: width};
	}
};
var $justgook$elm_image$Image$Internal$ImageData$dimensions = function (image) {
	return $justgook$elm_image$Image$Info$dimensions(
		$justgook$elm_image$Image$Internal$ImageData$getInfo(image));
};
var $justgook$elm_image$Image$Internal$ImageData$width = function (image) {
	return $justgook$elm_image$Image$Internal$ImageData$dimensions(image).fB;
};
var $justgook$elm_image$Image$Internal$BMP$encodeConfig = function (image) {
	var width = $justgook$elm_image$Image$Internal$ImageData$width(image);
	var bytesPerPixel = $justgook$elm_image$Image$Internal$ImageData$bytesPerPixel(
		$justgook$elm_image$Image$Internal$ImageData$getInfo(image));
	var encoder = $justgook$elm_image$Image$Internal$BMP$intToBytes(bytesPerPixel);
	var extraBytes = ((4 - ((width * bytesPerPixel) & bytesPerPixel)) & bytesPerPixel) % 4;
	return {
		a5: bytesPerPixel,
		bQ: A2(
			$elm$core$List$repeat,
			width,
			encoder(0)),
		bU: encoder,
		bV: extraBytes,
		bY: bytesPerPixel === 4,
		bp: true,
		b7: false,
		fB: width
	};
};
var $justgook$elm_image$Image$Internal$BMP$addRowPadding = F2(
	function (n, acc) {
		switch (n) {
			case 1:
				return A2(
					$elm$core$List$cons,
					$elm$bytes$Bytes$Encode$unsignedInt8(0),
					acc);
			case 2:
				return A2(
					$elm$core$List$cons,
					A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, 0),
					acc);
			case 3:
				return A2(
					$elm$core$List$cons,
					A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, 0),
					A2(
						$elm$core$List$cons,
						$elm$bytes$Bytes$Encode$unsignedInt8(0),
						acc));
			default:
				return A2(
					$elm$core$List$cons,
					A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
					acc);
		}
	});
var $justgook$elm_image$Image$Internal$BMP$encodeRow = F4(
	function (f, items, i, acc) {
		encodeRow:
		while (true) {
			if (items.b) {
				var px = items.a;
				var rest = items.b;
				var $temp$f = f,
					$temp$items = rest,
					$temp$i = i + 1,
					$temp$acc = A2(
					$elm$core$List$cons,
					f(px),
					acc);
				f = $temp$f;
				items = $temp$items;
				i = $temp$i;
				acc = $temp$acc;
				continue encodeRow;
			} else {
				return {at: acc, fB: i};
			}
		}
	});
var $justgook$elm_image$Image$Internal$BMP$header16_24 = F5(
	function (bitsPerPixel, w, h, dataSize, accum) {
		return A2(
			$elm$core$List$cons,
			A2($elm$bytes$Bytes$Encode$unsignedInt16, 1, 16973),
			A2(
				$elm$core$List$cons,
				A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 54 + dataSize),
				A2(
					$elm$core$List$cons,
					A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
					A2(
						$elm$core$List$cons,
						A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 14 + 40),
						A2(
							$elm$core$List$cons,
							A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 40),
							A2(
								$elm$core$List$cons,
								A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, w),
								A2(
									$elm$core$List$cons,
									A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, h),
									A2(
										$elm$core$List$cons,
										A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, 1),
										A2(
											$elm$core$List$cons,
											A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, bitsPerPixel),
											A2(
												$elm$core$List$cons,
												A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
												A2(
													$elm$core$List$cons,
													A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, dataSize),
													A2(
														$elm$core$List$cons,
														A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 2835),
														A2(
															$elm$core$List$cons,
															A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 2835),
															A2(
																$elm$core$List$cons,
																A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
																A2(
																	$elm$core$List$cons,
																	A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
																	accum)))))))))))))));
	});
var $elm$bytes$Bytes$Encode$Bytes = function (a) {
	return {$: 10, a: a};
};
var $elm$bytes$Bytes$Encode$bytes = $elm$bytes$Bytes$Encode$Bytes;
var $justgook$elm_image$Image$Internal$BMP$staticHeaderPart = A3(
	$elm$core$Basics$composeR,
	$elm$bytes$Bytes$Encode$sequence,
	$elm$bytes$Bytes$Encode$encode,
	_List_fromArray(
		[
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 2835),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 2835),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 4278190080),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 16711680),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 65280),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 255),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 544106839),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0)
		]));
var $justgook$elm_image$Image$Internal$BMP$header32 = F4(
	function (w, h, dataSize, accum) {
		return A2(
			$elm$core$List$cons,
			A2($elm$bytes$Bytes$Encode$unsignedInt16, 1, 16973),
			A2(
				$elm$core$List$cons,
				A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 122 + dataSize),
				A2(
					$elm$core$List$cons,
					A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
					A2(
						$elm$core$List$cons,
						A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 122),
						A2(
							$elm$core$List$cons,
							A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 108),
							A2(
								$elm$core$List$cons,
								A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, w),
								A2(
									$elm$core$List$cons,
									A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, h),
									A2(
										$elm$core$List$cons,
										A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, 1),
										A2(
											$elm$core$List$cons,
											A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, 32),
											A2(
												$elm$core$List$cons,
												A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 3),
												A2(
													$elm$core$List$cons,
													A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, dataSize),
													A2(
														$elm$core$List$cons,
														$elm$bytes$Bytes$Encode$bytes($justgook$elm_image$Image$Internal$BMP$staticHeaderPart),
														accum))))))))))));
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $justgook$elm_image$Image$Internal$BMP$encodeFolder = F5(
	function (config, remaining, height, totalBytes, acc) {
		encodeFolder:
		while (true) {
			var width = config.fB;
			var extraBytes = config.bV;
			if (remaining.b) {
				var row = remaining.a;
				var rest = remaining.b;
				var initial = A4($justgook$elm_image$Image$Internal$BMP$encodeRow, config.bU, row, 0, _List_Nil);
				var encoded = function () {
					var padding = width - initial.fB;
					if (!padding) {
						return initial.at;
					} else {
						return _Utils_ap(
							A2($elm$core$List$take, padding, config.bQ),
							initial.at);
					}
				}();
				var withRow = function () {
					if (!extraBytes) {
						return config.bp ? A2(
							$elm$core$List$cons,
							$elm$bytes$Bytes$Encode$sequence(
								$elm$core$List$reverse(encoded)),
							acc) : A2(
							$elm$core$List$cons,
							$elm$bytes$Bytes$Encode$sequence(encoded),
							acc);
					} else {
						return config.bp ? A2(
							$elm$core$List$cons,
							$elm$bytes$Bytes$Encode$sequence(
								$elm$core$List$reverse(
									A2($justgook$elm_image$Image$Internal$BMP$addRowPadding, extraBytes, encoded))),
							acc) : A2(
							$elm$core$List$cons,
							$elm$bytes$Bytes$Encode$sequence(
								_Utils_ap(
									encoded,
									A2($justgook$elm_image$Image$Internal$BMP$addRowPadding, extraBytes, _List_Nil))),
							acc);
					}
				}();
				var $temp$config = config,
					$temp$remaining = rest,
					$temp$height = height + 1,
					$temp$totalBytes = (totalBytes + (width * config.a5)) + extraBytes,
					$temp$acc = withRow;
				config = $temp$config;
				remaining = $temp$remaining;
				height = $temp$height;
				totalBytes = $temp$totalBytes;
				acc = $temp$acc;
				continue encodeFolder;
			} else {
				var body = config.b7 ? $elm$core$List$reverse(acc) : acc;
				return config.bY ? A4($justgook$elm_image$Image$Internal$BMP$header32, width, height, totalBytes, body) : A5($justgook$elm_image$Image$Internal$BMP$header16_24, 8 * config.a5, width, height, totalBytes, body);
			}
		}
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $justgook$elm_image$Image$Internal$ImageData$greedyGroupsOfWithStep = F3(
	function (size, step, xs) {
		var xs_ = A2($elm$core$List$drop, step, xs);
		var okayXs = $elm$core$List$length(xs) > 0;
		var okayArgs = (size > 0) && (step > 0);
		return (okayArgs && okayXs) ? A2(
			$elm$core$List$cons,
			A2($elm$core$List$take, size, xs),
			A3($justgook$elm_image$Image$Internal$ImageData$greedyGroupsOfWithStep, size, step, xs_)) : _List_Nil;
	});
var $justgook$elm_image$Image$Internal$ImageData$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($justgook$elm_image$Image$Internal$ImageData$greedyGroupsOfWithStep, size, size, xs);
	});
var $justgook$elm_image$Image$Internal$ImageData$toList2d = function (info) {
	toList2d:
	while (true) {
		switch (info.$) {
			case 0:
				var meta = info.a;
				var l = info.b;
				return A2(
					$justgook$elm_image$Image$Internal$ImageData$greedyGroupsOf,
					$justgook$elm_image$Image$Info$dimensions(meta).fB,
					l);
			case 1:
				var l = info.b;
				return l;
			case 2:
				var meta = info.a;
				var arr = info.b;
				return A2(
					$justgook$elm_image$Image$Internal$ImageData$greedyGroupsOf,
					$justgook$elm_image$Image$Info$dimensions(meta).fB,
					$elm$core$Array$toList(arr));
			case 3:
				var arr = info.b;
				return A3(
					$elm$core$Array$foldr,
					F2(
						function (line, acc1) {
							return function (l) {
								return A2($elm$core$List$cons, l, acc1);
							}(
								A3(
									$elm$core$Array$foldr,
									F2(
										function (px, acc2) {
											return A2($elm$core$List$cons, px, acc2);
										}),
									_List_Nil,
									line));
						}),
					_List_Nil,
					arr);
			default:
				var d = info.b;
				var b = info.c;
				var _v1 = A2($elm$bytes$Bytes$Decode$decode, d, b);
				if (!_v1.$) {
					if (_v1.a.$ === 4) {
						var _v2 = _v1.a;
						return _List_Nil;
					} else {
						var newData = _v1.a;
						var $temp$info = newData;
						info = $temp$info;
						continue toList2d;
					}
				} else {
					return _List_Nil;
				}
		}
	}
};
var $justgook$elm_image$Image$Internal$BMP$encode = function (image) {
	return $elm$bytes$Bytes$Encode$encode(
		$elm$bytes$Bytes$Encode$sequence(
			A5(
				$justgook$elm_image$Image$Internal$BMP$encodeFolder,
				$justgook$elm_image$Image$Internal$BMP$encodeConfig(image),
				$justgook$elm_image$Image$Internal$ImageData$toList2d(image),
				0,
				0,
				_List_Nil)));
};
var $justgook$elm_image$Image$Internal$ImageData$Array = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $justgook$elm_image$Image$Internal$ImageData$Array2d = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $justgook$elm_image$Image$Internal$ImageData$List = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $justgook$elm_image$Image$Internal$ImageData$toFromData = F2(
	function (color, meta) {
		var dim = $justgook$elm_image$Image$Info$dimensions(meta);
		return $justgook$elm_image$Image$Info$FromData(
			{a8: color, ey: dim.ey, fB: dim.fB});
	});
var $justgook$elm_image$Image$Internal$ImageData$forceColor = F2(
	function (color, image) {
		forceColor:
		while (true) {
			switch (image.$) {
				case 0:
					var meta = image.a;
					var im = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$List,
						A2($justgook$elm_image$Image$Internal$ImageData$toFromData, color, meta),
						im);
				case 1:
					var meta = image.a;
					var im = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$List2d,
						A2($justgook$elm_image$Image$Internal$ImageData$toFromData, color, meta),
						im);
				case 2:
					var meta = image.a;
					var im = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$Array,
						A2($justgook$elm_image$Image$Internal$ImageData$toFromData, color, meta),
						im);
				case 3:
					var meta = image.a;
					var im = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$Array2d,
						A2($justgook$elm_image$Image$Internal$ImageData$toFromData, color, meta),
						im);
				default:
					var d = image.b;
					var b = image.c;
					var _v1 = A2($elm$bytes$Bytes$Decode$decode, d, b);
					if (!_v1.$) {
						if (_v1.a.$ === 4) {
							var _v2 = _v1.a;
							return image;
						} else {
							var newData = _v1.a;
							var $temp$color = color,
								$temp$image = newData;
							color = $temp$color;
							image = $temp$image;
							continue forceColor;
						}
					} else {
						return image;
					}
			}
		}
	});
var $justgook$elm_image$Image$Internal$ImageData$Bytes = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $elm$core$Elm$JsArray$map = _JsArray_map;
var $elm$core$Array$map = F2(
	function (func, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = function (node) {
			if (!node.$) {
				var subTree = node.a;
				return $elm$core$Array$SubTree(
					A2($elm$core$Elm$JsArray$map, helper, subTree));
			} else {
				var values = node.a;
				return $elm$core$Array$Leaf(
					A2($elm$core$Elm$JsArray$map, func, values));
			}
		};
		return A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A2($elm$core$Elm$JsArray$map, helper, tree),
			A2($elm$core$Elm$JsArray$map, func, tail));
	});
var $justgook$elm_image$Image$Internal$ImageData$map = F2(
	function (fn, image) {
		map:
		while (true) {
			switch (image.$) {
				case 0:
					var meta = image.a;
					var l = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$List,
						meta,
						A2($elm$core$List$map, fn, l));
				case 1:
					var meta = image.a;
					var l = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$List2d,
						meta,
						A2(
							$elm$core$List$map,
							$elm$core$List$map(fn),
							l));
				case 2:
					var meta = image.a;
					var arr = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$Array,
						meta,
						A2($elm$core$Array$map, fn, arr));
				case 3:
					var meta = image.a;
					var arr = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$Array2d,
						meta,
						A2(
							$elm$core$Array$map,
							$elm$core$Array$map(fn),
							arr));
				default:
					var meta = image.a;
					var d = image.b;
					var b = image.c;
					var _v1 = A2($elm$bytes$Bytes$Decode$decode, d, b);
					if (!_v1.$) {
						if (_v1.a.$ === 4) {
							var _v2 = _v1.a;
							return A3($justgook$elm_image$Image$Internal$ImageData$Bytes, meta, d, b);
						} else {
							var newData = _v1.a;
							var $temp$fn = fn,
								$temp$image = newData;
							fn = $temp$fn;
							image = $temp$image;
							continue map;
						}
					} else {
						return A3($justgook$elm_image$Image$Internal$ImageData$Bytes, meta, d, b);
					}
			}
		}
	});
var $justgook$elm_image$Image$Internal$Pixel$dropChannel = $justgook$elm_image$Image$Internal$ImageData$map(
	$elm$core$Bitwise$shiftRightZfBy(8));
var $justgook$elm_image$Image$Internal$Pixel$px8AlphaTo24 = function (px_) {
	var px = px_ >>> 8;
	return px + ((px + (px << 8)) << 8);
};
var $justgook$elm_image$Image$Internal$Pixel$px8to24 = function (px) {
	return px + ((px + ((px + (px << 8)) << 8)) << 8);
};
var $justgook$elm_image$Image$Internal$Pixel$toBit24 = function (image) {
	var _v0 = $justgook$elm_image$Image$Internal$ImageData$getInfo(image);
	switch (_v0.$) {
		case 0:
			var color = _v0.a.a8;
			switch (color.$) {
				case 0:
					return $justgook$elm_image$Image$Internal$Pixel$dropChannel(image);
				case 2:
					return $justgook$elm_image$Image$Internal$Pixel$dropChannel(image);
				case 4:
					return $justgook$elm_image$Image$Internal$Pixel$dropChannel(image);
				case 1:
					return A2($justgook$elm_image$Image$Internal$ImageData$map, $justgook$elm_image$Image$Internal$Pixel$px8AlphaTo24, image);
				default:
					return $justgook$elm_image$Image$Internal$Pixel$dropChannel(image);
			}
		case 1:
			var bitsPerPixel = _v0.a.d0;
			switch (bitsPerPixel) {
				case 0:
					return A2($justgook$elm_image$Image$Internal$ImageData$map, $justgook$elm_image$Image$Internal$Pixel$px8to24, image);
				case 1:
					return image;
				case 2:
					return image;
				default:
					return $justgook$elm_image$Image$Internal$Pixel$dropChannel(image);
			}
		case 2:
			return image;
		default:
			var color = _v0.a.a8;
			switch (color.$) {
				case 0:
					return image;
				case 1:
					return image;
				case 2:
					return image;
				default:
					if (color.a === 3) {
						var _v4 = color.a;
						return $justgook$elm_image$Image$Internal$Pixel$dropChannel(image);
					} else {
						return image;
					}
			}
	}
};
var $justgook$elm_image$Image$Advanced$toBmp24 = A2(
	$elm$core$Basics$composeR,
	$justgook$elm_image$Image$Internal$Pixel$toBit24,
	A2(
		$elm$core$Basics$composeR,
		$justgook$elm_image$Image$Internal$ImageData$forceColor(
			$justgook$elm_image$Image$Info$FromDataChannel3(3)),
		$justgook$elm_image$Image$Internal$BMP$encode));
var $justgook$elm_image$Image$toBmpUrl = A2(
	$elm$core$Basics$composeR,
	$justgook$elm_image$Image$Advanced$toBmp24,
	A2(
		$elm$core$Basics$composeR,
		$danfishgold$base64_bytes$Base64$fromBytes,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$withDefault(''),
			$elm$core$Basics$append('data:image/bmp;base64,'))));
var $WhileTruu$elm_blurhash$Internal$toUri = F3(
	function (_v0, punch, blurhash) {
		var width = _v0.fB;
		var height = _v0.ey;
		return $justgook$elm_image$Image$toBmpUrl(
			$justgook$elm_image$Image$Color$fromList2d(
				A6(
					$WhileTruu$elm_blurhash$Internal$foldGrid,
					width,
					height,
					punch,
					blurhash,
					$WhileTruu$elm_blurhash$Internal$folderList2d,
					{at: _List_Nil, H: _List_Nil}).H));
	});
var $WhileTruu$elm_blurhash$BlurHash$toUri = $WhileTruu$elm_blurhash$Internal$toUri;
var $author$project$Components$FeaturedBotBig$featuredBotBig = function (model) {
	var _v0 = model.eE;
	if (_v0.$ === 1) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('featuredBotBig tileLoading')
				]),
			_List_Nil);
	} else {
		var indexData = _v0.a;
		var uri = A3(
			$WhileTruu$elm_blurhash$BlurHash$toUri,
			{ey: 30, fB: 30},
			1.0,
			indexData.aO.d1);
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('featuredBotBig featuredBotTileHighlight '),
					A2($elm$html$Html$Attributes$style, 'background-image', 'url(' + (uri + ')')),
					A2($elm$html$Html$Attributes$style, 'background-size', 'cover'),
					$elm$html$Html$Attributes$href(
					'/bot/' + $author$project$Model$tmpBotId(indexData.aO))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('featuredBotTileContent')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('featuredBotBigProfile tileLoading')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											$author$project$Model$profileImageUrl(indexData.aO.e4)),
											$elm$html$Html$Attributes$class('featuredBotProfileImg')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('featuredBotTileContentSpacer')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$h3,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('featuredBotTileTitle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(indexData.aO.fr)
								])),
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(indexData.aO.fg)
								]))
						]))
				]));
	}
};
var $author$project$Components$FeaturedBotMid$featuredBotMid = function (model) {
	var uri = A3(
		$WhileTruu$elm_blurhash$BlurHash$toUri,
		{ey: 30, fB: 30},
		1.0,
		model.d1);
	return A2(
		$elm$html$Html$a,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('featuredBotMid featuredBotTileHighlight'),
				A2($elm$html$Html$Attributes$style, 'background-image', 'url(' + (uri + ')')),
				A2($elm$html$Html$Attributes$style, 'background-size', 'cover'),
				$elm$html$Html$Attributes$href(
				'/bot/' + $author$project$Model$tmpBotId(model))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('featuredBotTileContent')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('featuredBotBigProfile featuredBotMidProfile tileLoading')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$Model$profileImageUrl(model.e4)),
										$elm$html$Html$Attributes$class('featuredBotProfileImg')
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('featuredBotTileContentSpacer')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$h3,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('featuredBotTileTitle featuredBotMidTileTitle')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(model.fr)
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(model.fg)
							]))
					]))
			]));
};
var $author$project$Components$FeaturedBotMid$featuredBotsMid = function (model) {
	var _v0 = model.eE;
	if (!_v0.$) {
		var bots = _v0.a;
		return A2($elm$core$List$map, $author$project$Components$FeaturedBotMid$featuredBotMid, bots.eN);
	} else {
		return A2(
			$elm$core$List$repeat,
			2,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('featuredBotMid tileLoading')
					]),
				_List_Nil));
	}
};
var $author$project$Model$NewBotsPageChanged = function (a) {
	return {$: 60, a: a};
};
var $author$project$Pages$Index$pageIndicatorSelectedClass = function (selected) {
	return selected ? ' stdButtonForcePressed ' : '';
};
var $author$project$Pages$Index$pageIndicator = F2(
	function (selected, i) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					'stdButton stdButtonMargin stdButtonSmallPadding' + $author$project$Pages$Index$pageIndicatorSelectedClass(
						_Utils_eq(selected, i))),
					$elm$html$Html$Events$onClick(
					$author$project$Model$NewBotsPageChanged(i))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$elm$core$String$fromInt(i))
				]));
	});
var $author$project$Pages$Index$botComponentParts = F2(
	function (indexPage, model) {
		return ($elm$core$List$length(indexPage.bk) > 6) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('botComponentParts')
				]),
			A2(
				$elm$core$List$map,
				function (i) {
					return A2($author$project$Pages$Index$pageIndicator, indexPage.bv, i);
				},
				A2(
					$elm$core$List$range,
					1,
					($elm$core$List$length(indexPage.bk) / 6) | 0))) : A2($elm$html$Html$div, _List_Nil, _List_Nil);
	});
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $elm_community$list_extra$List$Extra$groupsOfWithStep = F3(
	function (size, step, xs) {
		var xs_ = A2($elm$core$List$drop, step, xs);
		var thisGroup = A2($elm$core$List$take, size, xs);
		var okayLength = _Utils_eq(
			size,
			$elm$core$List$length(thisGroup));
		var okayArgs = (size > 0) && (step > 0);
		return (okayArgs && okayLength) ? A2(
			$elm$core$List$cons,
			thisGroup,
			A3($elm_community$list_extra$List$Extra$groupsOfWithStep, size, step, xs_)) : _List_Nil;
	});
var $elm_community$list_extra$List$Extra$groupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$groupsOfWithStep, size, size, xs);
	});
var $author$project$Pages$Index$getBotsPage = F2(
	function (page, botPreviews) {
		var correctedPageNumber = page - 1;
		var botPreviewPages = A2($elm_community$list_extra$List$Extra$groupsOf, 6, botPreviews);
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2($elm_community$list_extra$List$Extra$getAt, correctedPageNumber, botPreviewPages));
	});
var $author$project$Pages$Index$indexLoadingGuard = function (model) {
	var _v0 = model.eE;
	if (!_v0.$) {
		var indexPage = _v0.a;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('botBar')
						]),
					A2($elm$core$List$map, $author$project$Components$BotComponent$botComponent, indexPage.a3)),
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('featuredBotsTitle bestRatedTitleMargin')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('New bots:')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('botBar')
						]),
					A2(
						$elm$core$List$map,
						$author$project$Components$BotComponent$botComponent,
						A2($author$project$Pages$Index$getBotsPage, indexPage.bv, indexPage.bk))),
					A2($author$project$Pages$Index$botComponentParts, indexPage, model)
				]));
	} else {
		return $author$project$Components$LoadingIndicator$loadingIndicator;
	}
};
var $author$project$Pages$Index$index = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('centerFlexRow'),
				A2($elm$html$Html$Attributes$style, 'flex-grow', '1')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('content')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('featuredBotContainerWithLabel')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('featuredBotsTitle')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Featured bots:')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('featuredBotContainerParent')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('featuredBotContainer')
											]),
										_List_fromArray(
											[
												$author$project$Components$FeaturedBotBig$featuredBotBig(model),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('featuredBotContainerSide')
													]),
												$author$project$Components$FeaturedBotMid$featuredBotsMid(model))
											]))
									]))
							])),
						A2(
						$elm$html$Html$h1,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('featuredBotsTitle bestRatedTitleMargin')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Best rated bots:')
							])),
						$author$project$Pages$Index$indexLoadingGuard(model)
					]))
			]));
};
var $author$project$Model$NewBotCreatorAdditionalNotes = function (a) {
	return {$: 15, a: a};
};
var $author$project$Model$NewBotCreatorCategoryUpdated = function (a) {
	return {$: 19, a: a};
};
var $author$project$Model$NewBotCreatorDescriptionUpdated = function (a) {
	return {$: 9, a: a};
};
var $author$project$Model$NewBotCreatorEntryPointUpdated = function (a) {
	return {$: 13, a: a};
};
var $author$project$Model$NewBotCreatorImageRequested = {$: 10};
var $author$project$Model$NewBotCreatorInviteLinkUpdated = function (a) {
	return {$: 14, a: a};
};
var $author$project$Model$NewBotCreatorShortDescriptionUpdated = function (a) {
	return {$: 18, a: a};
};
var $author$project$Model$NewBotCreatorSubmitForm = {$: 16};
var $author$project$Model$NewBotCreatorTitleUpdated = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$NewBotCreator$additionalNotesGuard = F2(
	function (isEditing, content) {
		if (isEditing) {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		} else {
			return content;
		}
	});
var $author$project$Model$availableCategories = _List_fromArray(
	['Moderation', 'Fun', 'Memes', 'Utility', 'Tools', 'News', 'Music', 'Other']);
var $elm$html$Html$option = _VirtualDom_node('option');
var $author$project$Pages$NewBotCreator$categoryItem = function (string) {
	return A2(
		$elm$html$Html$option,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$value(string)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(string)
			]));
};
var $author$project$Components$CharacterCounter$characterCounterClass = F2(
	function (input, max) {
		return (_Utils_cmp(
			$elm$core$String$length(input),
			max) < 1) ? 'newBotCharacterCounter' : 'newBotCharacterCounter newBotCharacterCounterTooMuch';
	});
var $author$project$Components$CharacterCounter$characterCounter = F2(
	function (input, max) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					A2($author$project$Components$CharacterCounter$characterCounterClass, input, max))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$elm$core$String$fromInt(
						$elm$core$String$length(input)) + (' / ' + $elm$core$String$fromInt(max)))
				]));
	});
var $author$project$Pages$NewBotCreator$clientIdEmptyIfNothing = function (maybeString) {
	if (!maybeString.$) {
		var a = maybeString.a;
		return a;
	} else {
		return '';
	}
};
var $author$project$Components$FormInput$errorMessage = function (msg) {
	if (!msg.$) {
		var message = msg.a;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('newBotCreatorErrorMessage')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(message)
				]));
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Pages$NewBotCreator$importedCommandList = function (model) {
	var _v0 = model.aS;
	if (!_v0.$) {
		var _v1 = $author$project$Model$isNewBotCreatorInEditMode(model);
		if (_v1) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('newBotCreatorInfoMessage')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Command list linked with Bot Designer for Discord.')
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('newBotCreatorInfoMessage')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Command list with descriptions will be imported from Bot Designer.')
					]));
		}
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $elm$html$Html$select = _VirtualDom_node('select');
var $author$project$Pages$NewBotCreator$selectedFile = function (model) {
	var _v0 = model.e.c_;
	if (!_v0.$) {
		var file = _v0.a;
		return A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src(file),
					$elm$html$Html$Attributes$class('newBotCreatorProfilePreview')
				]),
			_List_Nil);
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Components$FormInput$submitButton = F2(
	function (sendingInProgress, action) {
		return sendingInProgress ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('newBotCreatorSubmitButton newBotCreatorSubmitButtonDisabled')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Submitting....')
				])) : A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('newBotCreatorSubmitButton'),
					$elm$html$Html$Events$onClick(action)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Submit')
				]));
	});
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$Pages$NewBotCreator$titleText = function (isEditing) {
	return isEditing ? 'Edit bot' : 'Add new bot';
};
var $author$project$Pages$NewBotCreator$newBotCreatorContent = function (_v0) {
	var model = _v0.a;
	var form = _v0.b;
	var isEditing = $author$project$Model$isNewBotCreatorInEditMode(model);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('content pushContentToLeft stretchContent')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Pages$NewBotCreator$titleText(isEditing))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('newBotCreatorForm')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Your bot name:')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('newBotCreatorInputField'),
								$elm$html$Html$Attributes$value(form.bK),
								$elm$html$Html$Attributes$placeholder('My awesome bot'),
								$elm$html$Html$Events$onInput($author$project$Model$NewBotCreatorTitleUpdated)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Your bot short description:')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('newBotCreatorInputField'),
								$elm$html$Html$Attributes$value(form.cw),
								$elm$html$Html$Attributes$placeholder('CUTE CAT IMAGES, CHECK IT OUT'),
								$elm$html$Html$Events$onInput($author$project$Model$NewBotCreatorShortDescriptionUpdated)
							]),
						_List_Nil),
						A2($author$project$Components$CharacterCounter$characterCounter, form.cw, 100),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Your bot description:')
							])),
						A2(
						$elm$html$Html$textarea,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('newBotCreatorInputField newBotCreatorTextArea'),
								$elm$html$Html$Attributes$placeholder('My awesome bot provides awesome ticket system.\nIt also has cute cat images.'),
								$elm$html$Html$Events$onInput($author$project$Model$NewBotCreatorDescriptionUpdated),
								$elm$html$Html$Attributes$value(model.e.bJ)
							]),
						_List_Nil),
						A2($author$project$Components$CharacterCounter$characterCounter, form.bJ, 8000),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Profile picture')
							])),
						$author$project$Pages$NewBotCreator$selectedFile(model),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('newBotCreatorChooseFileButton'),
								$elm$html$Html$Events$onClick($author$project$Model$NewBotCreatorImageRequested)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Choose file')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Bot category')
							])),
						A2(
						$elm$html$Html$select,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('newBotCreatorInputField newBotCategorySelect '),
								$elm$html$Html$Attributes$value(form.cy),
								$elm$html$Html$Events$onInput($author$project$Model$NewBotCreatorCategoryUpdated)
							]),
						A2($elm$core$List$map, $author$project$Pages$NewBotCreator$categoryItem, $author$project$Model$availableCategories)),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Entry point (like !help):')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('newBotCreatorInputField'),
								$elm$html$Html$Attributes$placeholder('.help'),
								$elm$html$Html$Attributes$value(form.aI),
								$elm$html$Html$Events$onInput($author$project$Model$NewBotCreatorEntryPointUpdated)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Invite link:')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('newBotCreatorInputField '),
								$elm$html$Html$Attributes$placeholder('https://discord.com/oauth2/authorize?client_id=527866094925512714&permissions=2048&scope=bot'),
								$elm$html$Html$Events$onInput($author$project$Model$NewBotCreatorInviteLinkUpdated),
								$elm$html$Html$Attributes$value(form.eH)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Client ID:')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('newBotCreatorInputField newBotCreatorInputFieldDisabled'),
								$elm$html$Html$Attributes$placeholder('Automatically extracted from invite link'),
								$elm$html$Html$Attributes$disabled(true),
								$elm$html$Html$Attributes$value(
								$author$project$Pages$NewBotCreator$clientIdEmptyIfNothing(
									$author$project$Model$clientIdFromInviteLink(form.eH)))
							]),
						_List_Nil),
						A2(
						$author$project$Pages$NewBotCreator$additionalNotesGuard,
						isEditing,
						A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Additional notes to review team:')
								]))),
						A2(
						$author$project$Pages$NewBotCreator$additionalNotesGuard,
						isEditing,
						A2(
							$elm$html$Html$textarea,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('newBotCreatorInputField newBotCreatorTextArea newBotCreatorAdditionalNotesArea'),
									$elm$html$Html$Attributes$placeholder('These cat images are really cute'),
									$elm$html$Html$Events$onInput($author$project$Model$NewBotCreatorAdditionalNotes),
									$elm$html$Html$Attributes$value(form.ct)
								]),
							_List_Nil)),
						$author$project$Components$FormInput$errorMessage(form.aA),
						$author$project$Pages$NewBotCreator$importedCommandList(model),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Make sure to join the official '),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('https://discord.gg/k8YYBmv'),
										$elm$html$Html$Attributes$class('accent')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Bot Designer List')
									])),
								$elm$html$Html$text(' server and read #bot-rules before submiting your bot!')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pushLeftFlexRow')
							]),
						_List_fromArray(
							[
								A2($author$project$Components$FormInput$submitButton, form.bx, $author$project$Model$NewBotCreatorSubmitForm)
							]))
					]))
			]));
};
var $author$project$Pages$NewBotCreator$newBotCreator = function (_v0) {
	var model = _v0.a;
	var user = _v0.b;
	if ($author$project$Model$isNewBotCreatorInEditMode(model)) {
		var _v1 = model.ax;
		switch (_v1.$) {
			case 2:
				return $author$project$Pages$NewBotCreator$newBotCreatorContent(
					_Utils_Tuple3(model, model.e, user));
			case 3:
				var error = _v1.a;
				return A3($author$project$Components$EntityLoadingError$previewEntityLoadingError, error, 'Bot', model);
			default:
				return $author$project$Components$LoadingIndicator$loadingIndicator;
		}
	} else {
		return $author$project$Pages$NewBotCreator$newBotCreatorContent(
			_Utils_Tuple3(model, model.e, user));
	}
};
var $author$project$Model$NewServerCreatorFormSetDescription = function (a) {
	return {$: 53, a: a};
};
var $author$project$Model$NewServerCreatorFormSetInviteLink = function (a) {
	return {$: 54, a: a};
};
var $author$project$Model$NewServerCreatorFormSetName = function (a) {
	return {$: 51, a: a};
};
var $author$project$Model$NewServerCreatorFormSetShortDescription = function (a) {
	return {$: 52, a: a};
};
var $author$project$Model$NewServerCreatorFormSubmit = {$: 55};
var $author$project$Components$FormInput$formInput = F3(
	function (value_, placeholder_, action) {
		return A2(
			$elm$html$Html$input,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('newBotCreatorInputField'),
					$elm$html$Html$Attributes$value(value_),
					$elm$html$Html$Attributes$placeholder(placeholder_),
					$elm$html$Html$Events$onInput(action)
				]),
			_List_Nil);
	});
var $author$project$Components$FormInput$formInputTile = F4(
	function (value_, name, placeholder_, action) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('formComponentTile')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(name + ':')
						])),
					A3($author$project$Components$FormInput$formInput, value_, placeholder_, action)
				]));
	});
var $author$project$Components$FormInput$formInputTileWithCharacterCounter = F5(
	function (value_, name, placeholder_, max, action) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('formComponentTileWithCharacter')
				]),
			_List_fromArray(
				[
					A4($author$project$Components$FormInput$formInputTile, value_, name, placeholder_, action),
					A2($author$project$Components$CharacterCounter$characterCounter, value_, max)
				]));
	});
var $author$project$Components$StretchedContent$stretchedContent = F2(
	function (_v0, content) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('content pushContentToLeft stretchContent')
				]),
			content);
	});
var $author$project$Components$FormInput$textAreaInput = F3(
	function (value_, placeholder_, action) {
		return A2(
			$elm$html$Html$textarea,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('newBotCreatorInputField newBotCreatorTextArea'),
					$elm$html$Html$Attributes$value(value_),
					$elm$html$Html$Attributes$placeholder(placeholder_),
					$elm$html$Html$Events$onInput(action)
				]),
			_List_Nil);
	});
var $author$project$Components$FormInput$textAreaInputTile = F4(
	function (value_, name, placeholder_, action) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('formComponentTile')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(name + ':')
						])),
					A3($author$project$Components$FormInput$textAreaInput, value_, placeholder_, action)
				]));
	});
var $author$project$Components$FormInput$textAreaInputTileWithCharacterCounter = F5(
	function (value_, name, placeholder_, max, action) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('formComponentTileWithCharacter')
				]),
			_List_fromArray(
				[
					A4($author$project$Components$FormInput$textAreaInputTile, value_, name, placeholder_, action),
					A2($author$project$Components$CharacterCounter$characterCounter, value_, max)
				]));
	});
var $author$project$Pages$NewServerCreator$newServerCreator = function (_v0) {
	var model = _v0.a;
	var user = _v0.b;
	var form = model.k;
	return A2(
		$author$project$Components$StretchedContent$stretchedContent,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Create new server')
					])),
				A4($author$project$Components$FormInput$formInputTile, form.ce, 'Server name', 'Awesome cars server', $author$project$Model$NewServerCreatorFormSetName),
				A5($author$project$Components$FormInput$formInputTileWithCharacterCounter, form.fg, 'Server short description', 'Join for awesome cars!', 80, $author$project$Model$NewServerCreatorFormSetShortDescription),
				A5($author$project$Components$FormInput$textAreaInputTileWithCharacterCounter, form.ay, 'Server description', 'You can meet here new users who are also interested in high performance cars', 2000, $author$project$Model$NewServerCreatorFormSetDescription),
				A4($author$project$Components$FormInput$formInputTile, form.eH, 'Invite link', 'https://discord.gg/S9ZVPcF', $author$project$Model$NewServerCreatorFormSetInviteLink),
				$author$project$Components$FormInput$errorMessage(form.aA),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2($author$project$Components$FormInput$submitButton, form.bx, $author$project$Model$NewServerCreatorFormSubmit)
					]))
			]));
};
var $author$project$Pages$NotFound$notFound = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('404; Not found ;c')
					]))
			]));
};
var $author$project$Model$DataModels$SET_REASON = function (a) {
	return {$: 0, a: a};
};
var $author$project$Model$DataModels$SUBMIT = {$: 2};
var $author$project$Pages$Report$reportPage = function (model) {
	var form = model.ad;
	return A2(
		$author$project$Components$StretchedContent$stretchedContent,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Report bot')
					])),
				A4(
				$author$project$Components$FormInput$formInputTile,
				form.cd,
				'Report reason',
				'Cat images on this bot are too cute',
				function (msg) {
					return $author$project$Model$ReportMsg(
						$author$project$Model$DataModels$SET_REASON(msg));
				}),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$Components$FormInput$submitButton,
						form.c4,
						$author$project$Model$ReportMsg($author$project$Model$DataModels$SUBMIT))
					]))
			]));
};
var $author$project$Pages$Search$searchPageResults = function (model) {
	var _v0 = model.a4;
	if (!_v0.$) {
		var result = _v0.a;
		if (!result.$) {
			var value = result.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('searchPageBotContainer')
					]),
				A2($elm$core$List$map, $author$project$Components$BotComponent$botComponent, value));
		} else {
			var error = result.a;
			return A3($author$project$Components$EntityLoadingError$previewEntityLoadingError, error, 'search results', model);
		}
	} else {
		return $author$project$Components$LoadingIndicator$loadingIndicator;
	}
};
var $author$project$Model$searchQuery = function (model) {
	var maybeQuery = $elm$url$Url$percentDecode(
		A3(
			$elm$core$String$replace,
			'/',
			'',
			A3($elm$core$String$replace, '/search', '', model.t.q)));
	if (!maybeQuery.$) {
		var query = maybeQuery.a;
		return query;
	} else {
		return '';
	}
};
var $author$project$Pages$Search$searchPage = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('content centerContent')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						'Search results for \"' + ($author$project$Model$searchQuery(model) + '\":'))
					])),
				$author$project$Pages$Search$searchPageResults(model)
			]));
};
var $author$project$Components$ColumnCenter$columnCenter = F2(
	function (_v0, content) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('columnCenter')
				]),
			content);
	});
var $author$project$Pages$ServerPreview$inviteButton = function (server) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('botPreviewInviteButton')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('+ Join to server')
			]));
};
var $author$project$Pages$ServerPreview$membersString = function (server) {
	var _v0 = server.eL;
	if (!_v0.$) {
		var a = _v0.a;
		return '\uD83D\uDFE2 ' + ($elm$core$String$fromInt(a) + ' members online');
	} else {
		return '❔❔❔ members online';
	}
};
var $author$project$Pages$ServerPreview$serverPreviewContent = F2(
	function (model, server) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('content addNavBarPadding stretchContent')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('stdA'),
							$elm$html$Html$Attributes$href('/dev/' + server.eX.b0)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Model$discordUserStringBotOwner(server.eX))
						])),
					A2(
					$author$project$Components$ColumnCenter$columnCenter,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('devPreviewProfile tileLoading'),
									$elm$html$Html$Attributes$src(
									$author$project$Model$profileImageUrl(server.e4))
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(server.fr)
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('serverPreviewMemberCount')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Pages$ServerPreview$membersString(server))
						])),
					A2(
					$author$project$Components$ColumnCenter$columnCenter,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$ServerPreview$inviteButton(server)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('content  pushContentToLeft ')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Server description:')
								])),
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(server.eK)
								]))
						]))
				]));
	});
var $author$project$Pages$ServerPreview$serverPreview = function (model) {
	return A4($author$project$Components$LoadingGuard$loadingGuard, model, 'server', model.bw, $author$project$Pages$ServerPreview$serverPreviewContent);
};
var $author$project$Pages$ToS$termsOfService = function (model) {
	return A2(
		$author$project$Components$ContentLeft$contentLeft,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Terms of service')
					])),
				$author$project$Components$Markdown$parseOurMarkdown('\n1. User is not allowed to add advertisements/vulgarisms or other inappropriate things to his bots.\n\n2. User is not allowed to do spamming or overusing our compute/storage resources on purpose.\n\n3. User should not decompile or modify in any way our software. We may take legal steps if we detect these actions.\n\n4. User should not exploit detected bug, it should send a report to the developer instead.\n\n5. User bots should return correct server count (for example not 9123781782378912834 servers).\n\n6. Users bots should not update server count more then every 5 minutes.\n\n7. User has right to delete all his bots and data. User should contact developer in this case.\n\n8. Support is available at contact@nilpointer.software\n\n9. We reserve to warn/ban/remove user or bot at any time with or without terms of service violation.\n            ')
			]));
};
var $author$project$Main$router = function (model) {
	return (model.t.q === '/') ? $author$project$Pages$Index$index(model) : (A2($elm$core$String$startsWith, '/bot-settings/', model.t.q) ? A2($author$project$Pages$SignInNeeded$accountGuard, model, $author$project$Pages$BotSettings$botSettings) : (A2($elm$core$String$startsWith, '/account', model.t.q) ? A2($author$project$Pages$SignInNeeded$accountGuard, model, $author$project$Pages$Account$account) : (A2($elm$core$String$startsWith, '/newBotCreator', model.t.q) ? A2($author$project$Pages$SignInNeeded$accountGuard, model, $author$project$Pages$NewBotCreator$newBotCreator) : (A2($elm$core$String$startsWith, '/edit-bot/', model.t.q) ? A2($author$project$Pages$SignInNeeded$accountGuard, model, $author$project$Pages$NewBotCreator$newBotCreator) : (A2($elm$core$String$startsWith, '/new-server', model.t.q) ? A2($author$project$Pages$SignInNeeded$accountGuard, model, $author$project$Pages$NewServerCreator$newServerCreator) : (A2($elm$core$String$startsWith, '/import-bot', model.t.q) ? A2($author$project$Pages$SignInNeeded$accountGuard, model, $author$project$Pages$ImportBot$importBot) : (A2($elm$core$String$startsWith, '/bot/', model.t.q) ? $author$project$Pages$BotPreview$botPage(model) : (A2($elm$core$String$startsWith, '/dev/', model.t.q) ? $author$project$Pages$DevPreview$devPreview(model) : (A2($elm$core$String$startsWith, '/search/', model.t.q) ? $author$project$Pages$Search$searchPage(model) : (A2($elm$core$String$startsWith, '/server/', model.t.q) ? $author$project$Pages$ServerPreview$serverPreview(model) : (A2($elm$core$String$startsWith, '/documentation/bot-api', model.t.q) ? $author$project$Pages$BotApiDocumentation$botApiDocumentation(model) : (A2($elm$core$String$startsWith, '/tos', model.t.q) ? $author$project$Pages$ToS$termsOfService(model) : (A2($elm$core$String$startsWith, '/report/bot', model.t.q) ? $author$project$Pages$Report$reportPage(model) : $author$project$Pages$NotFound$notFound(model))))))))))))));
};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$Components$Toast$toastClass = function (model) {
	var _v0 = model.dM;
	if (!_v0.$) {
		return 'show';
	} else {
		return '';
	}
};
var $author$project$Components$Toast$toastText = function (model) {
	var _v0 = model.dM;
	if (!_v0.$) {
		var a = _v0.a;
		return a;
	} else {
		return '';
	}
};
var $author$project$Components$Toast$toast = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('toast'),
				$elm$html$Html$Attributes$class(
				$author$project$Components$Toast$toastClass(model))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$Components$Toast$toastText(model))
			]));
};
var $author$project$Main$view = function (model) {
	return {
		d2: _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('page')
					]),
				_List_fromArray(
					[
						$author$project$Components$NavBar$navBar(model),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('centerFlexRow')
							]),
						_List_fromArray(
							[
								$author$project$Main$router(model)
							])),
						$author$project$Components$Footer$footer,
						$author$project$Components$Toast$toast(model)
					]))
			]),
		fr: $author$project$Main$generateTitle(model)
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{
		eF: function (_v0) {
			return $author$project$Model$init;
		},
		eT: $author$project$Model$UrlChanged,
		eU: $author$project$Model$LinkClicked,
		fm: $author$project$Model$subscriptions,
		fu: $author$project$Model$update,
		fz: $author$project$Main$view
	});
_Platform_export({'Main':{'init':$author$project$Main$main(
	$elm$json$Json$Decode$succeed(0))(0)}});}(this));
import './main.css';
import {Elm} from './Main.elm';
import * as serviceWorker from './serviceWorker';

// Disable default firebase domains by redirecting them to original domain
const loc = window.location.host;
if ("nilpointer-bdl.web.app" === loc || "nilpointer-bdl.firebaseapp.com" === loc) {
  window.location.replace("https://botdesignerlist.com");
}

const app = Elm.Main.init({
  node: document.getElementById('root'),
});

app.ports.setClipboard.subscribe((text) => {
  navigator.clipboard.writeText(text).then(() => {
  });
});

app.ports.setAuthToken.subscribe((token) => {
  window.localStorage.setItem('session', token);
  app.ports.recAuthToken.send(token);
});

app.ports.getAuthToken.subscribe(() => {
  const token = window.localStorage.getItem('session');
  if (token !== null) {
    app.ports.recAuthToken.send(token);
  } else {
    app.ports.recAuthToken.send("");
  }
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAL0Cgyd-tRtjitxI1r4VNGenNIWdcOpDw",
  authDomain: "nilpointer-bdl.firebaseapp.com",
  databaseURL: "https://nilpointer-bdl.firebaseio.com",
  projectId: "nilpointer-bdl",
  storageBucket: "nilpointer-bdl.appspot.com",
  messagingSenderId: "583744433487",
  appId: "1:583744433487:web:732a75c3a80ec94ed5a93a",
  measurementId: "G-4728K1MGHX",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
